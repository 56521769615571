import { FC, useEffect } from 'react';
import { faPlus } from '~/assets';
import { FormikProps, useFormikContext } from '~/modules';
import { RolesValueSelect } from '~/utils';
import HeaderModal from '../HeaderModal';
import Select from '../Select';
import {
  ButtonRegisterUser,
  Content,
  IconPlus,
  Input,
  Wrapper,
} from './styles';

type Props = {
  isOpen: boolean;
  handleClose(): void;
};

const ModalRegisterUser: FC<Props> = ({ isOpen, handleClose }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    submitForm,
    setFieldValue,
    resetForm,
  }: FormikProps<RegisterUser> = useFormikContext();

  useEffect(() => {
    !isOpen && resetForm();
  }, [isOpen]);

  return (
    <Wrapper isOpen={isOpen}>
      <HeaderModal title="Criar Usuário" onPressClose={handleClose} />
      <Content>
        <Input
          label="Nome completo"
          placeholder="Digite o nome do usuário"
          fullWidth
          id="name"
          name="name"
          error={touched?.name && errors?.name}
          value={values.name}
          onChange={handleChange}
        />
        <Input
          label="CPF"
          placeholder="000.000.000-00"
          fullWidth
          id="document"
          name="document"
          maskType="cpf"
          error={touched?.document && errors?.document}
          value={values.document}
          onChange={handleChange}
        />
        <Input
          label="E-mail"
          placeholder="Digite o email do usuário"
          fullWidth
          id="email"
          name="email"
          error={touched?.email && errors?.email}
          value={values.email}
          onChange={handleChange}
        />
        <Select
          label="Permissão"
          placeholder="Selecione a permissão do usuário"
          id="role"
          name="role"
          error={touched?.role && errors?.role}
          onChange={({ name, value }) => setFieldValue(name, value)}
          value={values.role}
          options={RolesValueSelect}
        />
        <ButtonRegisterUser
          id="btn_register_user"
          onPress={submitForm}
          onKeyEnter
        >
          <IconPlus icon={faPlus} />
          Criar Usuário
        </ButtonRegisterUser>
      </Content>
    </Wrapper>
  );
};

export default ModalRegisterUser;

import { FC, useMemo, useState } from 'react';
import { isEmpty } from '~/modules';
import If from '../If';
import { TextInputProps } from '../TextInput';
import { InputSearch, ItemList, Wrapper, WrapperList } from './styles';

type Props = TextInputProps & {
  className?: string;
  listSelect: SelectProps[];
  loading?: boolean;
  textIsEmpty?: string;
  clearValue?: boolean;
  onSelected(value: SelectProps): void;
};

const SelectInputSearch: FC<Props> = ({
  id,
  className,
  listSelect,
  textIsEmpty,
  loading = false,
  clearValue = false,
  onSelected,
  ...props
}) => {
  const [value, setValue] = useState('');
  const [onFocus, setOnFocus] = useState(true);

  const valueFiltered: SelectProps[] = useMemo(() => {
    return listSelect?.reduce((acc, item) => {
      const result =
        item.label.toLowerCase().includes(value.toLowerCase()) && item;
      return result ? [...acc, result] : [...acc];
    }, [] as SelectProps[]);
  }, [value]);

  const handleSelected = (value: SelectProps) => {
    onSelected(value);
    clearValue ? setValue('') : setValue(value.label);
    setOnFocus(false);
  };

  return (
    <Wrapper className={className}>
      <InputSearch
        {...props}
        id={id}
        value={value}
        type="filter"
        onFocus={() => setOnFocus(true)}
        onChange={({ target: { value } }) => {
          setValue(value);
        }}
      />

      <If condition={!isEmpty(value) && onFocus}>
        <WrapperList>
          {valueFiltered?.map((item) => (
            <ItemList key={item.value} onClick={() => handleSelected(item)}>
              {item.label}
            </ItemList>
          ))}
          <If condition={!loading && isEmpty(valueFiltered)}>
            <ItemList>{textIsEmpty}</ItemList>
          </If>
        </WrapperList>
      </If>
    </Wrapper>
  );
};

export default SelectInputSearch;

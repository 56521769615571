import { toOnlyNumbers } from '~/modules';
import {
  EventOptionsAccount,
  clearEmptyStrings,
  toApiDateWithZ,
} from '~/utils';

export const clientsToApi = (values: ClientRegister) => {
  const returned = clearEmptyStrings({
    ...values,
    document: toOnlyNumbers(values?.document),
    birthDate: toApiDateWithZ(values?.birthDate),
    phone: toOnlyNumbers(values?.phone),
    identityDocument: {
      ...values?.identityDocument,
      issueDate: values?.identityDocument?.issueDate
        ? toApiDateWithZ(values?.identityDocument?.issueDate)
        : null,
    },
  });
  if (!values?.identityDocument?.issueDate) {
    delete returned?.identityDocument;
  }
  if (!values?.phone) {
    delete returned?.phone;
  }
  return returned;
};

export const rechargeToApi = (values: AddBalance | UpdateLimit) => ({
  ...values,
  amount: Number(toOnlyNumbers(values?.amount)),
});

export const clientsStatusToApi = (status: EventOptionsAccount | undefined) => {
  switch (status) {
    case EventOptionsAccount.ACTIVE:
      return 'activate';
    case EventOptionsAccount.CANCEL:
      return 'cancel';
    case EventOptionsAccount.INACTIVE:
      return 'inactivate';
    default:
      return '';
  }
};

import { FC } from 'react';
import { Formik } from 'formik';
import { observer } from '~/modules';
import { useStores } from '~/utils';
import Login from './Login';
import validationSchema from './validation';

const initialValues: AuthUser = {
  username: '',
  password: '',
};

const LoginContainer: FC = () => {
  const {
    user: { login },
    theme: { clientProvider, isTablet },
  } = useStores();

  const handleFormSubmit = async (formValues: AuthUser): Promise<void> => {
    await login(formValues);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
    >
      <Login logoClient={clientProvider?.logo} isTablet={isTablet} />
    </Formik>
  );
};

export default observer(LoginContainer);

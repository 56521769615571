import { SceneWrapper, Select, TextInput, TitlePage } from '~/components';
import { getTheme, pxToRem, styled } from '~/modules';

const spacingXl = getTheme('spacing.xl');
const spacingXxl = getTheme('spacing.xxl');

export const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  padding-bottom: ${pxToRem(100)};
`;

export const TitlePageComponent = styled(TitlePage)`
  padding: ${spacingXxl}px 0;
`;

export const SelectInputComponent = styled(Select)`
  margin-bottom: ${spacingXl}px;
  width: ${pxToRem(551)};
`;

export const Input = styled(TextInput)`
  width: ${pxToRem(551)};
  margin-bottom: ${spacingXl}px;
`;

export const DefaultWrapper = styled(SceneWrapper)`
  justify-content: center;
  flex: 1;
`;

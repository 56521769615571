import { FC } from 'react';
import { FormBehavior } from '~/modules';
import ModalExportFile from './ModalExportFile';
import { initialValues, validationSchema } from './schema';

type Props = {
  isOpen: boolean;
  handleClose(): void;
  handleFormSubmit(values: ExportFile): void;
};

const ModalExportFileContainer: FC<Props> = ({
  isOpen,
  handleClose,
  handleFormSubmit,
}) => (
  <FormBehavior
    validationSchema={validationSchema}
    initialValues={initialValues}
    onSubmit={handleFormSubmit}
  >
    <ModalExportFile isOpen={isOpen} handleClose={handleClose} />
  </FormBehavior>
);

export default ModalExportFileContainer;

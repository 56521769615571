import { FC, ReactNode, useState } from 'react';
import { Analytics } from '~/services';
import { AnalyticsEvents, showAlert } from '~/utils';
import { TouchableButton } from './styles';

export type TouchableProps = {
  children: string | ReactNode;
  id: string;
  accessibility?: string;
  className?: string;
  disabled?: boolean;
  onPress(): Promise<void> | void;
};

const Touchable: FC<TouchableProps> = ({
  children,
  id,
  className,
  onPress,
  disabled,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  const handleOnPress = async (): Promise<void> => {
    try {
      setLoading(true);
      await onPress();
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setLoading(false);
      Analytics.logButtonClick({
        eventName: AnalyticsEvents.BUTTON_CLICK,
        eventParams: {
          accessibility: children?.toString(),
        },
      });
    }
  };

  return (
    <TouchableButton
      {...rest}
      id={`test_${id}`}
      onClick={!loading && !disabled ? handleOnPress : undefined}
      loading={loading}
      className={className}
      disabled={disabled}
    >
      {children}
    </TouchableButton>
  );
};

export default Touchable;

import { AuthApi, UserApi } from '~/api';
import { action, makeObservable, observable, persist } from '~/modules';
import { Storage } from '~/services';

export default class UserStore {
  @persist('object')
  @observable
  isLogged = false;

  @persist('object')
  @observable
  user: User = {} as User;

  constructor() {
    makeObservable(this);
  }

  @action
  getServerKeys = async (): Promise<string> => {
    const { publicKey } = await AuthApi.getPublicKey();
    Storage.setPublicKey(publicKey);
    return publicKey;
  };

  @action
  login = async (credentials: AuthUser): Promise<void> => {
    const publicKey = await this.getServerKeys();
    const token = await AuthApi.login(credentials, { publicKey });
    Storage.setToken(token);
    await this.getUserLogged();
    this.setIsLogged(true);
  };

  @action
  logout = async (): Promise<void> => {
    try {
      await AuthApi.logout();
    } finally {
      Storage.clearWholeStorage();
    }
  };

  @action
  setIsLogged = (value: boolean) => {
    this.isLogged = value;
  };

  @action
  registerUser = async (values: RegisterUser): Promise<void> => {
    await UserApi.registerUser(values);
  };

  @action
  getUserLogged = async (): Promise<void> => {
    this.user = await AuthApi.fetchUser();
  };

  @action
  changePassword = async (values: ChangePassword): Promise<void> => {
    const publicKey = await this.getServerKeys();
    await UserApi.changePassword(values, publicKey);
    await this.getUserLogged();
  };
}

import { FC, useEffect } from 'react';
import { FormBehavior, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import {
  getInitialValue,
  validationSchema,
} from '~/scenes/ClientSearch/schema';
import { KeyboardEvents, getIsRoute, useStores } from '~/utils';
import SpotlightSearch from './SpotlightSearch';

export type PropsSpotlight = {
  isOpen: boolean;
  handleOnSpotlight(): void;
};

const SpotlightSearchContainer: FC<PropsSpotlight> = ({
  isOpen,
  handleOnSpotlight,
}) => {
  const navigate = useNavigate();
  const {
    clients: { setDocumentSpotlightSearch },
  } = useStores();

  const initialValues = getInitialValue('');

  const handleSearch = ({ document }: SearchClient) => {
    setDocumentSpotlightSearch(document);
    handleOnSpotlight();
    navigate(Routes.CLIENT_SEARCH);
  };

  const detectKeyDown = (event: KeyboardEvent) => {
    if (
      !getIsRoute(Routes.CLIENT_SEARCH) &&
      (event.ctrlKey || event.metaKey) &&
      event.key === KeyboardEvents.FORWARD_SLASH
    ) {
      handleOnSpotlight();
    }
  };

  useEffect(() => {
    document.addEventListener(KeyboardEvents.TYPE, detectKeyDown, true);
    return () => {
      document.removeEventListener(KeyboardEvents.TYPE, detectKeyDown, true);
    };
  }, []);

  return (
    <FormBehavior
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSearch}
    >
      <SpotlightSearch isOpen={isOpen} handleOnSpotlight={handleOnSpotlight} />
    </FormBehavior>
  );
};

export default SpotlightSearchContainer;

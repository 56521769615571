import { LogoBuildersBankLight } from '~/assets';
import { Icon, getTheme, ifStyle, pxToRem, styled } from '~/modules';
import { mqDevices } from '~/utils';
import Button from '../Button';
import Typography from '../Typography';

const brandPrimaryContrast = getTheme('brand.primary.contrast');
const frostedGlassZ4 = getTheme('frosted glass.z4');
const infoContrast = getTheme('info.contrast');

const borderRadiusMax = getTheme('borderRadius.max');

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXl = getTheme('spacing.xl');

const isActivatedRoute = ifStyle('$activatedRoute');
const isFocus = ifStyle('focus');

type Props = {
  $activatedRoute?: boolean;
  focus?: boolean;
};

export const Wrapper = styled.div<Props>`
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
  background-color: ${brandPrimaryContrast};
  width: ${isFocus(pxToRem(350), 0)};
  height: 100vh;
  padding: ${spacingLg}px ${isFocus(spacingLg, 0)}px;

  -webkit-transition:
    width 0.4s ease-in-out,
    padding 0.4s ease-in-out;
  transition:
    width 0.4s ease-in-out,
    padding 0.4s ease-in-out;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  @media ${mqDevices.inMobileAndTablet} {
    position: absolute;
    width: ${isFocus(pxToRem(450), 0)};
  }
`;

export const Content = styled.div`
  flex-direction: column;
  width: 100%;
`;

export const Footer = styled(Content)`
  opacity: 0.5;
`;

export const Heading = styled.div`
  width: 100%;
  justify-content: center;
  margin-bottom: ${spacingXl}px;
  max-height: ${pxToRem(120)};
`;

export const Logo = styled(LogoBuildersBankLight)`
  max-width: ${pxToRem(245)};
  max-height: ${pxToRem(120)};
`;

export const LogoClient = styled.img`
  max-width: ${pxToRem(245)};
  max-height: ${pxToRem(120)};
`;

export const WrapperItem = styled.div<Props>`
  background-color: ${isActivatedRoute(frostedGlassZ4, 'transparent')};
  border-radius: ${isActivatedRoute(borderRadiusMax, 0)}px;
  padding: ${spacingSm}px ${spacingLg}px;
  align-items: center;
  width: 100%;
  margin: ${spacingSm}px 0;
  cursor: pointer;

  p {
    color: ${infoContrast};
    font-weight: ${isActivatedRoute('bold', 'normal')};
  }
`;

export const Text = styled(Typography).attrs({
  variant: 'md',
})``;

export const Icons = styled(Icon).attrs({
  fontSize: pxToRem(20),
})`
  color: ${infoContrast};
  margin-right: ${spacingMd}px;
  width: ${pxToRem(25)};
  height: ${pxToRem(25)};
`;

export const IconClose = styled(Icon).attrs({
  fontSize: pxToRem(25),
})``;

export const ButtonClose = styled(Button)`
  width: ${pxToRem(60)};
  height: ${pxToRem(60)};
  border-radius: 50%;
`;

import { CNPJ, CPF, isEmpty } from '~/modules';
import { CharactersLength } from '../enums';

export const maskCpfOrCnpj = (text?: string) => {
  if (!text || isEmpty(text)) return '';

  let value = text.replace(/\D/g, '');
  if (value.length <= 11) {
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  } else {
    value = value.replace(/^(\d{2})(\d)/, '$1.$2');
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    value = value.replace(/\.(\d{3})(\d)/, '.$1/$2');
    value = value.replace(/(\d{4})(\d)/, '$1-$2');
  }

  return value.slice(0, CharactersLength.DOCUMENT_MAX_LENGTH);
};

export const removeMaskCpfOrCnpj = (text: string) => {
  if (CPF.isValid(text)) return CPF.strip(text);
  if (CNPJ.isValid(text)) return CNPJ.strip(text);
  return text;
};

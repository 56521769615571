export enum ProductType {
  BENEFITS = 'BENEFITS',
  CREDIT = 'CREDIT',
  BANKING = 'BANKING',
}

export enum ProductTypePt {
  BENEFITS = 'Benefício',
  CREDIT = 'Crédito',
  BANKING = 'Bancário',
}

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCELED = 'CANCELED',
}

export enum ProductStatusPt {
  ACTIVE = 'Ativa',
  INACTIVE = 'Inativa',
  CANCELED = 'Cancelada',
}

export enum TypeAccountsFilter {
  CONTROL = 'CONTROL',
  DEFAULT = 'DEFAULT',
  ALL = 'ALL',
}

export enum StepsRegisterProduct {
  DATA = 1,
  MCC = 2,
  META_DATA = 3,
}

export enum SectionsInfoAll {
  META_DATA = 'META_DATA',
  MCC = 'MCC',
  EXTRA_INFOS = 'EXTRA_INFOS',
}

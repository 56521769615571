import { FC, ReactNode } from 'react';
import { Wrapper } from './styles';

type Props = {
  children: ReactNode;
};

const CompanyRegister: FC<Props> = ({ children }) => (
  <Wrapper titlePage="Cadastrar Empresa" hiddenBorder offPaddingContent>
    {children}
  </Wrapper>
);

export default CompanyRegister;

import { Icon, getTheme, ifStyle, pxToRem, styled } from '~/modules';
import Typography from '../Typography';

const dangerContrast = getTheme('danger.contrast');
const successContrast = getTheme('success.contrast');
const infoContrast = getTheme('info.contrast');
const dangerMain = getTheme('danger.main');
const successMain = getTheme('success.main');
const infoMain = getTheme('info.main');
const themeRadiusCard = getTheme('themeRadius.card');
const spacingMd = getTheme('spacing.md');

const isDanger = ifStyle('danger');
const isSuccess = ifStyle('success');
const isInfo = ifStyle('info');

type Props = {
  danger?: boolean;
  success?: boolean;
  info?: boolean;
};

export const Wrapper = styled.div<Props>`
  background: ${isDanger(
    dangerContrast,
    isSuccess(successContrast, isInfo(infoContrast)),
  )};
  width: ${pxToRem(210)};
  height: ${pxToRem(60)};
  border-radius: ${themeRadiusCard}px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 ${spacingMd}px;
`;

export const Text = styled(Typography).attrs({ variant: 'md' })<Props>`
  color: ${isDanger(dangerMain, isSuccess(successMain, isInfo(infoMain)))};
  font-weight: bold;
`;

export const IconStyled = styled(Icon)<Props>`
  font-size: ${pxToRem(22)};
  color: ${isDanger(dangerMain, isSuccess(successMain, isInfo(infoMain)))};
  margin-right: ${spacingMd}px;
`;

import { FC, ReactNode } from 'react';
import { Letters, Wrapper } from './styles';

type Props = {
  children: ReactNode;
};

const BackgroundLetters: FC<Props> = ({ children }) => (
  <Wrapper>
    <Letters />
    {children}
  </Wrapper>
);

export default BackgroundLetters;

export const clearEmptyStrings = (value) => {
  // Se for um objeto (mas não uma instância de um Array ou null), percorra suas propriedades
  if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
    Object.entries(value).forEach(([key, val]) => {
      // Se o valor for uma string vazia, podemos optar por deletar a propriedade ou definir como null
      if (val === '') {
        // Para definir como null, use:
        value[key] = null;

        // Para deletar a propriedade, descomente a próxima linha:
        // delete value[key];
      } else {
        // Se não for uma string vazia, chama a função recursivamente
        value[key] = clearEmptyStrings(val);
      }
    });
  } else if (Array.isArray(value)) {
    // Se o valor for um array, aplique a mesma lógica a cada elemento
    return value.map(clearEmptyStrings);
  }
  // Retorne o valor modificado (ou intacto, se nenhum dos casos acima se aplicar)
  return value;
};

import { FC } from 'react';
import { observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import Clients from './Clients';

export const ClientsContainer: FC = () => {
  const navigate = useNavigate();

  const handleNavigate = (route: Routes) => navigate(route);

  return <Clients onNavigate={handleNavigate} />;
};

export default observer(ClientsContainer);

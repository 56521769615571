import { SceneWrapper, Select, TextInput, TitlePage } from '~/components';
import { getTheme, pxToRem, styled } from '~/modules';

const spacingLg = getTheme('spacing.lg');
const spacingXl = getTheme('spacing.xl');

export const Wrapper = styled(SceneWrapper)`
  justify-content: center;
  flex: 1;
`;

export const WrapperContent = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
`;

export const Input = styled(TextInput)`
  margin-bottom: ${spacingLg}px;
  width: ${pxToRem(550)};
`;

export const InputMedium = styled(TextInput)`
  margin-bottom: ${spacingLg}px;
  width: ${pxToRem(420)};
`;

export const InputSmall = styled(TextInput)`
  margin-bottom: ${spacingLg}px;
  width: ${pxToRem(120)};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  padding-bottom: ${pxToRem(100)};
`;

export const TitlePageComponent = styled(TitlePage)`
  margin: ${spacingXl}px 0;
`;

export const WrapperInput = styled.div`
  width: ${pxToRem(550)};
  justify-content: space-between;
`;

export const SelectInputComponent = styled(Select)`
  margin-bottom: ${spacingLg}px;
  width: ${pxToRem(550)};
`;

import { FC } from 'react';
import { faExclamationTriangle } from '~/assets';
import {
  ButtonCancel,
  ButtonConfirm,
  ButtonsBox,
  Description,
  Icon,
  Title,
  Wrapper,
} from './styles';

type Props = {
  isOpen: boolean;
  title?: string;
  description: string;
  onCancel(): void;
  onConfirm(): Promise<void> | void;
};

const ModalConfirmation: FC<Props> = ({
  isOpen,
  title = 'Tem certeza?',
  description,
  onCancel,
  onConfirm,
}) => {
  return (
    <Wrapper isOpen={isOpen}>
      <Icon icon={faExclamationTriangle} />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <ButtonsBox>
        <ButtonCancel id="btn_cancel" onPress={onCancel}>
          Cancelar
        </ButtonCancel>
        <ButtonConfirm id="btn_confirm" onPress={onConfirm} transparent>
          Sim, tenho certeza
        </ButtonConfirm>
      </ButtonsBox>
    </Wrapper>
  );
};

export default ModalConfirmation;

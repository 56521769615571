import { FC } from 'react';
import { FormikProps, observer, useFormikContext } from '~/modules';
import { StepsRegisterProduct, TypeProductValueSelect } from '~/utils';
import {
  ButtonComponent,
  Content,
  Footer,
  Input,
  SelectInputComponent,
  TitlePageComponent,
  Wrapper,
} from './styles';

type Props = {
  onNavigateId(id: number): void;
};

const DataProduct: FC<Props> = ({ onNavigateId }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
  }: FormikProps<RegisterProduct> = useFormikContext();

  return (
    <Wrapper>
      <Content>
        <TitlePageComponent>Dados do Produto</TitlePageComponent>
        <Input
          label="Nome do Produto"
          placeholder="Informe o nome do produto"
          id="name"
          name="name"
          error={touched.name && errors?.name}
          value={values.name}
          onChange={handleChange}
        />
        <Input
          label="Descrição do Produto"
          placeholder="Adicione uma descrição para o produto"
          id="description"
          name="description"
          type="textarea"
          errors={touched.description && errors?.description}
          value={values.description.replace('\n', '')}
          onChange={handleChange}
        />
        <SelectInputComponent
          label="Tipo do Produto"
          placeholder="Selecione o tipo do produto"
          id="type"
          name="type"
          error={touched.type && errors?.type}
          onChange={({ name, value }) => setFieldValue(name, value)}
          value={values.type || ''}
          options={TypeProductValueSelect}
        />
        <Input
          label="Cod. Interno (opcional)"
          placeholder="Informe um código interno para o produto"
          id="internalProductId"
          name="internalProductId"
          error={touched.internalProductId && errors?.internalProductId}
          value={values.internalProductId}
          onChange={handleChange}
        />
      </Content>
      <Footer>
        <ButtonComponent id="btn_clear" onPress={resetForm} transparent>
          Limpar
        </ButtonComponent>
        <ButtonComponent
          id="btn_confirm"
          onPress={() => onNavigateId(StepsRegisterProduct.MCC)}
        >
          Próximo
        </ButtonComponent>
      </Footer>
    </Wrapper>
  );
};

export default observer(DataProduct);

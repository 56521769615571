import { FC, ReactNode } from 'react';
import { Wrapper } from './styles';

type Props = {
  isOpen: boolean;
  children: ReactNode;
  className?: string;
  closeOnOverlayClick?: boolean;
  onClose?(): void;
};

const Modal: FC<Props> = ({
  isOpen,
  children,
  className,
  closeOnOverlayClick,
  onClose,
}) => (
  <Wrapper
    className={className}
    isOpen={isOpen}
    shouldCloseOnOverlayClick={closeOnOverlayClick}
    onRequestClose={onClose}
  >
    {children}
  </Wrapper>
);

export default Modal;

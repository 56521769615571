import { LoginBackground } from '~/assets';
import { getTheme, pxToRem, styled } from '~/modules';

const brandPrimaryMain = getTheme('brand.primary.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');

export const Wrapper = styled.div`
  background: ${brandPrimaryContrast};
  height: 100vh;
  flex: 1;
  position: relative;
  overflow: hidden;
`;

export const Letters = styled(LoginBackground).attrs((props) => ({
  fill: brandPrimaryMain(props) as string,
}))`
  position: absolute;
  z-index: 1;
  top: -400px;
  width: 100%;
  min-width: ${pxToRem(1500)};
`;

import { isEmpty } from '~/modules';
import { CharactersLength } from '../enums';

export const formatPhone = (text?: string): string => {
  if (!text || isEmpty(text)) return '';
  const textReplace = text
    .replace('(', '')
    .replace(')', '')
    .replace('-', '')
    .replace(' ', '');
  if (textReplace.length > CharactersLength.PHONE_DDD)
    return text.substring(0, text.length - 1);

  const regex =
    textReplace.length === CharactersLength.PHONE_DDD
      ? /(\d{2})(\d{5})(\d)/
      : /(\d{2})(\d{4})(\d)/;
  const value = textReplace.replace(/\D/g, '');
  return value.replace(regex, '($1) $2-$3');
};

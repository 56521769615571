import { FC } from 'react';
import { FooterButtons } from '~/components';
import { FormikProps, useFormikContext } from '~/modules';
import { BusinessTypeSelect, Masks, StepsCompanyRegister } from '~/utils';
import { Props } from '..';
import {
  Content,
  Input,
  SelectInputComponent,
  TitlePageComponent,
  WrapperContent,
} from '../styles';

const DataCompany: FC<Props> = ({ onNavigateId }) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    resetForm,
    setFieldValue,
  }: FormikProps<CompanyRegister> = useFormikContext();

  return (
    <WrapperContent>
      <Content>
        <TitlePageComponent>Dados da Empresa</TitlePageComponent>
        <Input
          label="CNPJ"
          placeholder="00.000.000/0000-00"
          id="document"
          name="document"
          error={touched.document && errors.document}
          value={values.document}
          mask={Masks.cnpj}
          onChange={handleChange}
        />
        <Input
          label="Razão social"
          placeholder="Informe a razão social da empresa"
          id="legalName"
          name="legalName"
          error={touched.legalName && errors.legalName}
          value={values.legalName}
          onChange={({ target: { name, value } }) =>
            setFieldValue(name, value.toUpperCase())
          }
        />
        <Input
          label="Nome fantasia"
          placeholder="Informe o nome da empresa"
          id="tradingName"
          name="tradingName"
          error={touched.tradingName && errors.tradingName}
          value={values.tradingName}
          onChange={handleChange}
        />
        <SelectInputComponent
          label="Tipo da empresa"
          placeholder="Selecione o tipo da empresa"
          id="businessType"
          name="businessType"
          error={touched.businessType && errors?.businessType}
          onChange={({ name, value }) => setFieldValue(name, value)}
          value={values.businessType || ''}
          options={BusinessTypeSelect}
        />
        <Input
          label="Data de fundação"
          placeholder="00/00/0000"
          id="foundingDate"
          name="foundingDate"
          error={touched.foundingDate && errors.foundingDate}
          value={values.foundingDate}
          mask={Masks.date}
          onChange={handleChange}
        />
        <Input
          label="Inscrição estadual"
          placeholder="00.000.000-0"
          id="stateRegistration"
          name="stateRegistration"
          error={touched.stateRegistration && errors.stateRegistration}
          value={values.stateRegistration}
          mask={Masks.ie}
          onChange={handleChange}
        />
        <Input
          label="Atividade Principal"
          placeholder="Informe a atividade principal da empresa"
          id="mainActivity"
          name="mainActivity"
          error={touched.mainActivity && errors.mainActivity}
          value={values.mainActivity}
          onChange={handleChange}
        />
        <Input
          label="Cod. Interno (opcional)"
          placeholder="Informe um código interno para a empresa"
          id="internalPersonId"
          name="internalPersonId"
          error={touched.internalPersonId && errors.internalPersonId}
          value={values.internalPersonId}
          onChange={handleChange}
        />
      </Content>
      <FooterButtons
        firstBtn={{
          id: 'btn_clear',
          label: 'Limpar',
          disabled: isSubmitting,
          onPress: () => resetForm(),
        }}
        secondaryBtn={{
          id: 'btn_confirm',
          label: 'Próximo',
          onPress: () => onNavigateId(StepsCompanyRegister.CONTACT),
        }}
      />
    </WrapperContent>
  );
};

export default DataCompany;

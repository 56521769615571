import { getTheme, pxToRem, styled } from '~/modules';
import Button from '../Button';

const backgroundZ4 = getTheme('background.z4');
const spacingMd = getTheme('spacing.md');

export const Wrapper = styled.div`
  z-index: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  height: ${pxToRem(100)};
  background-color: ${backgroundZ4};
  gap: ${spacingMd}px;
`;

export const ButtonComponent = styled(Button)`
  width: ${pxToRem(250)};
  height: ${pxToRem(51)};
`;

import { FC } from 'react';
import { FormikProps, useFormikContext } from '~/modules';
import { CharactersLength } from '~/utils';
import {
  ButtonCreatePassword,
  ContentBox,
  ContentForm,
  Input,
  Requirements,
  RequirementsBox,
  Subtitle,
  Title,
  Wrapper,
} from './styles';

type Props = {
  isOpen: boolean;
};

const ModalNewPassword: FC<Props> = ({ isOpen }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    submitForm,
  }: FormikProps<ChangePassword> = useFormikContext();

  return (
    <Wrapper isOpen={isOpen}>
      <ContentBox>
        <Title>Digite sua nova senha</Title>
        <Subtitle>Sua nova senha deverá conter:</Subtitle>
        <RequirementsBox>
          <Requirements>
            Mínimo de {CharactersLength.PASSWORD_MIN} caracteres
          </Requirements>
          <Requirements>
            Letra maiúscula, minúscula, número e caracteres especiais
          </Requirements>
          <Requirements>Não conter informações cadastrais</Requirements>
        </RequirementsBox>
        <ContentForm>
          <Input
            label="Digite a senha atual"
            placeholder="**********"
            id="currentPassword"
            name="currentPassword"
            type="password"
            error={touched?.currentPassword && errors?.currentPassword}
            value={values.currentPassword}
            onChange={handleChange}
          />
          <Input
            label="Digite a nova senha"
            placeholder="**********"
            id="newPassword"
            name="newPassword"
            type="password"
            error={touched?.newPassword && errors?.newPassword}
            value={values.newPassword}
            onChange={handleChange}
          />
          <Input
            label="Confirme a nova senha"
            placeholder="**********"
            id="newPasswordConfirmation"
            name="newPasswordConfirmation"
            type="password"
            error={
              touched?.newPasswordConfirmation &&
              errors?.newPasswordConfirmation
            }
            value={values.newPasswordConfirmation}
            onChange={handleChange}
          />
          <ButtonCreatePassword
            onPress={submitForm}
            id="btn_criar_senha"
            onKeyEnter
          >
            Trocar Senha
          </ButtonCreatePassword>
        </ContentForm>
      </ContentBox>
    </Wrapper>
  );
};

export default ModalNewPassword;

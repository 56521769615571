import { FC } from 'react';
import { faEnvelope, faIdCard, faPenToSquare, faShieldHalved } from '~/assets';
import { observer } from '~/modules';
import { UserRoles, UserStatus, useStores } from '~/utils';
import If from '../If';
import PersonInfo from '../PersonInfo';
import ItemBox from './ItemBox';
import {
  ButtonEditData,
  Card,
  Column,
  Datas,
  IconEdit,
  Wrapper,
} from './styles';

type Props = {
  name?: string;
  userName?: string;
  status?: UserStatus;
  email?: string;
  document?: string;
  role?: string;
  onPressEditData(): void;
};

const UserCardInfo: FC<Props> = ({
  name,
  userName,
  status,
  email,
  document,
  role,
  onPressEditData,
}) => {
  const {
    user: { user },
    users: { userSelected },
    theme: { isTablet },
  } = useStores();

  return (
    <Wrapper>
      <PersonInfo name={name} status={status} userName={userName} />
      <If condition={!isTablet}>
        <Datas>Dados</Datas>
      </If>
      <Column>
        <Card>
          <ItemBox icon={faEnvelope} label="EMAIL" value={email} />
          <ItemBox icon={faIdCard} label="DOCUMENTO" value={document} />
          <ItemBox icon={faShieldHalved} label="PERMISSÃO" value={role} />
        </Card>
        <If
          condition={
            user.role === UserRoles.ADMIN &&
            userSelected.status !== UserStatus.CANCELLED
          }
        >
          <ButtonEditData id="btn_edit_data" onPress={onPressEditData}>
            <IconEdit icon={faPenToSquare} />
            Editar Dados
          </ButtonEditData>
        </If>
      </Column>
    </Wrapper>
  );
};

export default observer(UserCardInfo);

import { Select, TextInput, TitlePage, Typography } from '~/components';
import { Icon, getTheme, pxToRem, styled } from '~/modules';

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingXl = getTheme('spacing.xl');
const spacingXxl = getTheme('spacing.xxl');

const borderWidthMin = getTheme('borderWidth.min');
const themeRadiusInput = getTheme('themeRadius.input');
const brandPrimaryMain = getTheme('brand.primary.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');
const dangerLight = getTheme('danger.light');

export const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  padding-bottom: ${pxToRem(100)};
`;

export const TitlePageComponent = styled(TitlePage)`
  padding: ${spacingXxl}px 0;
`;

export const SelectInputComponent = styled(Select)`
  margin-bottom: ${spacingXl}px;
  width: ${pxToRem(551)};
`;

export const Input = styled(TextInput)`
  width: ${pxToRem(551)};
  margin-bottom: ${spacingXl}px;
`;

export const IconComponent = styled(Icon)`
  margin: 0 ${spacingMd}px;
`;

export const WrapperProduct = styled.div`
  flex-direction: column;
  margin-bottom: ${spacingXl}px;
`;

export const ProductInput = styled.div`
  font-weight: 700;
  align-items: center;
  width: ${pxToRem(550)};
  height: ${pxToRem(48)};
  border: ${borderWidthMin}px solid ${brandPrimaryMain};
  border-radius: ${themeRadiusInput}px;
  color: ${brandPrimaryContrast};
  background: ${brandPrimaryMain};
  cursor: pointer;
`;

export const Label = styled(Typography)`
  font-weight: 500;
  margin: ${spacingSm}px 0;
`;

export const Error = styled(Typography).attrs({
  variant: 'sm',
})`
  color: ${dangerLight};
  margin-top: ${spacingSm}px;
`;

import { FC } from 'react';
import {
  ProductStatus,
  ProductStatusPt,
  UploadStatus,
  UploadStatusPt,
  UserStatus,
  parseEnumToPt,
} from '~/utils';
import {
  BallBlue,
  BallGreen,
  BallRed,
  BallYellow,
  Text,
  Wrapper,
} from './styles';

type Props = {
  status?: ProductStatus | UserStatus | UploadStatus;
  textColor?: string;
  type: 'product' | 'user' | 'upload';
};

const StatusLabel: FC<Props> = ({
  status = ProductStatus.ACTIVE,
  textColor,
  type,
}) => {
  const getIconStatus = {
    [ProductStatus.ACTIVE]: <BallGreen />,
    [ProductStatus.CANCELED]: <BallRed />,
    [ProductStatus.INACTIVE]: <BallRed />,
    [UploadStatus.PROCESSING]: <BallBlue />,
    [UploadStatus.PROCESSED_ERROR]: <BallRed />,
    [UploadStatus.PROCESSED_SUCCESS]: <BallGreen />,
    [UserStatus.ACTIVE]: <BallGreen />,
    [UserStatus.BLOCKED]: <BallRed />,
    [UserStatus.CANCELLED]: <BallRed />,
    [UserStatus.PENDING]: <BallYellow />,
  };

  const getTypeStatus = {
    product: ProductStatusPt,
    user: UserStatus,
    upload: UploadStatusPt,
  };

  return (
    <Wrapper>
      {getIconStatus[status]}
      <Text textColor={textColor}>
        {parseEnumToPt(status, getTypeStatus[type])}
      </Text>
    </Wrapper>
  );
};

export default StatusLabel;

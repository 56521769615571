import { FC } from 'react';
import { FormBehavior } from '~/modules';

import ModalNewPassword from './ModalNewPassword';
import { initialValuesNewPassword, validationSchema } from './schema';

type Props = {
  isOpen: boolean;
  handleFormSubmit(values: ChangePassword): void;
};

const ModalNewPasswordContainer: FC<Props> = ({ isOpen, handleFormSubmit }) => {
  return (
    <FormBehavior
      validationSchema={validationSchema}
      initialValues={initialValuesNewPassword}
      onSubmit={handleFormSubmit}
    >
      <ModalNewPassword isOpen={isOpen} />
    </FormBehavior>
  );
};

export default ModalNewPasswordContainer;

import { FC, useEffect, useState } from 'react';
import { observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import { UserRoles, showAlert, useStores } from '~/utils';
import Products from './Products';

export const ProductsContainer: FC = () => {
  const navigate = useNavigate();
  const {
    user: { user },
    products: { productsList, getProducts, setCurrentProduct },
  } = useStores();

  const [isProductsLoading, setIsProductsLoading] = useState(true);

  const handleNavigate = (route: Routes) => navigate(route);

  const onGetProducts = async (values: GetProductsProps) => {
    setIsProductsLoading(true);
    try {
      await getProducts(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setIsProductsLoading(false);
    }
  };

  const handleProductDetails = async (values: Product) => {
    setCurrentProduct(values);
    navigate(Routes.PRODUCT_DETAILS);
  };

  useEffect(() => {
    onGetProducts({});
  }, []);

  return (
    <Products
      isProductsLoading={isProductsLoading}
      productsList={productsList}
      isUserEspectator={user.role === UserRoles.SPECTATOR}
      onPressProduct={handleProductDetails}
      onChangePage={(page) => onGetProducts({ page })}
      onNavigate={handleNavigate}
    />
  );
};

export default observer(ProductsContainer);

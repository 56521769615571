import { FC, useEffect } from 'react';
import { MetadataRegister, ProgressIndicator } from '~/components';
import { FormBehavior, observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import {
  AlertMessages,
  StepsRegisterProduct,
  showAlert,
  useStores,
} from '~/utils';
import DataProduct from './DataProduct';
import MCC from './MCC';
import ProductRegister from './ProductRegister';
import { initialValues, validationSchema } from './schema';

export const ProductRegisterContainer: FC = () => {
  const navigate = useNavigate();

  const {
    utils: { setProgressId },
    products: { registerProduct, setMccListSelected },
  } = useStores();

  const handleFormSubmit = async (values: RegisterProduct) => {
    await registerProduct(values);
    showAlert({
      message: AlertMessages.SUCCESS_REGISTER_PRODUCT,
      type: 'success',
    });
    navigate(Routes.PRODUCT_DETAILS);
  };

  useEffect(() => {
    return () => {
      setMccListSelected([]);
    };
  }, []);

  return (
    <FormBehavior
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
    >
      <ProductRegister>
        <ProgressIndicator
          steps={[
            {
              id: StepsRegisterProduct.DATA,
              label: 'Dados do Produto',
              content: <DataProduct onNavigateId={(id) => setProgressId(id)} />,
            },
            {
              id: StepsRegisterProduct.MCC,
              label: 'MCC',
              content: <MCC onNavigateId={(id) => setProgressId(id)} />,
            },
            {
              id: StepsRegisterProduct.META_DATA,
              label: 'Metadata',
              content: (
                <MetadataRegister onNavigateId={(id) => setProgressId(id)} />
              ),
            },
          ]}
        />
      </ProductRegister>
    </FormBehavior>
  );
};

export default observer(ProductRegisterContainer);

import { FC } from 'react';
import { FileRow, PaginatedList, StatusLabel } from '~/components';
import {
  UploadRolesPt,
  UploadStatus,
  controlFileColumnsIndividual,
  controlFileColumnsRecharge,
  parseEnumToPt,
} from '~/utils';
import {
  Card,
  Column,
  ColumnData,
  Content,
  DescriptionLabel,
  Label,
  LittleCard,
  Name,
  Title,
  Total,
  Wrapper,
  WrapperCard,
} from './styles';

type Props = {
  file: FileUpload;
  details?: FileUploadError[];
  isFileLoading: boolean;
  onPressSeeAll?(error: FileUploadError): void;
};

const UploadsDetails: FC<Props> = ({
  file,
  details = [],
  isFileLoading,
  onPressSeeAll = () => {},
}) => {
  return (
    <Wrapper titlePage={file.fileName}>
      <Content>
        <ColumnData>
          <Card>
            <Name>{file.fileName}</Name>
            <Label>DESCRIÇÃO</Label>
            <WrapperCard>
              <LittleCard>
                <Label>STATUS</Label>
                <StatusLabel status={file.status} type="upload" />
              </LittleCard>
              <LittleCard>
                <Label>TIPO</Label>
                <DescriptionLabel>
                  {parseEnumToPt(file.type, UploadRolesPt)}
                </DescriptionLabel>
              </LittleCard>
            </WrapperCard>
          </Card>
        </ColumnData>
        <Column>
          <Title>
            Detalhes
            <Total>{details && `${details.length} no total`} </Total>
          </Title>
          <PaginatedList
            columns={
              details.length === 0
                ? controlFileColumnsIndividual
                : controlFileColumnsRecharge
            }
            list={details}
            isLoading={isFileLoading}
            showPagination={false}
            renderList={(currentFile) => (
              <FileRow
                recharge={currentFile.recharge}
                name={currentFile.code}
                message={currentFile.message}
                createdAt={file.createdAt}
                type={file.type}
                status={file.status}
                key={file.createdAt}
                onPress={() => onPressSeeAll(currentFile)}
              />
            )}
            titleSuccessData={
              file.status === UploadStatus.PROCESSED_SUCCESS
                ? 'Arquivo processado com sucesso!'
                : ''
            }
            titleEmptyData="Nenhuma detalhe relacionado a este arquivo"
          />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default UploadsDetails;

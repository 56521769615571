import { FC } from 'react';
import { ButtonComponent, Wrapper } from './styles';

type PropsButton = {
  id: string;
  label: string;
  disabled?: boolean;
  onPress(): Promise<void> | void;
};

type Props = {
  firstBtn: PropsButton;
  secondaryBtn: PropsButton;
};

const FooterButtons: FC<Props> = ({ firstBtn, secondaryBtn }) => (
  <Wrapper>
    <ButtonComponent
      id={firstBtn.id}
      onPress={firstBtn.onPress}
      disabled={firstBtn.disabled}
      transparent
    >
      {firstBtn.label}
    </ButtonComponent>
    <ButtonComponent id={secondaryBtn.id} onPress={secondaryBtn.onPress}>
      {secondaryBtn.label}
    </ButtonComponent>
  </Wrapper>
);

export default FooterButtons;

export enum DatePickerMonthNavigation {
  NEXT_MONTH = 1,
  PREVIOUS_MONTH = -1,
}

export enum CalendarWeekdays {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

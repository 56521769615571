import { ProductsApi } from '~/api';
import { action, makeObservable, observable, persist } from '~/modules';
import { AlertMessages, ProductStatus } from '~/utils';

export default class ProductsStore {
  @observable
  productsList: ListResponse<Product> = {};

  @observable
  controlAccountList: ListResponse<Account> = {};

  @persist('object')
  @observable
  currentProduct: Product = {} as Product;

  @persist('object')
  @observable
  mccList: MCCList = {} as MCCList;

  @observable
  mccListSelected: SelectProps[] = [];

  constructor() {
    makeObservable(this);
  }

  @action
  getProducts = async (values: GetProductsProps): Promise<void> => {
    this.productsList = await ProductsApi.getProducts(values);
  };

  @action
  getMCC = async (code?: string): Promise<void> => {
    this.mccList = await ProductsApi.getMCC(code);
  };

  @action
  setMccListSelected = async (values: SelectProps[]): Promise<void> => {
    this.mccListSelected = values;
  };

  @action
  registerProduct = async (values: RegisterProduct): Promise<void> => {
    this.currentProduct = await ProductsApi.registerProduct(values);
  };

  @action
  setCurrentProduct = async (values: Product): Promise<void> => {
    this.currentProduct = values;
  };

  @action
  getControlAccounts = async (
    values: ListControlAccountsProps,
  ): Promise<void> => {
    this.controlAccountList = await ProductsApi.getControlAccounts(values);
  };

  @action
  setStatusProduct = async (
    productId: string,
    optionSelected?: ProductStatus,
  ): Promise<string> => {
    this.currentProduct = await ProductsApi.setStatusProduct(
      productId,
      optionSelected,
    );

    let message = '';
    switch (optionSelected) {
      case ProductStatus.ACTIVE:
        message = AlertMessages.SUCCESS_ACTIVE_PRODUCT;
        break;
      case ProductStatus.INACTIVE:
        message = AlertMessages.SUCCESS_INACTIVE_PRODUCT;
        break;
      default:
        break;
    }

    return message;
  };
}

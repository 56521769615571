import {
  Icon as IconModules,
  getTheme,
  ifStyle,
  pxToRem,
  styled,
} from '~/modules';
import Modal from '../Modal';
import Typography from '../Typography';

const textMain = getTheme('text.main');

const spacingMd = getTheme('spacing.md');
const spacingXl = getTheme('spacing.xl');
const spacingXxl = getTheme('spacing.xxl');

const backgroundZ2 = getTheme('background.z2');
const backgroundZ3 = getTheme('background.z3');
const backgroundZ4 = getTheme('background.z4');

const spacingSm = getTheme('spacing.sm');
const spacingLg = getTheme('spacing.lg');

const themeRadiusCard = getTheme('themeRadius.card');
const themeRadiusButton = getTheme('themeRadius.button');
const borderRadiusXl = getTheme('borderRadius.xl');

const isActive = ifStyle('active');

export const Wrapper = styled(Modal)`
  flex-direction: column;
  width: ${pxToRem(959)};
  max-width: 90%;
  max-height: 95vh;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  justify-content: space-between;
  padding: ${spacingXl}px ${spacingXxl}px;
`;

export const Column = styled.div`
  width: ${pxToRem(350)};
  height: 100%;
  flex-direction: column;
  margin-right: ${spacingMd}px;
`;

export const Icon = styled(IconModules)`
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  margin-right: ${spacingMd}px;
  color: ${textMain};
  opacity: 0.3 !important;
`;

export const WrapperItem = styled.div`
  height: max-content;
  margin-bottom: ${spacingXl}px;
  align-items: center;
`;

export const WrapperValue = styled.div`
  flex-direction: column;
`;

export const Label = styled(Typography).attrs({
  variant: 'xs',
})`
  font-weight: bold;
  opacity: 0.5 !important;
`;

export const Value = styled(Typography).attrs({
  variant: 'xs',
})``;

export const Title = styled(Typography)`
  flex: 1;
  font-weight: 500;
`;

export const Card = styled.div`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-height: ${pxToRem(96)};
  padding: 0 ${spacingLg}px;
  background-color: ${backgroundZ3};
  border-radius: ${themeRadiusCard}px;
  margin-bottom: ${spacingMd}px;
`;

export const Description = styled(Typography)`
  font-weight: bold;
  flex: 1;
`;

export const MetadataWrapper = styled.div`
  flex-direction: column;
  width: 100%;
`;

export const MetadataHeader = styled.div`
  width: 100%;
  padding: 0 ${spacingLg}px;
  margin-bottom: ${spacingMd}px;
`;

export const MetadataValueWrapper = styled.div`
  flex: 1;
  align-items: center;
  overflow-x: auto;
  width: ${pxToRem(407)};
  height: ${pxToRem(48)};
`;

type Props = {
  active: boolean;
};

export const SectionsBar = styled.div`
  width: 100%;
  height: ${pxToRem(60)};
  padding: ${spacingSm}px;
  background: ${backgroundZ2};
  border-radius: ${borderRadiusXl}px;
`;

export const SectionItem = styled.div<Props>`
  flex: 1;
  background-color: ${isActive(backgroundZ4, 'none')};
  justify-content: center;
  align-items: center;
  border-radius: ${themeRadiusButton}px;
  font-weight: ${isActive(700)};
  cursor: pointer;
`;

export const WrapperSections = styled.div`
  width: 100%;
  padding: 0 ${spacingXl}px;
  padding-top: ${spacingXl}px;
`;

import styled from 'styled-components';
import { ifStyle } from '~/modules';

const isLoading = ifStyle('loading');
const isDisabled = ifStyle('disabled');

type Props = {
  loading: boolean;
  disabled?: boolean;
};
export const TouchableButton = styled.div<Props>`
  width: 100%;
  cursor: ${isLoading('progress', isDisabled('not-allowed', 'pointer'))};
  opacity: ${isDisabled(0.5, 1)};
`;

import { Button, Select, TextInput, TitlePage } from '~/components';
import { getTheme, pxToRem, styled } from '~/modules';

const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXl = getTheme('spacing.xl');

const backgroundZ4 = getTheme('background.z4');

export const Wrapper = styled.div`
  flex: 1;
  align-items: center;
  flex-direction: column;
`;

export const Input = styled(TextInput)`
  margin-bottom: ${spacingLg}px;
  width: ${pxToRem(550)};
`;

export const SelectInputComponent = styled(Select)`
  margin-bottom: ${spacingLg}px;
  width: ${pxToRem(550)};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${pxToRem(100)};
  overflow: scroll;
`;

export const TitlePageComponent = styled(TitlePage)`
  margin: ${spacingXl}px 0;
`;

export const Footer = styled.div`
  z-index: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  height: ${pxToRem(100)};
  background-color: ${backgroundZ4};
  gap: ${spacingMd}px;
`;

export const ButtonComponent = styled(Button)`
  width: ${pxToRem(250)};
  height: ${pxToRem(51)};
`;

import { FC, useState } from 'react';
import { Rounded, Wrapper } from './styles';

type Props = {
  isActive: boolean;
  onPress?(isActive: boolean): void;
  disabled?: boolean;
};

const ToggleSwitch: FC<Props> = ({
  isActive,
  onPress = () => {},
  disabled = false,
}) => {
  const [isToggleActive, setIsToggleActive] = useState(isActive);

  const handleOnPress = () => {
    setIsToggleActive(!isToggleActive);
    onPress(!isToggleActive);
  };

  return (
    <Wrapper
      onClick={!disabled ? handleOnPress : () => {}}
      isActive={!disabled ? isToggleActive : false}
    >
      <Rounded />
    </Wrapper>
  );
};

export default ToggleSwitch;

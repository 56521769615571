import { Typography, getTheme, styled } from '~/modules';

const textMain = getTheme('text.main');

export const Wrapper = styled(Typography)`
  color: ${textMain};
  font-weight: normal;
  opacity: inherit !important;
  font-family: 'Poppins', sans-serif;
`;

import { FC, useEffect, useState } from 'react';
import { faPlus } from '~/assets';
import { AccountRow, HeaderModal, PaginatedList } from '~/components';
import {
  AccountType,
  controlAccountsColumnsAdd,
  showAlert,
  useStores,
} from '~/utils';
import { Content, Wrapper } from './styles';

type Props = {
  isOpen: boolean;
  handleClose(): void;
  onSelectAccount(account: Account): void;
};

const ModalProducts: FC<Props> = ({ isOpen, handleClose, onSelectAccount }) => {
  const {
    products: { controlAccountList, currentProduct, getControlAccounts },
  } = useStores();

  const [productsLoading, setProductsLoading] = useState(false);

  const onGetProducts = async ({ page }: ListProps) => {
    try {
      setProductsLoading(true);
      await getControlAccounts({ productId: currentProduct?.id, page });
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setProductsLoading(false);
    }
  };

  useEffect(() => {
    onGetProducts({});
  }, []);

  return (
    <Wrapper isOpen={isOpen}>
      <HeaderModal title="Selecionar Conta" onPressClose={handleClose} />
      <Content>
        <PaginatedList
          columns={controlAccountsColumnsAdd}
          list={controlAccountList.content?.filter(
            (account) => account?.type === AccountType.CONTROL_CASH_IN,
          )}
          totalPages={controlAccountList.paging?.totalPages}
          currentPage={controlAccountList.paging?.page}
          isLoading={productsLoading}
          onChangePage={(page) => onGetProducts({ page })}
          renderList={(account) => (
            <AccountRow
              balance={account?.balance}
              key={account?.id}
              name={account?.person?.fullName}
              status={account?.status}
              type={account?.type}
              selectIcon={faPlus}
              onPress={() => onSelectAccount(account)}
            />
          )}
          titleEmptyData="Sem produtos disponíveis"
        />
      </Content>
    </Wrapper>
  );
};

export default ModalProducts;

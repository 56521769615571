import { getTheme, pxToRem, styled } from '~/modules';
import { mqDevices } from '~/utils';
import Button from '../Button';
import Modal from '../Modal';
import TextInput from '../TextInput';

const backgroundZ2 = getTheme('background.z2');
const backgroundZ3 = getTheme('background.z3');
const backgroundZ4 = getTheme('background.z4');

const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXxl = getTheme('spacing.xxl');
const spacingXl = getTheme('spacing.xl');

const themeRadiusCard = getTheme('themeRadius.card');
const boxShadowZ4 = getTheme('boxShadow.z4');

export const Wrapper = styled(Modal)`
  flex-direction: column;
  max-height: 95%;
  width: ${pxToRem(959)};
  max-width: 90%;
  background-color: ${backgroundZ3};
  ${boxShadowZ4};

  @media ${mqDevices.inMobileAndTablet} {
    height: 85%;
  }
`;

export const Content = styled.div`
  width: 100%;
  overflow-y: auto;
  padding: ${spacingXxl}px;

  @media ${mqDevices.inMobileAndTablet} {
    flex-direction: column;
  }
`;

export const Input = styled(TextInput)`
  margin-bottom: ${spacingMd}px;
`;

export const ButtonComponent = styled(Button)`
  width: ${pxToRem(250)};
  height: ${pxToRem(51)};
  margin-left: ${spacingMd}px;
`;

export const ButtonResetPassword = styled(Button)`
  width: ${pxToRem(250)};
  height: ${pxToRem(51)};
`;

export const WrapperInfo = styled.div`
  width: ${pxToRem(320)};
  min-height: ${pxToRem(440)};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${spacingLg}px;
  background-color: ${backgroundZ2};
  border-radius: ${themeRadiusCard}px;
  margin-bottom: ${spacingLg}px;

  @media ${mqDevices.inMobileAndTablet} {
    width: 100%;
  }
`;

export const Form = styled.div`
  flex: 1;
  flex-direction: column;
  padding-left: ${spacingXl}px;
  padding-bottom: ${spacingXxl}px;

  @media ${mqDevices.inMobileAndTablet} {
    padding-left: 0;
  }
`;

export const Footer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: ${pxToRem(106)};
  background-color: ${backgroundZ4};
  border-bottom-left-radius: ${themeRadiusCard}px;
  border-bottom-right-radius: ${themeRadiusCard}px;
`;

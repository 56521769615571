import { FC } from 'react';
import { faSearchRegular, faUserPlus } from '~/assets';
import { Routes } from '~/routes';
import CardItem from './CardItem';
import { Content, Wrapper } from './styles';

type Props = {
  onNavigate(route: Routes): void;
};
const Clients: FC<Props> = ({ onNavigate }) => (
  <Wrapper titlePage="Clientes">
    <Content>
      <CardItem
        icon={faSearchRegular}
        title="Buscar Cliente"
        description="Busca de cliente Pessoa Física ou Pessoa Jurídica"
        labelButton="Acessar"
        onPress={() => onNavigate(Routes.CLIENT_SEARCH)}
      />
      <CardItem
        icon={faUserPlus}
        title="Cadastrar Cliente PF"
        description="Cadastro exclusivo para clientes Pessoa Física"
        labelButton="Acessar"
        onPress={() => onNavigate(Routes.CLIENT_REGISTER)}
      />
      <CardItem
        icon={faUserPlus}
        title="Cadastrar Cliente PJ"
        description="Cadastro exclusivo para clientes Pessoa Jurídica"
        labelButton="Acessar"
        onPress={() => onNavigate(Routes.COMPANY_REGISTER)}
      />
    </Content>
  </Wrapper>
);

export default Clients;

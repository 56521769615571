import { FC } from 'react';
import { FooterButtons } from '~/components';
import { FormikProps, useFormikContext } from '~/modules';
import {
  Masks,
  StepsClientRegister,
  formatPhone,
  genderTypeSelect,
} from '~/utils';
import { Props } from '..';
import {
  Content,
  Input,
  SelectInputComponent,
  TitlePageComponent,
  WrapperContent,
} from '../styles';

const DataClient: FC<Props> = ({ onNavigateId }) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    resetForm,
    setFieldValue,
  }: FormikProps<ClientRegister> = useFormikContext();

  return (
    <WrapperContent>
      <Content>
        <TitlePageComponent>Dados da Cliente</TitlePageComponent>
        <Input
          label="Primeiro Nome"
          placeholder="Informe o primeiro nome do cliente"
          id="firstName"
          name="firstName"
          error={touched.firstName && errors.firstName}
          value={values.firstName}
          onChange={({ target: { name, value } }) => setFieldValue(name, value)}
        />
        <Input
          label="Sobrenome"
          placeholder="Informe o primeiro nome do cliente"
          id="lastName"
          name="lastName"
          error={touched.lastName && errors.lastName}
          value={values.lastName}
          onChange={({ target: { name, value } }) => setFieldValue(name, value)}
        />
        <Input
          label="CPF"
          placeholder="000.000.000-00"
          id="document"
          name="document"
          error={touched.document && errors.document}
          value={values.document}
          mask={Masks.cpf}
          onChange={handleChange}
        />
        <Input
          label="Data de nascimento"
          placeholder="00/00/0000"
          id="birthDate"
          name="birthDate"
          error={touched.birthDate && errors.birthDate}
          value={values.birthDate}
          mask={Masks.date}
          onChange={handleChange}
        />
        <SelectInputComponent
          label="Gênero"
          placeholder="Selecione o gênero"
          id="gender"
          name="gender"
          error={touched.gender && errors?.gender}
          onChange={({ name, value }) => setFieldValue(name, value)}
          value={values.gender || ''}
          options={genderTypeSelect}
        />
        <Input
          label="E-mail"
          placeholder="cliente@dominio.com"
          id="mail"
          name="mail"
          error={touched.mail && errors.mail}
          value={values.mail}
          onChange={({ target: { name, value } }) =>
            setFieldValue(name, value.toLowerCase())
          }
        />
        <Input
          label="Telefone"
          placeholder="(00) 00000-0000"
          id="phone"
          name="phone"
          error={touched.phone && errors.phone}
          value={values.phone}
          onChange={({ target: { name, value } }) =>
            setFieldValue(name, formatPhone(value))
          }
        />
        <Input
          label="Nome da Mãe"
          placeholder="Informe o nome da mãe"
          id="motherName"
          name="motherName"
          error={touched.motherName && errors.motherName}
          value={values.motherName}
          onChange={({ target: { name, value } }) => setFieldValue(name, value)}
        />
        <Input
          label="Cod. Interno (opcional)"
          placeholder="Informe um código interno para a empresa"
          id="internalPersonId"
          name="internalPersonId"
          error={touched.internalPersonId && errors.internalPersonId}
          value={values.internalPersonId}
          onChange={handleChange}
        />
      </Content>
      <FooterButtons
        firstBtn={{
          id: 'btn_clear',
          label: 'Limpar',
          disabled: isSubmitting,
          onPress: () => resetForm(),
        }}
        secondaryBtn={{
          id: 'btn_confirm',
          label: 'Próximo',
          onPress: () => onNavigateId(StepsClientRegister.DATA + 1),
        }}
      />
    </WrapperContent>
  );
};

export default DataClient;

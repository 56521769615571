import { FC, useEffect } from 'react';
import { faLightbulb } from '~/assets';
import { FormikProps, useFormikContext } from '~/modules';
import { maskCpfOrCnpj } from '~/utils';
import {
  Description,
  IconComponent,
  Input,
  Rounded,
  Row,
  Title,
  Wrapper,
  WrapperText,
} from './styles';
import { PropsSpotlight } from '.';

const SpotlightSearch: FC<PropsSpotlight> = ({ isOpen, handleOnSpotlight }) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    submitForm,
    resetForm,
  }: FormikProps<SearchClient> = useFormikContext();

  useEffect(() => {
    !isOpen && resetForm();
  }, [isOpen]);

  return (
    <Wrapper
      isOpen={isOpen}
      onRequestClose={handleOnSpotlight}
      ariaHideApp={false}
    >
      <Input
        autoFocus
        type="filter"
        id="document"
        name="document"
        placeholder="Digite o CPF/CNPJ completo"
        value={values.document}
        error={touched?.document && errors?.document}
        onPressMagnifyingGlass={submitForm}
        onChange={({ target }) =>
          setFieldValue('document', maskCpfOrCnpj(target.value))
        }
      />
      <Row>
        <Rounded>
          <IconComponent icon={faLightbulb} />
        </Rounded>
        <WrapperText>
          <Title>Bem-vindo à busca rápida!</Title>
          <Description>
            Digite o CPF ou CNPJ de um cliente para acessar suas contas de forma
            rápida e fácil
          </Description>
        </WrapperText>
      </Row>
    </Wrapper>
  );
};

export default SpotlightSearch;

import { FC } from 'react';
import {
  faCircleSmall,
  faDownload,
  faIdCardSolid,
  faLockSolid,
  faPlus,
} from '~/assets';
import {
  ExportStatus,
  ExportStatusPt,
  ExportTypes,
  ExportTypesPt,
  formatHourMinuteSecondDate,
  parseEnumToPt,
  positionsList,
} from '~/utils';
import If from '../If';
import StatusIndicator from '../StatusIndicator';
import {
  Card,
  CreatedAt,
  IconCard,
  IconHiddenName,
  IconSearch,
  Name,
  Row,
  RowButtonSearch,
  SearchIconBox,
  Type,
} from './styles';

type Props = {
  name?: string;
  status?: ExportStatus;
  type?: ExportTypes;
  createdAt?: Date;
  showInfo?: boolean;
  sideAccount?: boolean;
  onPress?(): void;
  onPressToggleSwitch?(isActive: boolean): Promise<void> | void;
};

const ExportRow: FC<Props> = ({
  name = '',
  showInfo = true,
  status,
  createdAt,
  sideAccount,
  onPress = () => {},
  onPressToggleSwitch,
  type,
}) => (
  <Card>
    <Row>
      <IconCard icon={showInfo ? faIdCardSolid : faLockSolid} />
      <If condition={!showInfo}>
        {positionsList.map((value) => (
          <IconHiddenName key={value} icon={faCircleSmall} />
        ))}
      </If>
      <If condition={showInfo}>
        <Name>{name}</Name>
      </If>
    </Row>
    <Row>
      <Type>{parseEnumToPt(type, ExportTypesPt)}</Type>
    </Row>
    <Row>
      <StatusIndicator status={parseEnumToPt(status, ExportStatusPt)} />
    </Row>
    <Row>
      <CreatedAt>{formatHourMinuteSecondDate(createdAt)}</CreatedAt>
    </Row>

    <If condition={!onPressToggleSwitch}>
      <RowButtonSearch>
        <SearchIconBox
          id="onPress_product"
          onPress={onPress}
          disabled={status !== ExportStatus.PROCESSED_SUCCESS}
        >
          <IconSearch icon={sideAccount ? faPlus : faDownload} />
        </SearchIconBox>
      </RowButtonSearch>
    </If>
  </Card>
);

export default ExportRow;

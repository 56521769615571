import { isEmpty } from '~/modules';

export const getFirstAndLastName = (name?: string): string => {
  if (!name) return '';
  if (!name.includes(' ')) return name;
  const arr = name.split(' ');
  return `${arr[0]} ${arr[arr.length - 1]}`;
};

export const formatFirstLetteredUppercase = (text?: string): string => {
  if (isEmpty(text) || !text) return '';
  return text[0].toUpperCase() + text.substring(1).toLowerCase();
};

import { FC, useMemo, useState } from 'react';
import { faCalendar, faChevronLeft, faChevronRight, faXmark } from '~/assets';
import { ClickAwayListener, DateManager, Icon } from '~/modules';
import {
  CalendarWeekdays,
  DatePickerMonthNavigation,
  WEEKEND_DAYS,
  WEEK_DAYS,
  formatShortDate,
  formatToMonthYear,
} from '~/utils';
import If from '../If';
import {
  ButtonsBox,
  CalendarDay,
  CalendarDayBox,
  CalendarDaysBox,
  ClearButton,
  DatePickerBox,
  DayOfWeek,
  DaysOfWeekBox,
  FilterBox,
  FilterTextButton,
  IconButton,
  IconFilter,
  IconsBox,
  Month,
  MonthBox,
  SearchButton,
  Wrapper,
} from './styles';

type Props = {
  selectedStartDate?: CalendarDay;
  selectedEndDate?: CalendarDay;
  onSelectStartDate(value?: CalendarDay): void;
  onSelectEndDate(value?: CalendarDay): void;
  onSearch(): void | Promise<void>;
  onClear(): void;
};

const DatePicker: FC<Props> = ({
  selectedEndDate,
  selectedStartDate,
  onSelectEndDate,
  onSelectStartDate,
  onSearch,
  onClear,
}) => {
  const [selectedMonth, setSelectedMonth] = useState(DateManager());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const onPressPrevMonth = () => {
    setSelectedMonth(
      selectedMonth
        .clone()
        .add(DatePickerMonthNavigation.PREVIOUS_MONTH, 'months'),
    );
  };

  const onPressNextMonth = () => {
    setSelectedMonth(
      selectedMonth.clone().add(DatePickerMonthNavigation.NEXT_MONTH, 'months'),
    );
  };

  const handleFilter = () => {
    setIsDatePickerOpen((old) => !old);
  };

  const onPressSearch = async () => {
    await onSearch();
    handleFilter();
  };

  const handleOnClear = () => {
    setSelectedMonth(DateManager());
    onClear();
  };

  const onPressCalendarDay = (calendarDay: CalendarDay) => {
    if (
      selectedStartDate &&
      !selectedStartDate.dayDate.isSame(calendarDay.dayDate)
    ) {
      if (selectedStartDate.dayDate.isAfter(calendarDay.dayDate)) {
        onSelectEndDate(selectedStartDate);
        return onSelectStartDate(calendarDay);
      }
      return onSelectEndDate(calendarDay);
    }
    onSelectStartDate(calendarDay);
  };

  const daysOfSelectedMonth: CalendarDay[] = useMemo(() => {
    const daysOfMonth = Array.from({ length: selectedMonth.daysInMonth() }).map(
      (_, day) => {
        const dayNumber: number = day + 1;
        const dayDate = selectedMonth.clone().set('date', dayNumber);
        return {
          dayNumber,
          monthNumber: dayDate.month() + 1,
          dayDate,
        };
      },
    );
    const aditionalDays: CalendarDay[] = [...daysOfMonth];

    const firstDayOfMonth = daysOfMonth[0];
    const firstWeekday = firstDayOfMonth.dayDate.weekday();

    const numberOfAditionalDaysToAdd =
      firstWeekday === CalendarWeekdays.SUNDAY
        ? CalendarWeekdays.SATURDAY
        : firstWeekday - 1;
    Array.from({ length: numberOfAditionalDaysToAdd }).forEach((_, day) => {
      const dayDate = firstDayOfMonth.dayDate.clone().subtract(day + 1, 'days');
      aditionalDays.unshift({
        dayNumber: dayDate.weekday() + 1, // weekday starts with 0 to 6, but i need from 1 to 7 so i add + 1
        monthNumber: dayDate.month() + 1, // same thing for month
        dayDate,
      });
    });

    return aditionalDays;
  }, [selectedMonth]);

  const createCalendarDays = () => {
    return daysOfSelectedMonth.map((calendarDay, index) => {
      const { dayDate, dayNumber, monthNumber } = calendarDay;
      let isSelected = false;
      let isBetweenRange = false;
      // to disable future dates, use "dayDate.isAfter(DateManager());" to define isDisabled
      const isDisabled = false;
      if (selectedStartDate) {
        isSelected = dayDate.isSame(selectedStartDate.dayDate);
        if (selectedEndDate) {
          isSelected = isSelected || dayDate.isSame(selectedEndDate.dayDate);
          isBetweenRange = dayDate.isBetween(
            selectedStartDate.dayDate,
            selectedEndDate.dayDate,
          );
        }
      }
      const isCurrentDay =
        !selectedStartDate && dayDate.isSame(DateManager(), 'day');
      const isDayOfCurrentMonth = monthNumber === selectedMonth.month() + 1;

      return (
        <CalendarDayBox
          key={index}
          disabled={isDisabled}
          onClick={() => !isDisabled && onPressCalendarDay(calendarDay)}
        >
          <If condition={isDayOfCurrentMonth}>
            <CalendarDay
              isSelected={isSelected}
              isBetweenRange={isBetweenRange}
              isCurrentDay={isCurrentDay}
            >
              {dayNumber}
            </CalendarDay>
          </If>
        </CalendarDayBox>
      );
    });
  };

  const filterText = useMemo(() => {
    let text = 'Filtrar por data';
    if (selectedStartDate) {
      text = formatShortDate(selectedStartDate.dayDate.toDate());
      if (selectedEndDate) {
        text += ` - ${formatShortDate(selectedEndDate.dayDate.toDate())}`;
      }
    }

    return text;
  }, [selectedStartDate, selectedEndDate]);

  return (
    <Wrapper>
      <FilterBox>
        <IconFilter
          icon={selectedStartDate ? faXmark : faCalendar}
          onClick={selectedStartDate ? handleOnClear : undefined}
          clickable={!!selectedStartDate}
        />
        <FilterTextButton id="btn_filter" onPress={handleFilter}>
          {filterText}
        </FilterTextButton>
      </FilterBox>
      <If condition={isDatePickerOpen}>
        <ClickAwayListener onClickAway={handleFilter}>
          <DatePickerBox>
            <MonthBox>
              <Month>{formatToMonthYear(selectedMonth.toDate())}</Month>
              <IconsBox>
                <IconButton id="btn_mes_anterior" onPress={onPressPrevMonth}>
                  <Icon icon={faChevronLeft} />
                </IconButton>
                <IconButton id="btn_mes_seguinte" onPress={onPressNextMonth}>
                  <Icon icon={faChevronRight} />
                </IconButton>
              </IconsBox>
            </MonthBox>
            <DaysOfWeekBox>
              {WEEK_DAYS.map((weekDay, index) => (
                <DayOfWeek
                  key={index}
                  isWeekend={WEEKEND_DAYS.includes(weekDay)}
                >
                  {weekDay}
                </DayOfWeek>
              ))}
            </DaysOfWeekBox>
            <CalendarDaysBox>{createCalendarDays()}</CalendarDaysBox>
            <ButtonsBox>
              <ClearButton
                id="btn_limpar"
                onPress={handleOnClear}
                disabled={!selectedStartDate}
                transparent
              >
                Limpar
              </ClearButton>
              <SearchButton
                id="btn_search"
                onPress={onPressSearch}
                disabled={!selectedStartDate}
              >
                Buscar
              </SearchButton>
            </ButtonsBox>
          </DatePickerBox>
        </ClickAwayListener>
      </If>
    </Wrapper>
  );
};

export default DatePicker;

import { FC, useState } from 'react';
import { isEmpty } from '~/modules';
import { AlertMessages } from '~/utils';
import { ButtonFilter, Input, Wrapper } from './styles';

type Props = {
  label?: string;
  placeholder?: string;
  className?: string;
  onPressFilter(filterValue: string): Promise<void> | void;
};

const Filter: FC<Props> = ({
  label = AlertMessages.TITLE_FILTER,
  className,
  placeholder,
  onPressFilter,
}) => {
  const [filterValue, setFilterValue] = useState('');

  const onChangeValue = (text: string) => {
    setFilterValue(text);
    if (isEmpty(text)) {
      onPressFilter('');
    }
  };

  return (
    <Wrapper className={className}>
      <Input
        type="filter"
        id="filter"
        name="filter"
        label={label}
        placeholder={placeholder}
        value={filterValue}
        onChange={({ target: { value } }) => onChangeValue(value)}
      />
      <ButtonFilter
        id="btn_filter"
        disabled={isEmpty(filterValue)}
        onPress={() => onPressFilter(filterValue)}
      >
        Buscar
      </ButtonFilter>
    </Wrapper>
  );
};

export default Filter;

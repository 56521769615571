import { FC } from 'react';
import { faPlus, faPowerOff } from '~/assets';
import {
  AccountRow,
  OptionButton,
  OptionsList,
  PaginatedList,
  StatusLabel,
} from '~/components';
import {
  AlertMessages,
  ProductStatus,
  ProductTypePt,
  controlAccountsColumns,
  formatShortDate,
  parseEnumToPt,
} from '~/utils';
import {
  ButtonComponent,
  Card,
  Column,
  ColumnData,
  Content,
  Description,
  DescriptionLabel,
  IconComponent,
  Label,
  LittleCard,
  Name,
  Title,
  Total,
  Wrapper,
  WrapperCard,
  WrapperData,
} from './styles';

type Props = {
  product: Product;
  accounts: ListResponse<Account>;
  isAccountLoading: boolean;
  onChangePage(page: number): void;
  onPressOption(option: ProductStatus, text: AlertMessages): void;
  onPressSeeAll(): void;
  onPressOpenModalInstallmentDates(): void;
};

const ProductDetails: FC<Props> = ({
  product,
  accounts,
  isAccountLoading,
  onChangePage,
  onPressOption,
  onPressSeeAll,
  onPressOpenModalInstallmentDates,
}) => {
  const isActive = product.status === ProductStatus.ACTIVE;
  const { ACTIVE, INACTIVE } = ProductStatus;
  const { INACTIVE_PRODUCT, ACTIVE_PRODUCT } = AlertMessages;

  return (
    <Wrapper titlePage={product.name}>
      <Content>
        <ColumnData>
          <Card>
            <Name>{product.name}</Name>
            <Label>DESCRIÇÃO</Label>
            <Description>{product.description}</Description>
            <WrapperCard>
              <LittleCard>
                <Label>STATUS</Label>
                <StatusLabel status={product.status} type="product" />
              </LittleCard>
              <LittleCard>
                <Label>TIPO</Label>
                <DescriptionLabel>
                  {parseEnumToPt(product.type, ProductTypePt)}
                </DescriptionLabel>
              </LittleCard>
            </WrapperCard>
          </Card>
          <Card>
            <WrapperData>
              <Label>DADOS</Label>
              <WrapperCard>
                <LittleCard>
                  <Label>METADATA</Label>
                  <DescriptionLabel>
                    {product.metadata?.length && product.metadata?.length > 1
                      ? `${product.metadata?.length} campos`
                      : `${product.metadata?.length} campo`}
                  </DescriptionLabel>
                </LittleCard>
                <LittleCard>
                  <Label>MCC</Label>{' '}
                  <DescriptionLabel>
                    {product.merchantCategoryCodes?.length &&
                    product.merchantCategoryCodes?.length > 1
                      ? `${product.merchantCategoryCodes?.length} campos`
                      : `${product.merchantCategoryCodes?.length || 0} campo`}
                  </DescriptionLabel>
                </LittleCard>
              </WrapperCard>
            </WrapperData>
            <ButtonComponent id="btn_see_all" onPress={onPressSeeAll}>
              <IconComponent icon={faPlus} />
              Ver tudo
            </ButtonComponent>
          </Card>
          {product.installmentDates?.length &&
          product.installmentDates?.length > 1 ? (
            <Card>
              <WrapperData>
                <Label>CICLOS DE FATURAMENTO</Label>
                <WrapperCard column>
                  {product.installmentDates.map((cycle, i) => {
                    return i < 2 ? (
                      <LittleCard>
                        <DescriptionLabel>{`${formatShortDate(new Date(cycle.startDate))} - ${formatShortDate(new Date(cycle.endDate))}`}</DescriptionLabel>
                      </LittleCard>
                    ) : null;
                  })}
                </WrapperCard>
              </WrapperData>
              <ButtonComponent
                id="btn_see_modal_installment_date"
                onPress={onPressOpenModalInstallmentDates}
              >
                <IconComponent icon={faPlus} />
                Ver tudo
              </ButtonComponent>
            </Card>
          ) : null}
        </ColumnData>
        <Column>
          <OptionsList>
            <OptionButton
              id="btn_inactive_product"
              label={isActive ? 'Inativar Produto' : 'Ativar Produto'}
              icon={faPowerOff}
              onPress={() =>
                onPressOption(
                  isActive ? INACTIVE : ACTIVE,
                  isActive ? INACTIVE_PRODUCT : ACTIVE_PRODUCT,
                )
              }
            />
          </OptionsList>
          <Title>
            Contas de controle
            <Total>
              {accounts.paging?.totalElements &&
                `${accounts.paging?.totalElements} no total`}{' '}
            </Total>
          </Title>
          <PaginatedList
            columns={controlAccountsColumns}
            list={accounts.content}
            isLoading={isAccountLoading}
            totalPages={accounts.paging?.totalPages}
            currentPage={accounts.paging?.page}
            onChangePage={onChangePage}
            renderList={(account) => (
              <AccountRow
                offIcon
                balance={account?.balance}
                name={account?.person?.fullName}
                status={account?.status}
                type={account?.type}
                key={`${account?.balance}` + `${account?.person?.fullName}`}
              />
            )}
            titleEmptyData="Nenhuma conta de controle vinculada"
            descriptionEmptyData="Não existe conta de controle vinculada a este produto."
          />
        </Column>
      </Content>
    </Wrapper>
  );
};

export default ProductDetails;

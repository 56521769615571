import { getTheme, pxToRem, styled } from '~/modules';
import { mqDevices } from '~/utils';
import Modal from '../Modal';
import Typography from '../Typography';

const backgroundZ3 = getTheme('background.z3');
const backgroundZ2 = getTheme('background.z2');

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXl = getTheme('spacing.xl');
const spacingXxl = getTheme('spacing.xxl');

const themeRadiusCard = getTheme('themeRadius.card');

export const Wrapper = styled(Modal)`
  flex-direction: column;
  width: ${pxToRem(959)};
  max-width: 90%;
  height: ${pxToRem(772)};
  max-height: 95vh;

  @media ${mqDevices.inMobileAndTablet} {
    height: 85vh;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  padding: ${spacingXl}px;
`;

export const Row = styled.div`
  width: 100%;

  @media ${mqDevices.inMobileAndTablet} {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  flex-direction: column;
`;

export const TopInfoBox = styled.div`
  flex-direction: row;
  padding-left: ${spacingLg}px;
`;

export const ValueBox = styled.div`
  flex-direction: column;
  margin-right: ${spacingLg}px;
`;

export const Title = styled(Typography).attrs({
  variant: 'md',
})`
  font-weight: bold;
  opacity: 0.8 !important;
  margin-bottom: ${spacingMd}px;
`;

export const WrapperAmount = styled.div`
  flex-direction: column;
  margin-right: ${spacingXxl}px;
`;

export const Amount = styled(Typography).attrs({
  variant: 'xxl',
})`
  display: flex;
  font-weight: bold;
  align-items: center;
  margin-bottom: ${spacingMd}px;
`;

export const Prefix = styled(Typography).attrs({
  variant: 'xxl',
})`
  font-weight: 500;
  opacity: 0.8 !important;
  margin-right: ${spacingSm}px;
`;

export const Card = styled.div`
  overflow-y: auto;
  flex-direction: column;
  width: ${pxToRem(418)};
  max-height: ${pxToRem(460)};
  padding: ${spacingMd}px;
  border-radius: ${themeRadiusCard}px;
  background-color: ${backgroundZ3};
  margin: ${spacingSm}px;

  @media ${mqDevices.inMobileAndTablet} {
    width: 100%;
  }
`;

export const WrapperValue = styled.div`
  flex-direction: column;
  margin-bottom: ${spacingLg}px;
`;

export const Label = styled(Typography).attrs({
  variant: 'md',
})`
  font-weight: bold;
  opacity: 0.5 !important;
`;

export const Value = styled(Typography).attrs({
  variant: 'xs',
})`
  word-break: break-all;
`;

export const Columns = styled.div`
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${spacingMd}px;
  padding: 0 ${spacingLg}px;
`;

export const TitleColumn = styled(Typography).attrs({
  variant: 'sm',
})`
  flex: 1;
  font-weight: 500;
`;

export const WrapperItemMetaData = styled.div`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-height: ${pxToRem(96)};
  padding: 0 ${spacingLg}px;
  background-color: ${backgroundZ2};
  border-radius: ${themeRadiusCard}px;
  margin-bottom: ${spacingMd}px;
`;

export const Description = styled(Typography)`
  flex: 1;
  overflow-x: auto;
  font-weight: bold;
  margin-right: ${spacingMd}px;
`;

export const CardValue = styled.div`
  flex: 1;
  align-items: center;
  overflow-x: auto;
  width: ${pxToRem(160)};
  height: ${pxToRem(48)};
`;

export const ValueMetaData = styled(Typography)``;

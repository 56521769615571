import { FC } from 'react';

const Icon: FC<IconProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="500"
    height="100"
    fill="none"
    viewBox="0 0 500 101"
    className={className}
  >
    <path
      fill="#18164B"
      fillRule="evenodd"
      d="M24.127 100.5V65.806h24.128c8.883 0 16.084-7.31 16.084-16.326V35.194h8.043c8.883 0 16.085 7.31 16.085 16.326v32.654c0 9.016-7.202 16.326-16.085 16.326H24.127z"
      clipRule="evenodd"
    />
    <path
      fill="#00FFAE"
      d="M48.255.5H0v65.306h24.127V35.194H64.34V16.826C64.34 7.81 57.14.5 48.255.5z"
    />
    <path
      fill="#18164B"
      d="M204.887 83V51.02h12.523V83h-12.523zM217.41 44.114c0 2.07-1.651 3.75-3.687 3.75h-8.836V35.42h8.836c2.036 0 3.687 1.679 3.687 3.75v4.944z"
    />
    <path
      fill="#18164B"
      fillRule="evenodd"
      d="M149.509 36.72c2.769 0 5.175.542 7.22 1.625 2.087 1.04 3.706 2.492 4.856 4.355 1.15 1.82 1.725 3.922 1.725 6.305 0 3.293-1.15 5.98-3.45 8.06-.522.472-1.1.899-1.732 1.28a12.66 12.66 0 012.562 1.84c2.556 2.383 3.834 5.439 3.834 9.165 0 2.687-.66 5.07-1.981 7.15-1.32 2.037-3.173 3.64-5.558 4.81-2.343 1.127-5.091 1.69-8.243 1.69h-20.956V36.72h21.723zm-3.003 36.92h-6.197V63.63h6.197c1.108 0 2.045.195 2.811.585a4.07 4.07 0 011.853 1.755c.469.737.703 1.625.703 2.665 0 1.56-.49 2.795-1.469 3.705-.98.867-2.279 1.3-3.898 1.3zm-6.197-18.98h5.622c1.363 0 2.492-.346 3.386-1.04.895-.693 1.342-1.776 1.342-3.25 0-1.473-.447-2.556-1.342-3.25-.894-.693-2.023-1.04-3.386-1.04h-5.622v8.58z"
      clipRule="evenodd"
    />
    <path
      fill="#18164B"
      d="M175.908 81.96c2.386 1.214 5.112 1.82 8.179 1.82 3.066 0 5.771-.606 8.114-1.82 2.385-1.213 4.238-2.881 5.559-5.005 1.363-2.166 2.044-4.658 2.044-7.475V51.02h-12.523v18.59c0 .997-.319 1.82-.958 2.47-.597.607-1.342.91-2.236.91-.639 0-1.214-.13-1.726-.39a3.549 3.549 0 01-1.15-1.235c-.255-.52-.383-1.105-.383-1.755V51.02h-12.523v18.46c0 2.773.682 5.243 2.045 7.41s3.216 3.857 5.558 5.07zM223.357 35.42V83h12.523V35.42h-12.523z"
    />
    <path
      fill="#18164B"
      fillRule="evenodd"
      d="M255.498 83.65c-3.11 0-5.836-.715-8.179-2.145-2.3-1.43-4.11-3.38-5.43-5.85-1.278-2.513-1.917-5.395-1.917-8.645s.639-6.132 1.917-8.645c1.32-2.513 3.13-4.463 5.43-5.85 2.343-1.43 5.069-2.145 8.179-2.145 1.874 0 3.642.325 5.303.975.538.197 1.049.42 1.533.671V35.42h12.523V83h-12.203v-1.442a14.26 14.26 0 01-1.853.987 12.934 12.934 0 01-5.303 1.105zm2.3-11.18c1.065 0 1.98-.216 2.747-.65a4.898 4.898 0 001.789-1.95c.469-.823.703-1.776.703-2.86 0-1.083-.213-2.015-.639-2.795a4.768 4.768 0 00-1.853-1.95c-.767-.477-1.661-.715-2.683-.715-1.023 0-1.938.239-2.748.715a5.397 5.397 0 00-1.853 1.95c-.468.823-.702 1.755-.702 2.795s.213 1.972.639 2.795a5.215 5.215 0 004.6 2.665zM287.594 81.7c2.769 1.387 5.985 2.08 9.648 2.08 2.854 0 5.388-.455 7.603-1.365a14.933 14.933 0 005.687-4.095l-6.262-6.305c-.937 1.084-1.98 1.885-3.131 2.405-1.107.477-2.406.715-3.897.715-1.533 0-2.811-.303-3.834-.91-1.022-.606-1.81-1.495-2.364-2.665a8.83 8.83 0 01-.213-.566l21.617-.149c.213-.953.341-1.776.384-2.47a18.2 18.2 0 00.128-2.08c0-3.12-.724-5.893-2.173-8.32-1.405-2.427-3.365-4.312-5.878-5.655-2.513-1.386-5.409-2.08-8.689-2.08-3.323 0-6.304.737-8.945 2.21-2.641 1.43-4.728 3.402-6.262 5.915-1.491 2.514-2.236 5.395-2.236 8.645 0 3.293.767 6.218 2.3 8.775 1.576 2.514 3.749 4.485 6.517 5.915zm3.202-18.515c.057-.182.118-.358.185-.53.511-1.213 1.235-2.145 2.172-2.795.937-.65 2.044-.975 3.322-.975 1.236 0 2.258.282 3.067.845.81.564 1.406 1.409 1.789 2.535.102.259.191.536.269.831l-10.804.089z"
      clipRule="evenodd"
    />
    <path
      fill="#18164B"
      d="M316.7 51.02h12.523v1.678c2.139-1.552 4.78-2.328 7.923-2.328 1.448 0 2.684.174 3.706.52 1.065.347 1.917.845 2.556 1.495l-7.029 10.14c-.298-.346-.745-.628-1.341-.845-.554-.26-1.214-.39-1.981-.39-1.278 0-2.236.39-2.875 1.17-.639.737-.959 1.82-.959 3.25V83H316.7V51.02zM351.049 83.195c1.959.52 3.918.78 5.878.78 4.174 0 7.497-.931 9.967-2.795 2.471-1.863 3.706-4.377 3.706-7.54 0-2.037-.341-3.683-1.022-4.94-.682-1.3-1.576-2.34-2.684-3.12a13.544 13.544 0 00-3.386-1.82 30.676 30.676 0 00-3.45-1.04c-1.065-.26-1.939-.541-2.62-.845-.682-.303-1.022-.693-1.022-1.17 0-.346.149-.628.447-.845.341-.26.916-.39 1.725-.39.852 0 1.917.217 3.195.65 1.401.585 2.541 1.17 3.714 2.34l5.38-7.99s-3.094-2.377-5.133-3.125a23.19 23.19 0 00-8.05-1.43c-2.727 0-5.133.477-7.22 1.43-2.045.91-3.642 2.167-4.792 3.77-1.108 1.56-1.662 3.358-1.662 5.395 0 1.994.341 3.64 1.023 4.94.681 1.257 1.576 2.254 2.683 2.99a18.234 18.234 0 003.45 1.69 54.45 54.45 0 003.451.975c1.107.26 2.002.542 2.683.845.724.303 1.086.758 1.086 1.365 0 .347-.213.629-.639.845-.383.174-.937.26-1.661.26-1.576 0-3.088-.303-4.536-.91a11.347 11.347 0 01-3.642-2.47c-1.679 2.47-5.507 7.883-5.507 7.883s1.967 1.483 3.143 2.127a25.5 25.5 0 005.495 2.145z"
    />
    <path
      fill="#18164B"
      fillRule="evenodd"
      d="M391.596 50.5h-12.009V83h12.293c2.238 0 4.145-.393 5.721-1.18 1.608-.82 2.821-1.906 3.641-3.26.851-1.386 1.276-2.945 1.276-4.677 0-2.078-.599-3.873-1.796-5.385-.939-1.216-2.184-2.157-3.734-2.823 1.1-.612 1.982-1.371 2.646-2.279.946-1.322 1.419-2.897 1.419-4.724 0-1.543-.363-2.928-1.088-4.156-.725-1.229-1.797-2.205-3.215-2.93-1.418-.724-3.136-1.086-5.154-1.086zm-8.558 29.43h9.078c2.144 0 3.83-.599 5.06-1.795 1.229-1.197 1.843-2.693 1.843-4.488 0-1.165-.267-2.22-.803-3.165-.536-.945-1.308-1.685-2.317-2.22-.977-.567-2.143-.85-3.499-.85h-9.362V79.93zm8.842-15.542h-8.842V53.523h8.464c1.86 0 3.341.473 4.444 1.418 1.103.944 1.655 2.283 1.655 4.015 0 1.732-.52 3.07-1.56 4.015-1.009.945-2.396 1.417-4.161 1.417zM420.06 50.5L406.726 83h3.688l3.411-8.455h14.909L432.116 83h3.736L422.66 50.5h-2.6zm7.446 20.974l-6.196-15.488-6.247 15.488h12.443z"
      clipRule="evenodd"
    />
    <path
      fill="#18164B"
      d="M441.413 83V50.5h2.458l19.007 25.816V50.5h3.499V83h-2.458l-19.055-25.82V83h-3.451zM478.109 66.628L495.272 83H500l-17.903-16.995 17.43-15.505h-4.633l-16.785 14.993V50.5h-3.452V83h3.452V66.628z"
    />
  </svg>
);

export default Icon;

import { Button, Typography } from '~/components';
import { Icon, getTheme, pxToRem, styled } from '~/modules';
import { mqDevices } from '~/utils';

const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const themeRadiusCard = getTheme('themeRadius.card');
const borderRadiusMax = getTheme('borderRadius.max');
const backgroundZ3 = getTheme('background.z3');
const backgroundZ4 = getTheme('background.z4');
const textMain = getTheme('text.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');

export const Wrapper = styled.div`
  flex: 1;
  max-width: ${pxToRem(440)};
  height: fit-content;
  flex-direction: column;
  align-items: center;
  background: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;
  padding: ${spacingLg}px ${spacingMd}px;

  @media ${mqDevices.inMobileAndTablet} {
    flex-direction: row;
    width: 100vh;
    max-width: 100%;
    align-self: center;
    margin-bottom: ${spacingLg}px;
    padding: ${spacingMd}px;
  }
`;

export const Datas = styled(Typography)`
  font-weight: 500;
  opacity: 0.5 !important;
  margin-top: ${spacingLg}px;
`;

export const Card = styled.div`
  width: 100%;
  flex-direction: column;
  border-radius: ${themeRadiusCard}px;
  padding: 0 ${spacingMd}px;
  background: ${backgroundZ3};
  margin: ${spacingMd}px 0;

  @media ${mqDevices.inMobileAndTablet} {
    height: 100%;
    margin: 0;
    margin-bottom: ${spacingMd}px;
  }
`;

export const WrapperItem = styled.div`
  align-items: center;
  margin: ${spacingMd}px 0;
`;

export const IconStyled = styled(Icon).attrs({
  fontSize: pxToRem(20),
})`
  color: ${textMain};
  opacity: 0.3;
`;

export const LabelBox = styled.div`
  flex-direction: column;
  margin-left: ${spacingMd}px;
`;

export const Label = styled(Typography).attrs({ variant: 'xxs' })`
  text-transform: uppercase;
  opacity: 0.5 !important;
  font-weight: bold;
`;

export const Value = styled(Typography)`
  opacity: 0.8 !important;
`;

export const ButtonEditData = styled(Button)`
  width: 100%;
  height: ${pxToRem(60)};
  border-radius: ${borderRadiusMax}px;
`;

export const IconEdit = styled(Icon)`
  font-size: ${pxToRem(17)};
  color: ${brandPrimaryContrast};
  margin-right: ${spacingMd}px;
`;

export const Column = styled.div`
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media ${mqDevices.inMobileAndTablet} {
    height: 100%;
    justify-content: center;
  }
`;

export enum UserRoles {
  ADMIN = 'ADMINISTRADOR',
  SPECTATOR = 'ESPECTADOR',
}

export enum UserStatus {
  ACTIVE = 'ATIVO',
  PENDING = 'PENDENTE',
  BLOCKED = 'BLOQUEADO',
  CANCELLED = 'CANCELADO',
}

export enum PermissionType {
  ALLOWED = 'ALLOWED',
  NOT_ALLOWED = 'NOT_ALLOWED',
}

import { Krypton } from '~/modules';
import { ResponseError } from '~/utils';
import request from '../request';

export const getPublicKey = async (): Promise<KeysPublic> => {
  try {
    const { data } = await request.get('/keys/public');
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const login = async (
  { username, password }: AuthUser,
  { publicKey }: KeysPublic,
): Promise<Token> => {
  try {
    const { data } = await request.post('/login', {
      username: Krypton.encrypt(username, publicKey),
      password: Krypton.encrypt(password, publicKey),
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const logout = async (): Promise<void> => {
  try {
    await request.post('/logout');
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const fetchUser = async (): Promise<User> => {
  try {
    const { data } = await request.get('/users/me/details');
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

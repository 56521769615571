import 'react-toastify/dist/ReactToastify.min.css';
import { createGlobalStyle, getTheme } from '~/modules';
import { mqDevices } from '~/utils';

const backgroundColor = getTheme('background.z0');
const textColor = getTheme('text.main');

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700,900');
  * {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
  }
  body {
    background: ${backgroundColor};
    color: ${textColor};
    margin: 0;
    padding: 0;
    min-height: 100vh;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    text-rendering: optimizeLegibility !important;
    overscroll-behavior: contain;
    scrollbar-width: none;
    -webkit-font-smoothing: antialiased !important;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
  html {
    min-height: 100vh;
    width: 100%;
  }
  input, button {
    font-family: 'Poppins', sans-serif;
  }
  button {
    cursor: pointer;
  }
  div {
    display: flex;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  .MuiTypography-body1,
  .MuiTypography-caption,
  .MuiFormLabel-root,
  .MuiTypography-button,
  .MuiTypography-root {
    font-family: 'Poppins', sans-serif;
  }

  html {
    font-size: 85% ;
    @media ${mqDevices.inSmallDesktop} {
      font-size: 75% ;
    }
  }
`;

import { Routes } from '~/routes';

export const getActivatedRoute = (route: Routes): boolean => {
  const { pathname } = window.location;
  return pathname.includes(route);
};

export const getIsRoute = (route: Routes): boolean => {
  const { pathname } = window.location;
  return pathname === route;
};

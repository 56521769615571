import { ListPagination, ResponseError } from '~/utils';
import request from '../request';

export const getExports = async ({
  page = ListPagination.INITIAL_PAGE,
  size = ListPagination.SIZE,
}: GetExportsProps): Promise<ListResponse<FileExport>> => {
  try {
    const { data } = await request.get(`/files/exports`, {
      params: {
        page,
        size,
      },
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const downloadExport = async (id: string): Promise<void> => {
  try {
    return await request.get(`/files/exports/${id}/download`, {
      responseType: 'blob',
    });
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const uploadFile = async (params: ExportFile): Promise<void> => {
  try {
    await request.post(`/files/exports`, params);
  } catch (error) {
    throw new ResponseError(error);
  }
};

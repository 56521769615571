// import { OptionProps, StylesConfig } from 'react-select';
// import {
//   StylesProps,
//   defaultStyles,
// } from 'react-select/dist/declarations/src/styles';
import {
  CSSObjectWithLabel,
  SelectComponent,
  getTheme,
  pxToRem,
  styled,
} from '~/modules';
import Typography from '../Typography';

const backgroundZ0 = getTheme('background.z0');
const backgroundZ2 = getTheme('background.z2');
const backgroundZ4 = getTheme('background.z4');
const frostedGlass = getTheme('frosted glass.z0');
const reverseZ4 = getTheme('background.reverse.z4');
const brandPrimaryMain = getTheme('brand.primary.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');
const dangerLight = getTheme('danger.light');
const themeRadiusInput = getTheme('themeRadius.input');
const themeRadiusCard = getTheme('themeRadius.card');
const textLink = getTheme('text.link');
const textMain = getTheme('text.main');
const textContrast = getTheme('text.contrast');
const spacingMd = getTheme('spacing.md');
const spacingSm = getTheme('spacing.sm');

export const Wrapper = styled.div`
  width: 100%;
  flex-direction: column;
`;

export const Select = styled(SelectComponent).attrs(
  (props: any): SelectedProps => {
    return {
      styles: {
        option: (provided: CSSObjectWithLabel, state: PropsStyleSelect) => ({
          ...provided,
          color: state.selectProps.textMain,
          backgroundColor: state.isSelected
            ? state.selectProps.backgroundZ0
            : state.selectProps.backgroundZ2,
          width: '100%',
          display: 'flex',
          paddingLeft: state.selectProps.spacingSm,
          fontWeight: state.isSelected ? 'bold' : '500',
          '&:hover': {
            backgroundColor: state.selectProps.backgroundPrimaryMain,
            color: state.selectProps.primaryContrast,
          },
          ...props.customStyles.option,
        }),
        control: (provided: CSSObjectWithLabel, state: PropsStyleSelect) => ({
          ...provided,
          width: '100%',
          height: pxToRem(48),
          display: 'flex',
          backgroundColor: state.selectProps.frostedGlass,
          borderRadius: 8,
          borderColor: state.selectProps.textMain20,
          '&:active': {
            borderColor: state.selectProps.backgroundPrimaryMain,
          },
          '&:hover': {
            borderColor: state.selectProps.backgroundPrimaryMain,
          },
          '&:focus': {
            borderColor: state.selectProps.backgroundPrimaryMain,
          },
          color: state.selectProps.textMain,
          boxShadow: '',
          ...props.customStyles.control,
        }),
        menuList: () => ({
          padding: 0,
          flexDirection: 'column',
          width: '100%',
          ...props.customStyles.menuList,
        }),
        menu: (provided: CSSObjectWithLabel) => ({
          ...provided,
          ...props.customStyles.menu,
        }),
        dropdownIndicator: (
          provided: CSSObjectWithLabel,
          state: PropsStyleSelect,
        ) => ({
          marginRight: state.selectProps.spacingSm,
          marginLeft: state.selectProps.spacingSm,
          color: state.selectProps.reverseZ4,
          ...props.customStyles.dropdownIndicator,
        }),
        singleValue: (
          provided: CSSObjectWithLabel,
          state: PropsStyleSelect,
        ) => ({
          ...provided,
          color: state.selectProps.textMain,
          ...props.customStyles.singleValue,
        }),
        input: (provided: CSSObjectWithLabel, state: PropsStyleSelect) => ({
          ...provided,
          color: state.selectProps.reverseZ4,
          ...props.customStyles.input,
        }),
        placeholder: (
          provided: CSSObjectWithLabel,
          state: PropsStyleSelect,
        ) => ({
          ...provided,
          color: state.selectProps.textMain,
          ...props.customStyles.placeholder,
        }),
      },
      indicatorSeparator: () => false,
      color: textLink(props) as string,
      frostedGlass: frostedGlass(props) as string,
      backgroundZ0: backgroundZ0(props) as string,
      backgroundZ2: backgroundZ2(props) as string,
      backgroundZ4: backgroundZ4(props) as string,
      backgroundPrimaryMain: brandPrimaryMain(props) as string,
      themeRadiusInput: themeRadiusInput(props) as number,
      themeRadiusCard: themeRadiusCard(props) as number,
      textMain20: `${textMain(props)}20`,
      textMain: textMain(props) as string,
      textContrast: textContrast(props) as string,
      primaryContrast: brandPrimaryContrast(props) as string,
      reverseZ4: reverseZ4(props) as string,
      spacingMd: `${spacingMd(props)}px`,
      spacingSm: `${spacingSm(props)}px`,
    };
  },
)<any>``;

export const Label = styled(Typography).attrs({
  variant: 'lg',
})`
  font-weight: 700;
`;

export const CustomLabel = styled(Typography).attrs({
  variant: 'sm',
})`
  font-weight: 500;
  margin: ${spacingSm}px 0;
`;

export const Error = styled(Typography).attrs({
  variant: 'sm',
})`
  color: ${dangerLight};
  margin-top: ${spacingSm}px;
`;

import { FC } from 'react';
import { FooterButtons } from '~/components';
import { FormikProps, useFormikContext } from '~/modules';
import { Masks, StepsClientRegister } from '~/utils';
import { Props } from '..';
import { Content, Input, TitlePageComponent, WrapperContent } from '../styles';

const DataClient: FC<Props> = ({ onNavigateId }) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    setFieldValue,
  }: FormikProps<ClientRegister> = useFormikContext();

  return (
    <WrapperContent>
      <Content>
        <TitlePageComponent>Dados da Cliente</TitlePageComponent>
        <Input
          label="Número do Documento"
          placeholder="Informe o número do RG do cliente"
          id="identityDocument.number"
          name="identityDocument.number"
          error={
            touched.identityDocument?.number && errors.identityDocument?.number
          }
          value={values.identityDocument?.number}
          onChange={({ target: { name, value } }) =>
            setFieldValue(name, value.toUpperCase())
          }
        />
        <Input
          label="Órgão Emissor"
          placeholder="Informe o órgão emissor. Ex: SSP"
          id="identityDocument.dispatchingAgency"
          name="identityDocument.dispatchingAgency"
          error={
            touched.identityDocument?.dispatchingAgency &&
            errors.identityDocument?.dispatchingAgency
          }
          value={values.identityDocument?.dispatchingAgency}
          onChange={({ target: { name, value } }) =>
            setFieldValue(name, value.toUpperCase())
          }
        />
        <Input
          label="Estado"
          placeholder="SP"
          id="identityDocument.state"
          name="identityDocument.state"
          error={
            touched.identityDocument?.state && errors.identityDocument?.state
          }
          value={values.identityDocument?.state}
          mask={Masks.state}
          onChange={({ target: { value } }) =>
            setFieldValue('identityDocument.state', value.toUpperCase())
          }
        />
        <Input
          label="Data de Emissão"
          placeholder="00/00/0000"
          id="identityDocument.issueDate"
          name="identityDocument.issueDate"
          error={
            touched.identityDocument?.issueDate &&
            errors.identityDocument?.issueDate
          }
          value={values.identityDocument?.issueDate}
          mask={Masks.date}
          onChange={handleChange}
        />
      </Content>
      <FooterButtons
        firstBtn={{
          id: 'btn_clear',
          label: 'Voltar',
          disabled: isSubmitting,
          onPress: () => onNavigateId(StepsClientRegister.DOCUMENTS - 1),
        }}
        secondaryBtn={{
          id: 'btn_confirm',
          label: 'Próximo',
          onPress: () => onNavigateId(StepsClientRegister.DOCUMENTS + 1),
        }}
      />
    </WrapperContent>
  );
};

export default DataClient;

import { FC, useState } from 'react';
import { MetadataRegister, ProgressIndicator } from '~/components';
import { FormBehavior, observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import {
  AlertMessages,
  PersonType,
  StepsSideAccount,
  showAlert,
  useStores,
} from '~/utils';
import DataAccount from './DataAccount';
import {
  initialValues,
  initialValuesPF,
  validationSchema,
  validationSchemaPF,
} from './schema';
import SideAccountRegister from './SideAccountRegister';

export const SideAccountRegisterContainer: FC = () => {
  const navigate = useNavigate();
  const {
    clients: { currentClient, sideAccountRegister, sideAccountRegisterPF },
    utils: { setProgressId },
  } = useStores();

  const [nameProduct, setNameProduct] = useState('');

  const handleFormSubmit = async (values: SideAccountRegister) => {
    await sideAccountRegister(values);
    showAlert({
      message: AlertMessages.SUCCESS_SIDE_ACCOUNT_REGISTER,
      type: 'success',
    });
    navigate(Routes.CLIENT_ACCOUNT_DETAILS);
  };

  const handleFormSubmitPF = async (values: SideAccountRegisterPF) => {
    await sideAccountRegisterPF(values);
    showAlert({
      message: AlertMessages.SUCCESS_SIDE_ACCOUNT_REGISTER,
      type: 'success',
    });
    navigate(Routes.CLIENT_ACCOUNT_DETAILS);
  };

  const isClientPF = currentClient?.personType === PersonType.PF;

  return (
    <FormBehavior
      validationSchema={isClientPF ? validationSchemaPF : validationSchema}
      initialValues={(isClientPF ? initialValuesPF : initialValues) as any}
      onSubmit={!isClientPF ? handleFormSubmit : handleFormSubmitPF}
    >
      <SideAccountRegister>
        <ProgressIndicator
          steps={[
            {
              id: StepsSideAccount.DATA,
              label: 'Dados da Conta',
              content: (
                <DataAccount
                  client={currentClient}
                  nameProduct={nameProduct}
                  onNavigateId={(id) => setProgressId(id)}
                  setNameProduct={(name) => setNameProduct(name)}
                />
              ),
            },
            {
              id: StepsSideAccount.META_DATA,
              label: 'Metadata',
              content: (
                <MetadataRegister onNavigateId={(id) => setProgressId(id)} />
              ),
            },
          ]}
        />
      </SideAccountRegister>
    </FormBehavior>
  );
};

export default observer(SideAccountRegisterContainer);

import { SceneWrapper, Typography } from '~/components';
import { getTheme, pxToRem, styled } from '~/modules';
import { mqDevices } from '~/utils';

const backgroundZ4 = getTheme('background.z4');
const backgroundZ3 = getTheme('background.z3');

const spacingMd = getTheme('spacing.md');
const spacingXl = getTheme('spacing.xl');
const spacingXs = getTheme('spacing.xs');
const spacingLg = getTheme('spacing.lg');

const themeRadiusCard = getTheme('themeRadius.card');
const boxShadowTertiaryGlow = getTheme('boxShadow.z4');

export const Wrapper = styled(SceneWrapper)`
  justify-content: center;
  flex: 1;
`;

export const Content = styled.div`
  flex: 1;

  @media ${mqDevices.inMobileAndTablet} {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  flex-direction: column;
  margin-top: ${spacingXl}px;

  & + & {
    margin-left: ${spacingXl}px;
    flex: 1;

    @media ${mqDevices.inMobileAndTablet} {
      margin-left: 0;
    }
  }
`;

export const ColumnData = styled(Column)`
  @media ${mqDevices.inMobileAndTablet} {
    flex: 1;
    flex-direction: row;
    justify-content: center;
  }
`;

export const Card = styled.div`
  width: 100%;
  height: max-content;
  flex-direction: column;
  max-width: ${pxToRem(400)};
  padding: ${spacingLg}px;
  background-color: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;
  margin-bottom: ${spacingXl}px;
  ${boxShadowTertiaryGlow}

  @media ${mqDevices.inMobileAndTablet} {
    height: 100%;
    max-width: 100%;
    justify-content: space-between;
    margin-right: ${spacingMd}px;
  }
`;

export const Name = styled(Typography).attrs({
  variant: 'md',
})`
  font-weight: bold;
  margin-bottom: ${spacingLg}px;
  word-break: break-all;
`;

export const Label = styled(Typography).attrs({
  variant: 'xxs',
})`
  font-weight: 700;
  opacity: 0.5 !important;
  margin-bottom: ${spacingXs}px;
`;

export const WrapperCard = styled.div``;

export const DescriptionLabel = styled(Typography).attrs({ variant: 'xs' })`
  font-weight: 500;
`;

export const LittleCard = styled.div`
  flex-direction: column;
  font-weight: 500;
  opacity: 0.8 !important;
  background-color: ${backgroundZ3};
  border-radius: ${themeRadiusCard}px;
  padding: ${spacingMd}px;
  margin-right: ${spacingMd}px;
  min-width: ${pxToRem(130)};
  margin-top: ${spacingMd}px;
  width: 100%;

  &:last-child {
    margin-right: 0px;
  }

  @media ${mqDevices.inMobileAndTablet} {
    min-width: 0;
  }
`;

export const Title = styled(Typography).attrs({ variant: 'md' })`
  display: flex;
  font-weight: bold;
`;

export const Total = styled(Typography).attrs({ variant: 'md' })`
  opacity: 0.5 !important;
  margin-left: ${spacingMd}px;
`;

import { FC } from 'react';
import { Text, Wrapper } from './styles';

type Props = {
  name?: string;
  className?: string;
  secondary?: boolean;
  tertiary?: boolean;
  onPress?(): void;
};

const LetteredAvatar: FC<Props> = ({
  name,
  className,
  secondary,
  tertiary,
  onPress,
}) => {
  const firstLetter = name?.toUpperCase().slice(0, 1);

  return (
    <Wrapper
      className={className}
      secondary={secondary ? `${secondary}` : undefined}
      tertiary={tertiary ? `${tertiary}` : undefined}
      onClick={onPress}
    >
      <Text>{firstLetter}</Text>
    </Wrapper>
  );
};

export default LetteredAvatar;

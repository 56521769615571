import { ListPagination, ResponseError } from '~/utils';
import request from '../request';

export const getFiles = async ({
  page = ListPagination.INITIAL_PAGE,
  size = ListPagination.SIZE,
}: GetFilesProps): Promise<ListResponse<FileUpload>> => {
  try {
    const { data } = await request.get(`/files/imports`, {
      params: {
        page,
        size,
      },
    });
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const uploadFile = async (values: FormData): Promise<void> => {
  try {
    await request.post(`/files/imports`, values, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  } catch (error) {
    throw new ResponseError(error);
  }
};

import { FC, ReactNode, useEffect, useState } from 'react';
import { Analytics } from '~/services';
import { AnalyticsEvents, KeyboardEvents, showAlert } from '~/utils';
import { Button, LoadingIndicator } from './styles';

type ButtonProps = {
  id: string;
  children: ReactNode | string;
  secondary?: boolean;
  tertiary?: boolean;
  transparent?: boolean;
  disabled?: boolean;
  column?: boolean;
  onKeyEnter?: boolean;
  onLoading?: boolean;
  onPress(): Promise<void> | void;
};

const Touchable: FC<ButtonProps> = ({
  id,
  children,
  secondary,
  tertiary,
  transparent,
  disabled,
  column,
  onKeyEnter,
  onLoading = false,
  onPress,
  ...rest
}) => {
  const [loading, setLoading] = useState(false);

  const handleOnPress = async (): Promise<void> => {
    try {
      setLoading(true);
      await onPress();
    } catch ({ message, status }) {
      showAlert({ message });
    } finally {
      setLoading(false);
      Analytics.logButtonClick({
        eventName: AnalyticsEvents.BUTTON_CLICK,
        eventParams: {
          accessibility: children?.toString(),
        },
      });
    }
  };

  const detectKeyDown = (event: KeyboardEvent) => {
    if (onKeyEnter && !disabled && event.key === KeyboardEvents.ENTER) {
      handleOnPress();
    }
  };

  useEffect(() => {
    document.addEventListener(KeyboardEvents.TYPE, detectKeyDown, true);
    return () => {
      document.removeEventListener(KeyboardEvents.TYPE, detectKeyDown, true);
    };
  }, [!disabled]);

  return (
    <Button
      {...rest}
      type="button"
      id={`test_${id}`}
      loading={loading || onLoading}
      secondary={secondary ? `${secondary}` : undefined}
      tertiary={tertiary ? `${tertiary}` : undefined}
      transparent={transparent}
      disabled={disabled}
      column={column}
      onClick={handleOnPress}
    >
      {loading || onLoading ? <LoadingIndicator /> : children}
    </Button>
  );
};

export default Touchable;

export enum CharactersLength {
  USER_MAX = 254,
  USER_MIN = 3,
  PASSWORD_MIN = 10,
  EMPTY = 0,
  TEXT_BLOCKED_MAX = 500,
  TEXT_BLOCKED_MIN = 20,
  AGENCY = 4,
  PHONE_DDD = 11,
  DOCUMENT_MAX_LENGTH = 18,
}

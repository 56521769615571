import { FC, useEffect, useState } from 'react';
import { ModalUploadFile } from '~/components';
import { observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import { AlertMessages, UserRoles, showAlert, useStores } from '~/utils';
import Uploads from './Uploads';

export const UploadsContainer: FC = () => {
  const navigate = useNavigate();
  const {
    user: { user },
    uploads: { uploadFile, getFiles, uploadsList, setCurrentFile },
  } = useStores();

  const [onUploadFileModal, setOnUploadFileModal] = useState(false);
  const [isFilesLoading, setIsFilesLoading] = useState(true);

  const handleUploadFileModal = () =>
    setOnUploadFileModal((onUploadFileModal) => !onUploadFileModal);

  const handleUploadFile = async (values: UploadFile) => {
    await uploadFile(values);
    handleUploadFileModal();
    onGetFiles({});
    showAlert({
      message: AlertMessages.FILES_UPLOAD_FILE,
      type: 'success',
    });
  };

  const onGetFiles = async (values: GetUsersProps) => {
    setIsFilesLoading(true);
    try {
      await getFiles(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setIsFilesLoading(false);
    }
  };

  const handleFileDetails = async (values: FileUpload) => {
    setCurrentFile(values);
    navigate(Routes.FILES_DETAILS);
  };

  useEffect(() => {
    onGetFiles({});
  }, []);

  return (
    <>
      <Uploads
        isFilesLoading={isFilesLoading}
        filesList={uploadsList}
        isUserEspectator={user.role === UserRoles.SPECTATOR}
        onPressFile={handleFileDetails}
        onChangePage={(page) => onGetFiles({ page })}
        onPressUploadFile={handleUploadFileModal}
      />
      <ModalUploadFile
        isOpen={onUploadFileModal}
        handleClose={handleUploadFileModal}
        handleFormSubmit={handleUploadFile}
      />
    </>
  );
};

export default observer(UploadsContainer);

import { SceneWrapper, Typography } from '~/components';
import { getTheme, styled } from '~/modules';
import { mqDevices } from '~/utils';

const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXxl = getTheme('spacing.xxl');

export const Wrapper = styled(SceneWrapper)`
  flex: 1;
`;

export const ContentBox = styled.div`
  width: 100%;
  padding: ${spacingXxl}px 0;

  @media ${mqDevices.inMobileAndTablet} {
    flex-direction: column;
  }
`;

export const PaginatedListBox = styled.div`
  flex-direction: column;
  margin-left: ${spacingMd}px;
`;

export const Title = styled(Typography).attrs({ variant: 'md' })`
  font-weight: bold;
`;

export const ContentColumn = styled.div`
  flex: 1;
  flex-direction: column;
  margin-left: ${spacingLg}px;
`;

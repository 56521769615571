import { groupBy } from '~/modules';
import { formatTimestampToDateOrToday } from './dateFormatter';

type Props<ContentItem> = {
  content?: Array<ContentItem>;
  renderPeriodValue(date: ContentItem): number;
};

export const formatGroupDate = <ContentItem>({
  content,
  renderPeriodValue,
}: Props<ContentItem>) => {
  const activitiesDictionary = groupBy(content, (item) =>
    formatTimestampToDateOrToday(renderPeriodValue(item)),
  );
  return Object.entries(activitiesDictionary).map(([date, listActivities]) => ({
    date,
    data: listActivities,
  }));
};

import { Icon, getTheme, ifStyle, pxToRem, styled } from '~/modules';
import Button from '../Button';
import Touchable from '../Touchable';
import Typography from '../Typography';

const primaryMain = getTheme('brand.primary.main');
const primaryContrast = getTheme('brand.primary.contrast');

const backgroundZ2 = getTheme('background.z2');
const backgroundZ4 = getTheme('background.z4');
const textMain = getTheme('text.main');

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');

const boxShadowZ3 = getTheme('boxShadow.z3');
const themeRadiusCard = getTheme('themeRadius.card');
const themeRadiusButton = getTheme('themeRadius.button');
const borderRadiusMax = getTheme('borderRadius.max');
const figmaInputBorders = getTheme('figma.input.borders');

const isWeekend = ifStyle('isWeekend');
const isSelected = ifStyle('isSelected');
const isBetweenRange = ifStyle('isBetweenRange');
const isCurrentDay = ifStyle('isCurrentDay');
const isClickable = ifStyle('clickable');
const isDisabled = ifStyle('disabled');

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const FilterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${pxToRem(54)};
  width: fit-content;
  padding: 0 ${spacingMd}px;
  font-weight: bold;
  background-color: transparent;
  color: ${textMain};
  border-radius: ${borderRadiusMax}px;
  border: 1px solid ${textMain};
`;

export const FilterTextButton = styled(Touchable)`
  padding: ${spacingMd}px;
`;

type IconFilterProps = {
  clickable?: boolean;
};

export const IconFilter = styled(Icon).attrs({
  fontSize: pxToRem(17),
})<IconFilterProps>`
  cursor: ${isClickable('pointer', 'default')};
`;

export const DatePickerBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${themeRadiusCard}px;
  background-color: ${backgroundZ4};
  padding: ${spacingMd}px;
  width: ${pxToRem(370)};
  position: absolute;
  top: ${pxToRem(-30)};
  z-index: 4;
  ${boxShadowZ3};
`;

export const MonthBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: ${spacingMd}px 0;
`;

export const Month = styled(Typography).attrs({ variant: 'md' })`
  font-weight: 500;
`;

export const IconsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

export const IconButton = styled(Button)`
  cursor: pointer;
  width: ${pxToRem(50)};
  height: ${pxToRem(50)};
  border-radius: ${pxToRem(50)};
  margin-left: ${spacingSm}px;
`;

export const DaysOfWeekBox = styled.div`
  padding: ${spacingSm}px 0;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  opacity: 0.5;
`;

type DayOfWeekProps = {
  isWeekend?: boolean;
};

export const DayOfWeek = styled(Typography)<DayOfWeekProps>`
  opacity: ${isWeekend(0.5, 1)} !important;
  font-weight: 500;
`;

export const CalendarDaysBox = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 100%;
`;

type CalendarDayBoxProps = {
  disabled?: boolean;
};

export const CalendarDayBox = styled.div<CalendarDayBoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${pxToRem(40)};
  margin: ${spacingSm}px 0;
  opacity: ${isDisabled(0.4, 1)};
  cursor: ${isDisabled('default', 'pointer')};
`;

type CalendarDayProps = {
  isSelected?: boolean;
  isBetweenRange?: boolean;
  isCurrentDay?: boolean;
};

export const CalendarDay = styled(Typography)<CalendarDayProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  border: ${pxToRem(1)} solid ${figmaInputBorders};
  background-color: ${isSelected(
    primaryMain,
    isBetweenRange(backgroundZ2, isCurrentDay(backgroundZ2, 'transparent')),
  )};
  color: ${isSelected(primaryContrast, textMain)};
  border-radius: ${themeRadiusButton}px;
`;

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: ${spacingMd}px 0;
`;

export const ClearButton = styled(Button)`
  width: 100%;
  height: ${pxToRem(50)};
  border-radius: ${themeRadiusButton}px;
`;

export const SearchButton = styled(ClearButton)`
  margin-left: ${spacingMd}px;
`;

import { FC, useState } from 'react';
import { ModalConfirmation, ModalEditUser } from '~/components';
import { isEmpty, observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import {
  AlertMessages,
  EventOptionsAccount,
  showAlert,
  useStores,
} from '~/utils';
import UserDetails from './UserDetails';

export const UserDetailsContainer: FC = () => {
  const {
    user: { user, logout },
    users: { userSelected, editUser, setStatusUser },
  } = useStores();

  const navigate = useNavigate();
  const handleNavigate = (route: Routes) => navigate(route);

  const [onModalEdit, setonModalEdit] = useState(false);
  const handleOnModalEdit = () => setonModalEdit((onModalEdit) => !onModalEdit);

  const [textModalConfirmation, setTextModalConfirmation] =
    useState<AlertMessages>('' as AlertMessages);
  const [optionSelected, setOptionSelected] = useState<EventOptionsAccount>();

  const handleEditUser = async (values: EditUser) => {
    await editUser(values);
    handleOnModalEdit();
    showAlert({
      message: AlertMessages.SUCCESS_EDIT_USER,
      type: 'success',
    });
  };

  const handleOnpressOption = (
    option: EventOptionsAccount,
    text: AlertMessages,
  ) => {
    setOptionSelected(option);
    setTextModalConfirmation(text);
  };

  const handleOptionSelected = async () => {
    const message = await setStatusUser(userSelected.id, optionSelected);
    setTextModalConfirmation('' as AlertMessages);
    showAlert({ message, type: 'success' });
    userSelected.id === user.id && logout();
  };

  return (
    <>
      <UserDetails
        user={userSelected}
        onNavigate={handleNavigate}
        onPressEditData={handleOnModalEdit}
        onPressOption={handleOnpressOption}
      />
      <ModalEditUser
        isOpen={onModalEdit}
        handleClose={handleOnModalEdit}
        handleFormSubmit={handleEditUser}
      />
      <ModalConfirmation
        isOpen={!isEmpty(textModalConfirmation)}
        description={textModalConfirmation}
        onConfirm={handleOptionSelected}
        onCancel={() => setTextModalConfirmation('' as AlertMessages)}
      />
    </>
  );
};

export default observer(UserDetailsContainer);

import styled from 'styled-components';
import { getTheme, pxToRem } from '~/modules';
import Typography from '../Typography';

const spacingSm = getTheme('spacing.sm');
const successLight = getTheme('success.light');
const dangerMain = getTheme('danger.main');
const warningMain = getTheme('warning.main');
const textContrast = getTheme('text.contrast');
const textMain = getTheme('text.main');
const infoMain = getTheme('info.main');

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  width: ${pxToRem(12)};
  height: ${pxToRem(12)};
  border-radius: 50%;
`;

export const BallGreen = styled(Icon)`
  background-color: ${successLight};
`;
export const BallRed = styled(Icon)`
  background-color: ${dangerMain};
`;

export const BallYellow = styled(Icon)`
  background-color: ${warningMain};
`;

export const BallBlue = styled(Icon)`
  background-color: ${infoMain};
`;

export const BallEmpty = styled(Icon)`
  background-color: ${textContrast};
  border: 1.8px solid ${textMain};
`;

type TextProps = {
  textColor?: string;
};

export const Text = styled(Typography).attrs({
  variant: 'xs',
})<TextProps>`
  margin-left: ${spacingSm}px;
  font-weight: 500;
  color: ${({ textColor }) => textColor};
`;

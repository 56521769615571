import { Typography, getTheme, ifStyle, pxToRem, styled } from '~/modules';

const brandPrimaryMain = getTheme('brand.primary.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');
const brandSecondaryMain = getTheme('brand.secondary.main');
const brandSecondaryContrast = getTheme('brand.secondary.contrast');

const isSecondary = ifStyle('secondary');
const isTertiary = ifStyle('tertiary');

type Props = {
  secondary?: boolean | string;
  tertiary?: boolean | string;
};
export const Wrapper = styled.div<Props>`
  background-color: ${isSecondary(
    brandSecondaryMain,
    isTertiary(brandPrimaryContrast, brandPrimaryMain),
  )};
  color: ${isSecondary(
    brandSecondaryContrast,
    isTertiary(brandPrimaryMain, brandPrimaryContrast),
  )};

  width: ${pxToRem(106)};
  height: ${pxToRem(106)};
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const Text = styled(Typography)`
  color: inherit;
`;

import { FormValidator } from '~/modules';
import { timestampNow } from '~/utils';

export const validationSchema = FormValidator.object().shape({
  productId: FormValidator.string().required('Campo obrigatório'),
  personId: FormValidator.string().required('Campo obrigatório'),
  type: FormValidator.string().required('Campo obrigatório'),
});

export const validationSchemaPF = FormValidator.object().shape({
  productId: FormValidator.string().required('Campo obrigatório'),
  personId: FormValidator.string().required('Campo obrigatório'),
});

export const initialValues: SideAccountRegister = {
  type: '',
  productId: '',
  personId: '',
  internalAccountId: '',
  metadata: [{ key: '', value: '', id: timestampNow() }],
};

export const initialValuesPF: SideAccountRegisterPF = {
  productId: '',
  personId: '',
  internalAccountId: '',
  metadata: [{ key: '', value: '', id: timestampNow() }],
};

import { FC } from 'react';
import { formatShortDate } from '~/utils';
import HeaderModal from '../HeaderModal';
import {
  Card,
  Columns,
  Content,
  Row,
  TitleColumn,
  ValueData,
  Wrapper,
  WrapperItemMetaData,
} from './styles';

type Props = {
  isOpen: boolean;
  currentProduct: Product;
  handleClose(): void;
};

const ModalListInstallmentDates: FC<Props> = ({
  isOpen,
  currentProduct,
  handleClose,
}) => {
  const { installmentDates } = currentProduct;

  return (
    <Wrapper isOpen={isOpen} onClose={handleClose}>
      <HeaderModal title="Ciclos de Faturamento" onPressClose={handleClose} />
      <Content>
        <Row>
          <Card>
            <Columns>
              <TitleColumn>Data inicial</TitleColumn>
              <TitleColumn>Data final</TitleColumn>
            </Columns>
            {installmentDates?.map((date) => {
              return (
                <WrapperItemMetaData key={date.startDate}>
                  <ValueData>
                    {formatShortDate(new Date(date.startDate))}
                  </ValueData>
                  <ValueData>
                    {formatShortDate(new Date(date.endDate))}
                  </ValueData>
                </WrapperItemMetaData>
              );
            })}
          </Card>
        </Row>
      </Content>
    </Wrapper>
  );
};

export default ModalListInstallmentDates;

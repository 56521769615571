import { FC } from 'react';
import {
  faCircleSmall,
  faIdCardSolid,
  faLockSolid,
  faPlus,
  faSearch,
} from '~/assets';
import {
  ProductStatus,
  ProductStatusPt,
  ProductType,
  ProductTypePt,
  parseEnumToPt,
  positionsList,
} from '~/utils';
import If from '../If';
import StatusIndicator from '../StatusIndicator';
import ToggleSwitch from '../ToggleSwitch';
import {
  Card,
  Description,
  IconCard,
  IconHiddenName,
  IconHidenCard,
  IconSearch,
  Name,
  Row,
  RowButtonSearch,
  SearchIconBox,
  WrapperToggle,
} from './styles';

type Props = {
  name?: string;
  type?: ProductType;
  status?: ProductStatus;
  description?: string;
  isAllowed?: boolean;
  showInfo?: boolean;
  sideAccount?: boolean;
  onPress?(): void;
  onPressToggleSwitch?(isActive: boolean): Promise<void> | void;
};

const ProductRow: FC<Props> = ({
  name = '',
  isAllowed = false,
  showInfo = true,
  status,
  description,
  type,
  sideAccount,
  onPress = () => {},
  onPressToggleSwitch,
}) => (
  <Card>
    <Row>
      <IconCard icon={showInfo ? faIdCardSolid : faLockSolid} />
      <If condition={!showInfo}>
        {positionsList.map((value) => (
          <IconHiddenName key={value} icon={faCircleSmall} />
        ))}
      </If>
      <If condition={showInfo}>
        <Name>{name}</Name>
      </If>
    </Row>
    <Row>
      <StatusIndicator status={parseEnumToPt(type, ProductTypePt)}>
        <IconHidenCard icon={faLockSolid} />
      </StatusIndicator>
    </Row>
    <Row>
      <StatusIndicator status={parseEnumToPt(status, ProductStatusPt)}>
        <IconHidenCard icon={faLockSolid} />
      </StatusIndicator>
    </Row>
    <Row>
      <Description>
        {showInfo
          ? description
          : 'Você não tem permissão para visualizar este produto'}
      </Description>
    </Row>
    <If condition={!onPressToggleSwitch}>
      <RowButtonSearch>
        <SearchIconBox
          id="onPress_product"
          onPress={onPress}
          disabled={
            !showInfo || (sideAccount && status === ProductStatus.INACTIVE)
          }
        >
          <IconSearch icon={sideAccount ? faPlus : faSearch} />
        </SearchIconBox>
      </RowButtonSearch>
    </If>
    <If condition={!!onPressToggleSwitch}>
      <WrapperToggle>
        <ToggleSwitch isActive={isAllowed} onPress={onPressToggleSwitch} />
      </WrapperToggle>
    </If>
  </Card>
);

export default ProductRow;

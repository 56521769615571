import { FC, useMemo } from 'react';
import {
  faClock,
  faLayerPlus,
  faLock,
  faMoneyBill,
  faUnlock,
  faXmark,
} from '~/assets';
import {
  AccountTransactionRow,
  DatePicker,
  OptionButton,
  OptionsList,
  PaginatedList,
} from '~/components';
import { currencyParser } from '~/modules';
import {
  AccountPaymentType,
  AccountTypePt,
  AlertMessages,
  EventOptionsAccount,
  ProductStatus,
  ProductStatusPt,
  formatPaymentTypeToSelectValue,
  formatShortDate,
  generateDateObject,
  parseEnumToPt,
  transactionsColumns,
} from '~/utils';
import {
  ButtonInfo,
  Card,
  CardLabel,
  CardValue,
  CardsBox,
  ContentBox,
  IconPlus,
  SelectInputComponent,
  StatusCard,
  StatusCardLabel,
  StatusCardValue,
  Title,
  TopBox,
  Wrapper,
  WrapperFilters,
} from './styles';

type Props = {
  account: Account;
  listPaging?: ListPaging;
  transactionsGroups: GroupDateProps<Transaction>[];
  isTransactionsLoading: boolean;
  selectedStartDate?: CalendarDay;
  selectedEndDate?: CalendarDay;
  onPressAditionalInfo(): void;
  onPressScheduling(): void;
  onPressAddBalance(): void;
  onPressUpdateLimit(): void;
  onPressTransaction(transactionId: string): void;
  onChangePage(page: number): void;
  onSelectStartDate(value?: CalendarDay): void;
  onSelectEndDate(value?: CalendarDay): void;
  onPressOption(option: EventOptionsAccount, text: AlertMessages): void;
  onSearch(startDate?: string, endDate?: string): void;
  onClearFilter(): void;
};

const AccountDetails: FC<Props> = ({
  account,
  isTransactionsLoading,
  listPaging,
  transactionsGroups,
  selectedEndDate,
  selectedStartDate,
  onPressAddBalance,
  onPressUpdateLimit,
  onPressAditionalInfo,
  onPressScheduling,
  onPressTransaction,
  onChangePage,
  onSelectEndDate,
  onSelectStartDate,
  onClearFilter,
  onSearch,
  onPressOption,
}) => {
  const { balance, product, type, status, paymentType, limit } = account;
  const isCanceled = status === ProductStatus.CANCELED;
  const isActive = status === ProductStatus.ACTIVE;
  const isInactive = status === ProductStatus.INACTIVE;
  const { INACTIVE, ACTIVE, CANCEL } = EventOptionsAccount;
  const {
    INACTIVE_ACCOUNT_MESSAGE,
    ACTIVE_ACCOUNT_MESSAGE,
    CANCEL_ACCOUNT_MESSAGE,
  } = AlertMessages;

  const generateSelectValuesFromInstallmentDates = useMemo(() => {
    return product?.installmentDates?.map((cycle) => {
      return {
        label: `${formatShortDate(new Date(cycle.startDate))} - ${formatShortDate(new Date(cycle.endDate))}`,
        value: [cycle.startDate, cycle.endDate],
      };
    });
  }, []);

  return (
    <Wrapper titlePage={product?.name}>
      <ContentBox>
        <OptionsList condition={!isCanceled}>
          <OptionButton
            id="btn_lock_account"
            label={isActive ? 'Inativar conta' : 'Ativar conta'}
            icon={isActive ? faLock : faUnlock}
            onPress={() =>
              onPressOption(
                isActive ? INACTIVE : ACTIVE,
                isActive ? INACTIVE_ACCOUNT_MESSAGE : ACTIVE_ACCOUNT_MESSAGE,
              )
            }
          />
          <OptionButton
            id="btn_cancel_account"
            label="Cancelar conta"
            icon={faXmark}
            onPress={() => onPressOption(CANCEL, CANCEL_ACCOUNT_MESSAGE)}
          />
          {paymentType !== AccountPaymentType.POSTPAID && (
            <OptionButton
              id="btn_add_balance"
              onPress={onPressAddBalance}
              icon={faMoneyBill}
              label="Adicionar Saldo"
              type="primary"
            />
          )}
          {paymentType === AccountPaymentType.POSTPAID && (
            <OptionButton
              id="btn_update_limit"
              onPress={onPressUpdateLimit}
              icon={faMoneyBill}
              label="Atualizar Limite"
              type="primary"
            />
          )}
        </OptionsList>
        <Title>Dados da Conta</Title>
        <TopBox>
          <CardsBox>
            {paymentType !== AccountPaymentType.POSTPAID && (
              <Card>
                <CardLabel>Saldo</CardLabel>
                <CardValue>{currencyParser(balance || '')}</CardValue>
              </Card>
            )}
            {paymentType === AccountPaymentType.POSTPAID && (
              <Card>
                <CardLabel>Limite</CardLabel>
                <CardValue>{currencyParser(limit || '')}</CardValue>
              </Card>
            )}
            <Card>
              <CardLabel>Tipo</CardLabel>
              <CardValue>{parseEnumToPt(type, AccountTypePt)}</CardValue>
            </Card>
            <Card>
              <CardLabel>Produto</CardLabel>
              <CardValue>{product?.name}</CardValue>
            </Card>
            <StatusCard success={isActive} danger={isCanceled || isInactive}>
              <StatusCardLabel
                success={isActive}
                danger={isCanceled || isInactive}
              >
                Status
              </StatusCardLabel>
              <StatusCardValue
                success={isActive}
                danger={isCanceled || isInactive}
              >
                {parseEnumToPt(status, ProductStatusPt)}
              </StatusCardValue>
            </StatusCard>
          </CardsBox>
          <CardsBox>
            <ButtonInfo
              id="btn_scheduling_info"
              onPress={onPressScheduling}
              tertiary
            >
              <IconPlus icon={faClock} />
              Agendamentos
            </ButtonInfo>
            <ButtonInfo id="btn_aditional_info" onPress={onPressAditionalInfo}>
              <IconPlus icon={faLayerPlus} />
              Informações Adicionais
            </ButtonInfo>
          </CardsBox>
        </TopBox>
        <Title>
          Transações{!selectedStartDate ? ' dos últimos 30 dias' : ''}
        </Title>
        <WrapperFilters>
          <DatePicker
            selectedEndDate={selectedEndDate}
            selectedStartDate={selectedStartDate}
            onClear={onClearFilter}
            onSearch={onSearch}
            onSelectEndDate={onSelectEndDate}
            onSelectStartDate={onSelectStartDate}
          />
          {paymentType === AccountPaymentType.POSTPAID && (
            <SelectInputComponent
              placeholder="Selecione a fatura"
              id="paymentType"
              name="paymentType"
              onChange={({ value }) => {
                onSelectStartDate(generateDateObject(value[0]));
                onSelectEndDate(generateDateObject(value[1]));
                onSearch(value[0], value[1]);
              }}
              value={formatPaymentTypeToSelectValue(
                selectedStartDate,
                selectedEndDate,
              )}
              options={generateSelectValuesFromInstallmentDates}
            />
          )}
        </WrapperFilters>

        <PaginatedList
          columns={transactionsColumns}
          list={transactionsGroups}
          currentPage={listPaging?.page}
          totalPages={listPaging?.totalPages}
          isLoading={isTransactionsLoading}
          renderPeriodGroup={({ date }) => date}
          hasGroupingByPeriod
          onChangePage={onChangePage}
          renderList={({ data }) =>
            data.map((transaction) => (
              <AccountTransactionRow
                key={transaction.id}
                date={transaction?.createdAt}
                transactionType={transaction?.transactionType}
                value={transaction?.amount}
                onPress={() => onPressTransaction(transaction?.id)}
              />
            ))
          }
          titleEmptyData={`  ${
            selectedStartDate
              ? 'Nenhuma transação encontrada'
              : 'Sem transações no período de 30 dias'
          }`}
          descriptionEmptyData={` ${
            selectedStartDate
              ? 'Esta conta  não possui nenhuma transação com a data selecionada'
              : 'Use o filtro de data para buscar por transações mais antigas'
          }`}
        />
      </ContentBox>
    </Wrapper>
  );
};

export default AccountDetails;

import { computed } from 'mobx';
import { ExportApi } from '~/api';
import { action, makeObservable, observable, persist } from '~/modules';
import { toApiDateWithZ } from '~/utils';

export default class ExportsStore {
  constructor() {
    makeObservable(this);
  }

  @observable
  exportsList: ListResponse<FileExport> = {};

  @persist('object')
  @observable
  currentExport: FileExport = {} as FileExport;

  @observable
  currentExportError: FileExportError | undefined;

  @computed get getCurrentFileMetadata(): FileExportMetaData[] {
    if (this.currentExportError?.recharge) {
      return this.currentExportError.recharge.metadata;
    }

    return [];
  }

  @computed get getCurrentFileExtraInfo(): FileExportErrorRecharge | null {
    if (this.currentExportError?.recharge) {
      return this.currentExportError.recharge;
    }

    return null;
  }

  @action
  getExports = async (values: GetExportsProps): Promise<void> => {
    this.exportsList = await ExportApi.getExports(values);
  };

  @action
  setcurrentExportError = (error: FileExportError): void => {
    this.currentExportError = error;
  };

  @action
  downloadExport = async (values: FileExport): Promise<any> => {
    return await ExportApi.downloadExport(values.id);
  };

  @action
  uploadFile = async (params: ExportFile): Promise<void> => {
    const payload = {
      ...params,
      startDate: toApiDateWithZ(params.startDate),
      endDate: toApiDateWithZ(params.endDate),
    };
    await ExportApi.uploadFile(payload);
  };
}

import { ProductStatus } from '~/utils';

export const productsStatusToApi = (status: ProductStatus | undefined) => {
  switch (status) {
    case ProductStatus.ACTIVE:
      return 'activate';
    case ProductStatus.CANCELED:
      return 'cancel';
    case ProductStatus.INACTIVE:
      return 'inactivate';
    default:
      return '';
  }
};

import { FC } from 'react';
import { FormikErrors } from 'formik';
import { isEmpty } from 'lodash';
import { useDropzone } from 'react-dropzone';
import If from '../If';

import { CustomLabel, DragWrapper, Error, Wrapper } from './styles';

type Props = {
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<UploadFile>>;
  error?:
    | string
    | false
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  label?: string;
};

const FileUpload: FC<Props> = ({ setFieldValue, error, label = '' }) => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setFieldValue('file', acceptedFiles[0]);
      },
    });

  return (
    <Wrapper>
      <If condition={!isEmpty(label)}>
        <CustomLabel>{label}</CustomLabel>
      </If>
      <DragWrapper
        isDragOver={isDragActive}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        <If condition={isDragActive}>
          <p>Solte aqui</p>
        </If>

        <If condition={!acceptedFiles[0] && !isDragActive}>
          <p>Arraste o arquivo aqui</p>
          ou
          <p>Clique para selecionar</p>
        </If>

        <If condition={!!acceptedFiles[0] && !isDragActive}>
          <p>{!!acceptedFiles[0] ? acceptedFiles[0].name : ''}</p>
        </If>
      </DragWrapper>
      <Error>{error ? (error as string) : ''}</Error>
    </Wrapper>
  );
};

export default FileUpload;

import { FormValidator } from '~/modules';

export const validationSchema = FormValidator.object().shape({
  accountId: FormValidator.string().required('Campo obrigatório'),
  amount: FormValidator.string().required('Campo obrigatório'),
  destinationId: FormValidator.string(),
});

export const initialValues: AddBalance = {
  accountId: '',
  amount: '',
  metadata: [{ key: '', value: '' }],
};

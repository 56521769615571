import { Icon, getTheme, ifStyle, pxToRem, styled } from '~/modules';
import LetteredAvatarComponent from '../LetteredAvatar';
import Touchable from '../Touchable';
import Typography from '../Typography';

const backgroundZ3 = getTheme('background.z3');
const backgroundZ2 = getTheme('background.z2');

const spacingXs = getTheme('spacing.xs');
const spacingLg = getTheme('spacing.lg');
const spacingMd = getTheme('spacing.md');
const textMain = getTheme('text.main');
const textLink = getTheme('text.link');
const borderRadiusMax = getTheme('borderRadius.max');

const isOpen = ifStyle('isOpen');
const isDisabled = ifStyle('disabled');

export const Wrapper = styled.div`
  height: 95px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${backgroundZ3};
`;

export const Container = styled.div`
  align-items: center;
`;

export const UserBox = styled.div`
  align-items: center;
  cursor: pointer;
`;

export const NavigationHistoryBox = styled.div`
  align-items: center;
  flex: 1;
`;

export const NavigationHistoryText = styled(Typography)`
  opacity: 0.5 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const TitlePage = styled(Typography).attrs({ variant: 'md' })`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-left: ${spacingXs}px;
  flex: 1;
  word-break: break-all;
`;

export const Name = styled(Typography).attrs({ variant: 'sm' })`
  font-weight: bold;
  color: ${textLink};
`;

export const IconBars = styled(Icon).attrs({ fontSize: pxToRem(28) })`
  color: ${textMain};
  opacity: 0.8;
  margin-right: ${spacingLg}px;
  cursor: pointer;
`;

type IconMagnifyingGlassProps = {
  disabled?: boolean;
};
export const IconMagnifyingGlass = styled(Icon).attrs({
  fontSize: pxToRem(24),
})<IconMagnifyingGlassProps>`
  color: ${textMain};
  opacity: ${isDisabled(0.3, 0.8)};
  pointer-events: ${isDisabled('none')};
  margin-left: ${spacingLg}px;
  cursor: ${isDisabled('not-allowed', 'pointer')};
`;

type IconChevronProps = {
  $isOpen: boolean;
};
export const IconChevron = styled(Icon).attrs({
  fontSize: pxToRem(20),
})<IconChevronProps>`
  color: ${textLink};
  margin-right: ${spacingMd}px;
  width: ${pxToRem(20)};
  transform: ${isOpen('rotate(90deg)')};
  -webkit-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
`;

export const LetteredAvatar = styled(LetteredAvatarComponent)`
  min-width: ${pxToRem(60)};
  min-height: ${pxToRem(60)};
  width: ${pxToRem(60)};
  height: ${pxToRem(60)};
  margin: 0 ${spacingLg}px;
  cursor: pointer;
`;

export const IconButton = styled(Touchable)`
  width: ${pxToRem(50)};
  height: ${pxToRem(50)};
  border-radius: ${borderRadiusMax}px;
  background-color: ${backgroundZ2};
  justify-content: center;
  align-items: center;
  margin-right: ${spacingMd}px;
`;

export const IconArrow = styled(Icon)`
  color: ${textMain};
  font-size: ${pxToRem(24)};
  opacity: 0.8 !important;
`;

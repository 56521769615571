import { Icon, Modal, getTheme, pxToRem, styled } from '~/modules';
import TextInput from '../TextInput';
import Typography from '../Typography';

const frostedGlassZ2 = getTheme('frosted glass.z2');
const textMain = getTheme('text.main');
const brandPrimaryMain = getTheme('brand.primary.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');

const spacingMd = getTheme('spacing.md');

const themeRadiusCard = getTheme('themeRadius.card');
const borderWidthMin = getTheme('borderWidth.min');

const boxShadowZ4 = getTheme('boxShadow.z4');

export const Wrapper = styled(Modal).attrs({
  style: {
    overlay: {
      flex: 1,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
      backgroundColor: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
})`
  display: flex;
  flex-direction: column;
  width: max-content;
  height: max-content;
  max-width: 90%;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  backdrop-filter: blur(20px);
  background-color: ${frostedGlassZ2};
  border-radius: ${themeRadiusCard}px;
  border: ${borderWidthMin}px solid ${textMain}20;
  padding: ${spacingMd}px;
  ${boxShadowZ4};
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
`;

export const Input = styled(TextInput)``;

export const Row = styled.div`
  align-items: center;
  margin-top: ${spacingMd}px;
`;

export const Rounded = styled.div`
  min-width: ${pxToRem(60)};
  min-height: ${pxToRem(60)};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: ${brandPrimaryMain};
  margin-right: ${spacingMd}px;
`;

export const IconComponent = styled(Icon).attrs({
  fontSize: pxToRem(20),
})`
  color: ${brandPrimaryContrast};
`;

export const WrapperText = styled.div`
  flex-direction: column;
`;

export const Title = styled(Typography)`
  font-weight: bold;
`;

export const Description = styled(Typography)`
  line-height: 140%;
`;

import { FormValidator, isValidCpfCnpj } from '~/modules';

export const validationSchema = FormValidator.object().shape({
  document: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Documento inválido', isValidCpfCnpj),
});

export const getInitialValue = (document: string) => {
  return { document };
};

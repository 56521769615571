import { FC, useState } from 'react';
import { faPlus, faXmark } from '~/assets';
import { FooterButtons } from '~/components';
import { FormikProps, isEmpty, observer, useFormikContext } from '~/modules';
import { ClientsStore } from '~/stores';
import { PersonType, StepsSideAccount, maskCpfOrCnpj } from '~/utils';
import ModalAccounts from './ModalAccounts';
import {
  Content,
  Error,
  IconComponent,
  Input,
  Label,
  ProductInput,
  TitlePageComponent,
  Wrapper,
  WrapperProduct,
} from './styles';

type Props = {
  clientStore: ClientsStore;
  nameProduct: string;
  setNameProduct(name: string): void;
  onNavigateId(id: number): void;
};

const DataAccount: FC<Props> = ({
  clientStore,
  nameProduct,
  onNavigateId,
  setNameProduct,
}) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
    setFieldValue,
  }: FormikProps<AddBalance> = useFormikContext();

  const [accountName, setAccountName] = useState(nameProduct);
  const [onModalAccounts, setOnModalAccounts] = useState(false);

  const handleOnModalAccounts = () =>
    setOnModalAccounts((onModalAccounts) => !onModalAccounts);

  const handleSelectAccount = async (account: Account) => {
    setAccountName(account?.person?.fullName || '');
    setFieldValue('accountId', account?.id);
    setFieldValue('destinationId', clientStore.selectedAccount?.id);
    setNameProduct(account?.product?.name || '');
    handleOnModalAccounts();
  };

  const handleClear = async () => {
    resetForm();
    setAccountName('');
    setFieldValue('productId', '');
    setNameProduct('');
  };

  const isClientPF = clientStore.currentClient?.personType === PersonType.PF;

  return (
    <Wrapper>
      <Content>
        <TitlePageComponent>Insira os dados</TitlePageComponent>
        <WrapperProduct>
          <Label>Conta</Label>
          <ProductInput onClick={handleOnModalAccounts}>
            <IconComponent icon={isEmpty(accountName) ? faPlus : faXmark} />
            {isEmpty(accountName) ? 'Selecionar Conta' : accountName}
          </ProductInput>
          <Error>{touched.accountId && errors?.accountId}</Error>
        </WrapperProduct>
        <Input
          label={isClientPF ? 'CPF' : 'CNPJ'}
          placeholder={isClientPF ? '000.000.000-00' : '00.000.000/0000-00'}
          id="document"
          name="document"
          disabled
          value={maskCpfOrCnpj(clientStore.currentClient?.document)}
          onChange={handleChange}
        />
        <Input
          label="Valor"
          placeholder="R$ 123,45"
          id="amount"
          name="amount"
          value={values?.amount}
          error={touched?.amount && errors?.amount}
          onChange={handleChange}
          maskType="currency"
        />
      </Content>
      <FooterButtons
        firstBtn={{
          id: 'btn_clear',
          label: 'Limpar',
          onPress: () => handleClear(),
        }}
        secondaryBtn={{
          id: 'btn_confirm',
          label: 'Próximo',
          onPress: () => onNavigateId(StepsSideAccount.META_DATA),
        }}
      />
      <ModalAccounts
        isOpen={onModalAccounts}
        handleClose={handleOnModalAccounts}
        onSelectAccount={(account) => handleSelectAccount(account)}
      />
    </Wrapper>
  );
};

export default observer(DataAccount);

import { getTheme, styled } from '~/modules';
import { mqDevices } from '~/utils';
import Typography from '../Typography';

const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');

export const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media ${mqDevices.inMobileAndTablet} {
    height: 100%;
    justify-content: center;
  }
`;
export const WrapperLetter = styled.div`
  p {
    font-size: 24px;
  }
`;

export const Name = styled(Typography).attrs({ variant: 'md' })`
  width: 90%;
  font-weight: bold;
  text-align: center;
  margin-top: ${spacingMd}px;
  margin-bottom: ${spacingLg}px;
`;

export const UserName = styled(Typography).attrs({ variant: 'xs' })`
  width: 90%;
  text-align: center;
  margin-bottom: ${spacingLg}px;
`;

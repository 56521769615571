import { FC, useMemo, useState } from 'react';
import { FormBehavior } from '~/modules';
import { AlertMessages, showAlert, useStores } from '~/utils';
import ModalConfirmation from '../ModalConfirmation';
import ModalEditUser from './ModalEditUser';
import { getInitialValues, validationSchema } from './schema';

type Props = {
  isOpen: boolean;
  handleClose(): void;
  handleFormSubmit(values: RegisterUser): void;
};

const ModalEditUserContainer: FC<Props> = ({
  isOpen,
  handleClose,
  handleFormSubmit,
}) => {
  const {
    user: { user, logout },
    users: { userSelected, resetPassword },
  } = useStores();

  const [onConfirmation, setOnConfirmation] = useState(false);

  const handleOnConfirmation = () =>
    setOnConfirmation((onConfirmation) => !onConfirmation);

  const handleResetPassword = async () => {
    await resetPassword(userSelected.id);
    showAlert({
      message: AlertMessages.SUCCESS_RESET_PASSWORD,
      type: 'success',
    });
    handleOnConfirmation();
    userSelected.id === user.id && (await logout());
  };

  const initialValues: EditUser = useMemo(() => {
    return getInitialValues(userSelected);
  }, [userSelected]);

  return (
    <>
      <FormBehavior
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        enableReinitialize
      >
        <ModalEditUser
          isOpen={isOpen}
          userSelected={userSelected}
          handleClose={handleClose}
          onResetPassword={handleOnConfirmation}
        />
      </FormBehavior>
      <ModalConfirmation
        description="O usuário receberá um email com a nova senha"
        isOpen={onConfirmation}
        onCancel={handleOnConfirmation}
        onConfirm={handleResetPassword}
      />
    </>
  );
};
export default ModalEditUserContainer;

import { getTheme, pxToRem, styled } from '~/modules';
import Button from '../Button';
import TextInput from '../TextInput';

const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');

export const Wrapper = styled.div`
  align-items: flex-end;
  margin-top: -${spacingMd}px;
  padding-bottom: ${spacingLg}px;
`;

export const Input = styled(TextInput)`
  width: ${pxToRem(390)};
  margin-right: ${spacingMd}px;
`;

export const ButtonFilter = styled(Button)`
  width: ${pxToRem(250)};
`;

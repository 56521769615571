import { FC, useEffect, useState } from 'react';
import { faXmark } from '~/assets';
import { Icon } from '~/modules';
import { ButtonClose, InputItem, WrapperItem } from './styles';

type Props = {
  metadata: MetaData;
  onChange(value: MetaData): void;
  onRemove(id?: number): void;
};

const Metadata: FC<Props> = ({ metadata, onChange, onRemove }) => {
  const [key, setKey] = useState(metadata.key);
  const [value, setValue] = useState(metadata.value);

  const handleRemoveItem = () => {
    onRemove(metadata.id);
  };

  useEffect(() => {
    onChange({ key, value, id: metadata.id });
  }, [key, value]);

  return (
    <WrapperItem>
      <InputItem
        id="key"
        name="key"
        placeholder="Insira a descrição da chave"
        value={key}
        onChange={({ target: { value } }) => setKey(value)}
        autoFocus
      />
      <InputItem
        id="value"
        name="value"
        placeholder="Insira o valor"
        value={value}
        onChange={({ target: { value } }) => setValue(value)}
      />
      <ButtonClose id="btn_remove_item" onPress={handleRemoveItem} transparent>
        <Icon icon={faXmark} />
      </ButtonClose>
    </WrapperItem>
  );
};

export default Metadata;

import { FC } from 'react';
import { observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import ActivateUserError from './UserActivationError';

export const UserActivationErrorContainer: FC = () => {
  const navigate = useNavigate();
  const handleNavigate = (route: Routes) => {
    navigate(route);
  };

  return <ActivateUserError onNavigate={handleNavigate} />;
};

export default observer(UserActivationErrorContainer);

import { FC } from 'react';
import { FormBehavior } from '~/modules';
import ModalRegisterUser from './ModalRegisterUser';
import { initialValues, validationSchema } from './schema';

type Props = {
  isOpen: boolean;
  handleClose(): void;
  handleFormSubmit(values: RegisterUser): void;
};

const ModalRegisterUserContainer: FC<Props> = ({
  isOpen,
  handleClose,
  handleFormSubmit,
}) => (
  <FormBehavior
    validationSchema={validationSchema}
    initialValues={initialValues}
    onSubmit={handleFormSubmit}
  >
    <ModalRegisterUser isOpen={isOpen} handleClose={handleClose} />
  </FormBehavior>
);

export default ModalRegisterUserContainer;

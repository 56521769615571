import { FormValidator, isValidCnpj } from '~/modules';
import {
  States,
  isValidDate,
  validateMaxLength,
  validateMinLength,
} from '~/utils';

const isActiveStateRegistration = (value?: string): boolean => {
  if (!value) return true;
  const pattern = /^\d{2}\.\d{3}\.\d{3}-\d{1}$/;
  return pattern.test(value);
};

const isPhone = (value?: string): boolean => {
  if (!value) return true;
  return value.length === 15 || value.length === 14;
};

const isWebsite = (value?: string): boolean => {
  if (!value) return true;
  const match = value.match(
    /^www\.[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
  );
  return match != null;
};

const isState = (value?: string): boolean => {
  return !!States.find((state) => state === value);
};

const isEmail = (value?: string): boolean => {
  if (!value) return false;
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!re.test(value)) {
    return false;
  }
  const parts = value.split('@');
  const [localPart, domain] = parts;
  if (
    /\.{2}/.test(localPart) ||
    /\.$/.test(localPart) ||
    /^\./.test(localPart) ||
    /[^a-zA-Z0-9._%-]/.test(localPart)
  ) {
    return false;
  }
  return !(
    /^\./.test(domain) ||
    /\.$/.test(domain) ||
    !/^[a-zA-Z0-9.-]+$/.test(domain) ||
    !/\.[a-zA-Z]{2,}$/.test(domain)
  );
};

export const validationSchema = FormValidator.object().shape({
  document: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'CNPJ inválido', isValidCnpj),

  legalName: FormValidator.string()
    .required('Campo obrigatório')
    .test(
      'valid',
      'Tamanho inválido, superior a 254 caracteres',
      validateMaxLength,
    )
    .test(
      'valid',
      'Tamanho inválido, inferior a 3 caracteres',
      validateMinLength,
    ),

  tradingName: FormValidator.string()
    .required('Campo obrigatório')
    .test(
      'valid',
      'Tamanho inválido, superior a 254 caracteres',
      validateMaxLength,
    )
    .test(
      'valid',
      'Tamanho inválido, inferior a 3 caracteres',
      validateMinLength,
    ),

  foundingDate: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Data inválida', isValidDate),

  stateRegistration: FormValidator.string().test(
    'valid',
    'Inscrição estadual inválida',
    isActiveStateRegistration,
  ),

  mail: FormValidator.string()
    .test('valid', 'E-mail inválido', isEmail)
    .required('Campo obrigatório'),

  phone: FormValidator.string()
    .test('valid', 'Telefone inválido', isPhone)
    .required('Campo obrigatório'),

  website: FormValidator.string().test('valid', 'Website inválido', isWebsite),

  businessType: FormValidator.string().required('Campo obrigatório'),

  address: FormValidator.object().shape({
    postalCode: FormValidator.string().required('Campo obrigatório'),
    street: FormValidator.string().required('Campo obrigatório'),
    number: FormValidator.number().required('Campo obrigatório'),
    neighborhood: FormValidator.string().required('Campo obrigatório'),
    city: FormValidator.string().required('Campo obrigatório'),
    state: FormValidator.string()
      .required('Campo obrigatório')
      .test('valid', 'Estado inválido', isState),
  }),
});

export const initialValues: CompanyRegister = {
  document: '',
  mail: '',
  phone: '',
  legalName: '',
  tradingName: '',
  foundingDate: '',
  stateRegistration: '',
  mainActivity: '',
  website: '',
  businessType: '',
  internalPersonId: '',
  address: {
    city: '',
    neighborhood: '',
    number: '',
    state: '',
    street: '',
    complement: '',
    postalCode: '',
  },
};

import { ReportHandler } from 'web-vitals';

const reportWebVitals = (onPerfEntry?: ReportHandler): any => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    (async () => {
      const webVitals = await import('web-vitals');
      webVitals.getCLS(onPerfEntry);
      webVitals.getFID(onPerfEntry);
      webVitals.getFCP(onPerfEntry);
      webVitals.getLCP(onPerfEntry);
      webVitals.getTTFB(onPerfEntry);
    })();
  }
};

export default reportWebVitals;

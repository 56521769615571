import { ExportTypes } from '../enums';

export const RolesValueExports: SelectProps[] = [
  { label: 'Saldo Rescisão Diário', value: ExportTypes.INSTALLMENTS_BY_DATE },
  {
    label: 'Saldo Mensal Consolidado',
    value: ExportTypes.INSTALLMENTS_BY_PERIOD,
  },
];

export const controlExportColumnsRecharge = [
  'Código de erro',
  'Mensagem',
  'Informações adicionais',
];

export const controlExportColumnsIndividual = [
  'Código de erro',
  'Mensagem',
  '',
];

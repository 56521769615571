import { FC } from 'react';
import { IconDefinition } from '~/assets';
import { IconStyled, Label, LabelBox, Value, WrapperItem } from './styles';

type Props = {
  icon: IconDefinition;
  label: string;
  value?: string;
};

const ItemBox: FC<Props> = ({ icon, label, value }) => {
  return (
    <WrapperItem>
      <IconStyled icon={icon} />
      <LabelBox>
        <Label>{label}</Label>
        <Value>{value || '-'}</Value>
      </LabelBox>
    </WrapperItem>
  );
};

export default ItemBox;

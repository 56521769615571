export enum ExportTypes {
  INSTALLMENTS_BY_DATE = 'INSTALLMENTS_BY_DATE',
  INSTALLMENTS_FUTURE = 'INSTALLMENTS_FUTURE',
  INSTALLMENTS_BY_PERIOD = 'INSTALLMENTS_BY_PERIOD',
  IMPORT_ERROR = 'IMPORT_ERROR',
}
export enum ExportTypesPt {
  INSTALLMENTS_BY_DATE = 'Saldo Rescisão Diário',
  INSTALLMENTS_FUTURE = 'Saldo Rescisão Desligamento',
  INSTALLMENTS_BY_PERIOD = 'Saldo Mensal Consolidado',
  IMPORT_ERROR = 'Erros de Importação',
}

export enum ExportStatus {
  PROCESSING = 'PROCESSING',
  PROCESSED_SUCCESS = 'PROCESSED_SUCCESS',
  PROCESSED_ERROR = 'PROCESSED_ERROR',
}

export enum ExportStatusPt {
  PROCESSING = 'Processando',
  PROCESSED_SUCCESS = 'Sucesso',
  PROCESSED_ERROR = 'Erro',
}

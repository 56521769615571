import { FC, useEffect, useState } from 'react';
import {
  faCalendar,
  faEnvelope,
  faFileContract,
  faGlobe,
  faIdBadge,
  faIdCard,
  faLocationPin,
  faLocationPlus,
  faMobile,
  faPersonDress,
  faUser,
  faVenusMars,
} from '~/assets';
import { isEmpty } from '~/modules';
import {
  GenderPt,
  ModalClientSections,
  PersonType,
  formatAddress,
  formatPhone,
  formatShortDateFromString,
  getActiveAddress,
  maskCpfOrCnpj,
  parseEnumToPt,
} from '~/utils';
import EmptyData from '../EmptyData';
import HeaderModal from '../HeaderModal';
import If from '../If';
import Item from './Item';
import {
  Card,
  Column,
  Content,
  Description,
  MetadataHeader,
  MetadataValueWrapper,
  MetadataWrapper,
  SectionItem,
  SectionsBar,
  Title,
  Value,
  Wrapper,
  WrapperSections,
} from './styles';

type Props = {
  dataClient: Client;
  isOpen: boolean;
  handleClose(): void;
  initialOpenedSection?: ModalClientSections;
};

const ModalClientDetails: FC<Props> = ({
  dataClient,
  isOpen,
  handleClose,
  initialOpenedSection = ModalClientSections.PERSONAL_DATA,
}) => {
  const { mail, phone, addresses, document, individual, company } = dataClient;
  const activeAddress = getActiveAddress(addresses);
  const [activeSection, setActiveSection] =
    useState<ModalClientSections>(initialOpenedSection);

  useEffect(() => {
    if (isOpen) {
      setActiveSection(initialOpenedSection);
    }
  }, [isOpen, initialOpenedSection]);

  return (
    <Wrapper isOpen={isOpen} onClose={handleClose}>
      <HeaderModal title="Dados do cliente" onPressClose={handleClose} />
      <WrapperSections>
        <SectionsBar>
          <SectionItem
            active={activeSection === ModalClientSections.PERSONAL_DATA}
            onClick={() => setActiveSection(ModalClientSections.PERSONAL_DATA)}
          >
            <Value>Dados Pessoais</Value>
          </SectionItem>
          <SectionItem
            active={activeSection === ModalClientSections.META_DATA}
            onClick={() => setActiveSection(ModalClientSections.META_DATA)}
          >
            <Value>Metadata</Value>
          </SectionItem>
        </SectionsBar>
      </WrapperSections>
      <Content>
        <If condition={activeSection === ModalClientSections.PERSONAL_DATA}>
          <Column>
            <Item label="TELEFONE" icon={faMobile} value={formatPhone(phone)} />
            <Item label="EMAIL" icon={faEnvelope} value={mail} />
            <Item
              label="ENDEREÇO"
              icon={faLocationPin}
              value={formatAddress(activeAddress)}
            />
            <Item
              label="COMPLEMENTO DO ENDEREÇO"
              icon={faLocationPlus}
              value={activeAddress?.complement}
            />
            <If condition={dataClient?.personType === PersonType.PJ}>
              <Item icon={faGlobe} label="WEBSITE" value={company?.website} />
            </If>
          </Column>
          <Column>
            <If condition={dataClient?.personType === PersonType.PF}>
              <Item
                label="DOCUMENTO"
                icon={faIdCard}
                value={maskCpfOrCnpj(document)}
              />
              <Item
                label="NOME DA MÃE"
                icon={faPersonDress}
                value={individual?.motherName}
              />
              <Item
                label="GÊNERO"
                icon={faVenusMars}
                value={parseEnumToPt(individual?.gender, GenderPt)}
              />
              <Item
                label="DATA DE NASCIMENTO"
                icon={faCalendar}
                value={formatShortDateFromString(individual?.birthDate)}
              />
            </If>
            <If condition={dataClient?.personType === PersonType.PJ}>
              <Item
                label="CNPJ"
                icon={faIdCard}
                value={maskCpfOrCnpj(document)}
              />
              <Item
                label="RAZÃO SOCIAL"
                icon={faUser}
                value={company?.legalName}
              />
              <Item
                label="NOME FANTASIA"
                icon={faIdBadge}
                value={company?.tradingName}
              />
              <Item
                label="TIPO DA EMPRESA"
                icon={faFileContract}
                value={company?.businessType?.toUpperCase()}
              />
              <Item
                label="INSCRIÇÃO ESTADUAL"
                icon={faFileContract}
                value={company?.stateRegistration}
              />
              <Item
                icon={faIdBadge}
                label="ATIVIDADE PRINCIPAL"
                value={company?.mainActivity}
              />
              <Item
                icon={faCalendar}
                label="DATA DE FUNDAÇÃO"
                value={formatShortDateFromString(company?.foundingDate ?? '')}
              />
            </If>
          </Column>
        </If>
        <If condition={activeSection === ModalClientSections.META_DATA}>
          <If condition={!isEmpty(individual?.metadata)}>
            <MetadataWrapper>
              <MetadataHeader>
                <Title>Descrição</Title>
                <Title>Valor</Title>
              </MetadataHeader>
              {individual?.metadata?.map(({ key, value }, index) => {
                return (
                  <Card key={index}>
                    <Description>{key}</Description>
                    <MetadataValueWrapper>
                      <Value>{value}</Value>
                    </MetadataValueWrapper>
                  </Card>
                );
              })}
            </MetadataWrapper>
          </If>
          <If condition={isEmpty(individual?.metadata)}>
            <EmptyData title="Sem metadados cadastrados" description="" />
          </If>
        </If>
      </Content>
    </Wrapper>
  );
};

export default ModalClientDetails;

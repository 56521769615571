import { FC, useState } from 'react';
import { FooterButtons, ModalConfirmation } from '~/components';
import { FormikProps, cep, isEmpty, useFormikContext } from '~/modules';
import { Masks, StepsClientRegister } from '~/utils';
import { Props } from '..';
import {
  Content,
  Input,
  InputMedium,
  InputSmall,
  TitlePageComponent,
  WrapperContent,
  WrapperInput,
} from '../styles';

const Address: FC<Props> = ({ onNavigateId }) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleChange,
    submitForm,
    validateForm,
    setFieldValue,
  }: FormikProps<ClientRegister> = useFormikContext();

  const [onConfirmation, setOnConfirmation] = useState(false);

  const handleOnConfirmation = () =>
    setOnConfirmation((onConfirmation) => !onConfirmation);

  const handleGetAddress = async () => {
    const address = await cep(values.address.postalCode);
    setFieldValue('address.street', address.street);
    setFieldValue('address.neighborhood', address.neighborhood);
    setFieldValue('address.city', address.city);
    setFieldValue('address.state', address.state);
  };

  const handleFormValidate = async () => {
    const errors = await validateForm(values);

    if (isEmpty(errors)) {
      handleOnConfirmation();
    } else {
      await submitForm();
      if (
        errors.firstName ||
        errors.lastName ||
        errors.document ||
        errors.birthDate ||
        errors.mail ||
        errors.phone ||
        errors.motherName ||
        errors.gender ||
        errors.internalPersonId
      ) {
        onNavigateId(StepsClientRegister.DATA);
      }
    }
  };

  const handleSubmitForm = async () => {
    await submitForm();
    handleOnConfirmation();
  };

  return (
    <WrapperContent>
      <Content>
        <TitlePageComponent>Endereço do Cliente</TitlePageComponent>
        <Input
          label="CEP"
          placeholder="00000-000"
          id="address.postalCode"
          name="address.postalCode"
          error={touched.address?.postalCode && errors.address?.postalCode}
          value={values.address?.postalCode}
          mask={Masks.cep}
          onBlur={handleGetAddress}
          onChange={handleChange}
        />
        <WrapperInput>
          <InputMedium
            label="Rua"
            placeholder="Informe o nome da rua"
            id="address.street"
            name="address.street"
            error={touched.address?.street && errors.address?.street}
            value={values.address?.street}
            onChange={handleChange}
          />
          <InputSmall
            label="Número"
            placeholder="0000"
            id="address.number"
            name="address.number"
            type="number"
            error={touched.address?.number && errors.address?.number}
            value={values.address?.number}
            onChange={({ target: { name, value } }) =>
              setFieldValue(name, value.toString())
            }
          />
        </WrapperInput>
        <Input
          label="Bairro"
          placeholder="Informe o bairro"
          id="address.neighborhood"
          name="address.neighborhood"
          error={touched.address?.neighborhood && errors.address?.neighborhood}
          value={values.address?.neighborhood}
          onChange={handleChange}
        />
        <WrapperInput>
          <InputMedium
            label="Cidade"
            placeholder="Informe a cidade"
            id="address.city"
            name="address.city"
            error={touched.address?.city && errors.address?.city}
            value={values.address?.city}
            onChange={handleChange}
          />
          <InputSmall
            label="Estado"
            placeholder="SP"
            id="address.state"
            name="address.state"
            error={touched.address?.state && errors.address?.state}
            value={values.address?.state}
            mask={Masks.state}
            onChange={({ target: { value } }) =>
              setFieldValue('address.state', value.toUpperCase())
            }
          />
        </WrapperInput>
        <Input
          label="Complemento"
          placeholder="Edificio Azul"
          id="address.complement"
          name="address.complement"
          error={touched.address?.complement && errors.address?.complement}
          value={values.address?.complement}
          onChange={handleChange}
        />
      </Content>
      <FooterButtons
        firstBtn={{
          id: 'btn_clear',
          label: 'Voltar',
          disabled: isSubmitting,
          onPress: () => onNavigateId(StepsClientRegister.ADDRESS - 1),
        }}
        secondaryBtn={{
          id: 'btn_confirm',
          label: 'Finalizar',
          onPress: () => handleFormValidate(),
        }}
      />
      <ModalConfirmation
        isOpen={onConfirmation}
        description={`Deseja realizar o cadastro do cliente ${values.firstName + ' ' + values.lastName}?`}
        onCancel={handleOnConfirmation}
        onConfirm={handleSubmitForm}
      />
    </WrapperContent>
  );
};

export default Address;

import { FC, useEffect } from 'react';
import { faPlus } from '~/assets';
import { FormikProps, useFormikContext } from '~/modules';
import { RolesValueFiles } from '~/utils';
import FileUpload from '../FileUpload';
import HeaderModal from '../HeaderModal';
import Select from '../Select';
import { ButtonUploadFile, Content, IconPlus, Wrapper } from './styles';

type Props = {
  isOpen: boolean;
  handleClose(): void;
};

const ModalUploadFile: FC<Props> = ({ isOpen, handleClose }) => {
  const {
    values,
    errors,
    touched,
    submitForm,
    setFieldValue,
    resetForm,
  }: FormikProps<UploadFile> = useFormikContext();

  useEffect(() => {
    !isOpen && resetForm();
  }, [isOpen]);

  return (
    <Wrapper isOpen={isOpen}>
      <HeaderModal title="Carregar Arquivo" onPressClose={handleClose} />
      <Content>
        <FileUpload
          label="Arquivo"
          setFieldValue={setFieldValue}
          error={touched?.file && errors?.file}
        />
        <Select
          label="Tipo"
          placeholder="Selecione o tipo do arquivo"
          id="type"
          name="type"
          error={touched?.type && errors?.type}
          onChange={({ name, value }) => setFieldValue(name, value)}
          value={values.type}
          options={RolesValueFiles}
        />
        <ButtonUploadFile
          id="btn_register_user"
          onPress={submitForm}
          onKeyEnter
        >
          <IconPlus icon={faPlus} />
          Carregar
        </ButtonUploadFile>
      </Content>
    </Wrapper>
  );
};

export default ModalUploadFile;

import { FC, useState } from 'react';
import {
  faArrowLeft,
  faArrowRight,
  faBars,
  faChevronRight,
  faMagnifyingGlass,
} from '~/assets';
import { Routes } from '~/routes';
import { RoutesHistory } from '~/routes/routing';
import { getIsRoute } from '~/utils';

import PopUpHeader from '../PopUpHeader';
import {
  Container,
  IconArrow,
  IconBars,
  IconButton,
  IconChevron,
  IconMagnifyingGlass,
  LetteredAvatar,
  Name,
  NavigationHistoryBox,
  NavigationHistoryText,
  TitlePage,
  UserBox,
  Wrapper,
} from './styles';

type Props = {
  titlePage: string;
  nameUser: string;
  onPressFocusMenu(): void;
  onPressLogout(): Promise<void> | void;
  onPressMagnifyingGlass(): void;
};

const Header: FC<Props> = ({
  titlePage,
  nameUser,
  onPressFocusMenu,
  onPressLogout,
  onPressMagnifyingGlass,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handlePopup = () => setIsPopupOpen((onPopup) => !onPopup);

  const activeRoute = window.location.pathname as Routes;

  const navigationHistory = RoutesHistory[activeRoute];

  const onPressBack = () => {
    window.history.back();
  };

  const onPressForward = () => {
    window.history.forward();
  };

  return (
    <Wrapper>
      <Container>
        <IconBars icon={faBars} onClick={onPressFocusMenu} />
        <IconButton id="btn_back" onPress={onPressBack}>
          <IconArrow icon={faArrowLeft} />
        </IconButton>
        <IconButton id="btn_forward" onPress={onPressForward}>
          <IconArrow icon={faArrowRight} />
        </IconButton>
        <NavigationHistoryBox>
          <NavigationHistoryText>{navigationHistory}</NavigationHistoryText>
          <TitlePage>{titlePage}</TitlePage>
        </NavigationHistoryBox>
      </Container>
      <Container>
        <IconMagnifyingGlass
          icon={faMagnifyingGlass}
          onClick={onPressMagnifyingGlass}
          disabled={getIsRoute(Routes.CLIENT_SEARCH)}
        />
        <UserBox id="user_box" onClick={handlePopup}>
          <LetteredAvatar name={nameUser} tertiary />
          <IconChevron $isOpen={isPopupOpen} icon={faChevronRight} />
          <Name>{nameUser}</Name>
        </UserBox>
      </Container>
      <PopUpHeader
        isOpen={isPopupOpen}
        onClose={handlePopup}
        onLogout={onPressLogout}
      />
    </Wrapper>
  );
};

export default Header;

import { FC } from 'react';

const Icon: FC<IconProps> = ({ className }) => (
  <svg
    width="246"
    height="50"
    viewBox="0 0 246 50"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1435 49.1217V32.3842H23.9851C28.345 32.3842 31.8794 28.8578 31.8794 24.5077V17.6158H35.8266C40.1866 17.6158 43.721 21.1422 43.721 25.4923V41.2452C43.721 45.5952 40.1866 49.1217 35.8266 49.1217H12.1435Z"
      fill="white"
    />
    <path
      d="M23.9851 0.878342H0.302002V32.3842H12.1435V17.6158H31.8794V8.7548C31.8794 4.40475 28.345 0.878342 23.9851 0.878342Z"
      fill="#00FFAE"
    />
    <path d="M100.858 40.679V25.2508H107.004V40.679H100.858Z" fill="white" />
    <path
      d="M107.004 21.9189C107.004 22.9181 106.194 23.7281 105.195 23.7281H100.858V17.7248H105.195C106.194 17.7248 107.004 18.5348 107.004 19.5339V21.9189Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.6791 18.352C75.0379 18.352 76.2191 18.6133 77.2226 19.136C78.2469 19.6377 79.0413 20.338 79.6058 21.237C80.1702 22.115 80.4525 23.1289 80.4525 24.2787C80.4525 25.8675 79.888 27.1637 78.7591 28.1671C78.5028 28.3949 78.2196 28.6006 77.9094 28.7843C78.3769 29.0365 78.7961 29.3325 79.1668 29.6723C80.4211 30.8221 81.0483 32.2959 81.0483 34.0938C81.0483 35.3899 80.7242 36.5397 80.0762 37.5432C79.4281 38.5258 78.5187 39.2993 77.348 39.8637C76.1982 40.4073 74.8498 40.679 73.3028 40.679H63.0173V18.352H73.6791ZM72.2053 36.1635H69.1635V31.3343H72.2053C72.7488 31.3343 73.2087 31.4284 73.585 31.6165C73.9822 31.8047 74.2854 32.0869 74.4944 32.4632C74.7244 32.8186 74.8394 33.2471 74.8394 33.7489C74.8394 34.5015 74.5989 35.0973 74.1181 35.5363C73.6373 35.9544 72.9997 36.1635 72.2053 36.1635ZM69.1635 27.0069H71.9231C72.592 27.0069 73.146 26.8396 73.585 26.5051C74.0241 26.1706 74.2435 25.648 74.2435 24.9372C74.2435 24.2264 74.0241 23.7038 73.585 23.3693C73.146 23.0348 72.592 22.8676 71.9231 22.8676H69.1635V27.0069Z"
      fill="white"
    />
    <path
      d="M86.6357 40.1773C87.8064 40.7627 89.1443 41.0553 90.6495 41.0553C92.1547 41.0553 93.4822 40.7627 94.632 40.1773C95.8027 39.5919 96.7121 38.7871 97.3601 37.7627C98.0291 36.7174 98.3636 35.5154 98.3636 34.1565V25.2508H92.2174V34.2192C92.2174 34.7001 92.0606 35.0973 91.747 35.4108C91.4544 35.7035 91.0885 35.8499 90.6495 35.8499C90.3359 35.8499 90.0537 35.7871 89.8028 35.6617C89.5729 35.5154 89.3847 35.3168 89.2384 35.0659C89.1129 34.8151 89.0502 34.5328 89.0502 34.2192V25.2508H82.904V34.1565C82.904 35.4945 83.2385 36.6861 83.9075 37.7314C84.5765 38.7766 85.4858 39.5919 86.6357 40.1773Z"
      fill="white"
    />
    <path d="M109.923 17.7248V40.679H116.069V17.7248H109.923Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.698 40.9926C124.172 40.9926 122.834 40.6477 121.684 39.9578C120.555 39.2679 119.666 38.3272 119.018 37.1356C118.391 35.923 118.078 34.5328 118.078 32.9649C118.078 31.397 118.391 30.0068 119.018 28.7943C119.666 27.5818 120.555 26.641 121.684 25.9721C122.834 25.2822 124.172 24.9372 125.698 24.9372C126.617 24.9372 127.485 25.094 128.3 25.4076C128.564 25.5024 128.815 25.6103 129.053 25.7314V17.7248H135.199V40.679H129.21V39.9833C128.926 40.1601 128.623 40.3188 128.3 40.4595C127.485 40.8149 126.617 40.9926 125.698 40.9926ZM126.827 35.599C127.349 35.599 127.799 35.4945 128.175 35.2854C128.551 35.0555 128.844 34.7419 129.053 34.3447C129.283 33.9475 129.398 33.4875 129.398 32.9649C129.398 32.4423 129.293 31.9928 129.084 31.6165C128.875 31.2193 128.572 30.9057 128.175 30.6758C127.799 30.4458 127.36 30.3308 126.858 30.3308C126.356 30.3308 125.907 30.4458 125.509 30.6758C125.133 30.9057 124.83 31.2193 124.6 31.6165C124.37 32.0137 124.255 32.4632 124.255 32.9649C124.255 33.4666 124.36 33.9161 124.569 34.3133C124.799 34.7105 125.112 35.0241 125.509 35.2541C125.907 35.484 126.346 35.599 126.827 35.599Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.45 40.0519C142.809 40.7208 144.388 41.0553 146.186 41.0553C147.586 41.0553 148.83 40.8358 149.917 40.3968C151.004 39.9578 151.934 39.2993 152.708 38.4212L149.635 35.3795C149.175 35.9021 148.663 36.2889 148.098 36.5397C147.555 36.7697 146.917 36.8847 146.186 36.8847C145.433 36.8847 144.806 36.7384 144.304 36.4457C143.802 36.153 143.416 35.7244 143.144 35.16C143.106 35.072 143.071 34.9809 143.039 34.8867L153.649 34.815C153.753 34.3551 153.816 33.9579 153.837 33.6234C153.879 33.2681 153.9 32.9336 153.9 32.62C153.9 31.1148 153.544 29.7768 152.833 28.6061C152.144 27.4354 151.182 26.5261 149.948 25.878C148.715 25.209 147.293 24.8745 145.684 24.8745C144.053 24.8745 142.59 25.2299 141.294 25.9407C139.998 26.6306 138.973 27.5818 138.221 28.7943C137.489 30.0068 137.123 31.397 137.123 32.9649C137.123 34.5537 137.499 35.9649 138.252 37.1983C139.025 38.4108 140.092 39.362 141.45 40.0519ZM143.022 31.1195C143.05 31.0318 143.08 30.9466 143.112 30.8639C143.363 30.2786 143.719 29.8291 144.179 29.5155C144.639 29.2019 145.182 29.0451 145.809 29.0451C146.415 29.0451 146.917 29.181 147.314 29.4528C147.712 29.7246 148.004 30.1322 148.192 30.6758C148.242 30.8005 148.286 30.9341 148.324 31.0766L143.022 31.1195Z"
      fill="white"
    />
    <path
      d="M155.736 25.2508H161.882V26.0602C162.932 25.3116 164.228 24.9372 165.77 24.9372C166.481 24.9372 167.087 25.0209 167.589 25.1881C168.112 25.3553 168.53 25.5957 168.843 25.9093L165.394 30.8012C165.248 30.634 165.028 30.4981 164.735 30.3936C164.464 30.2681 164.14 30.2054 163.763 30.2054C163.136 30.2054 162.666 30.3936 162.352 30.7698C162.039 31.1252 161.882 31.6479 161.882 32.3378V40.679H155.736V25.2508Z"
      fill="white"
    />
    <path
      d="M172.593 40.7731C173.555 41.024 174.517 41.1494 175.478 41.1494C177.527 41.1494 179.158 40.6999 180.37 39.801C181.583 38.9021 182.189 37.6896 182.189 36.1635C182.189 35.1809 182.022 34.3865 181.687 33.7802C181.353 33.1531 180.914 32.6513 180.37 32.275C179.848 31.8987 179.294 31.6061 178.708 31.397C178.123 31.188 177.559 31.0207 177.015 30.8953C176.492 30.7698 176.064 30.634 175.729 30.4876C175.395 30.3413 175.228 30.1531 175.228 29.9232C175.228 29.7559 175.301 29.62 175.447 29.5155C175.614 29.3901 175.897 29.3274 176.294 29.3274C176.712 29.3274 177.234 29.4319 177.862 29.641C178.549 29.9232 179.109 30.2054 179.685 30.7698C180.544 29.5155 182.325 26.9149 182.325 26.9149C182.325 26.9149 180.806 25.7685 179.806 25.4076C178.531 24.9477 177.214 24.7177 175.855 24.7177C174.517 24.7177 173.336 24.9477 172.311 25.4076C171.308 25.8466 170.524 26.4529 169.959 27.2264C169.416 27.979 169.144 28.8466 169.144 29.8291C169.144 30.7908 169.311 31.5852 169.646 32.2123C169.98 32.8186 170.419 33.2994 170.963 33.6548C171.506 33.9893 172.071 34.2611 172.656 34.4701C173.262 34.6583 173.827 34.815 174.35 34.9405C174.893 35.0659 175.332 35.2018 175.667 35.3481C176.022 35.4945 176.2 35.714 176.2 36.0067C176.2 36.1739 176.095 36.3098 175.886 36.4143C175.698 36.4979 175.426 36.5397 175.071 36.5397C174.297 36.5397 173.555 36.3934 172.844 36.1007C172.154 35.808 171.559 35.4108 171.057 34.9091C170.233 36.1007 168.354 38.7122 168.354 38.7122C168.354 38.7122 169.32 39.4276 169.897 39.7383C170.733 40.1773 171.632 40.5222 172.593 40.7731Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M192.494 25H186.6V40.6791H192.633C193.732 40.6791 194.668 40.4892 195.441 40.1094C196.23 39.7143 196.826 39.1902 197.228 38.5369C197.646 37.8684 197.854 37.1163 197.854 36.2807C197.854 35.278 197.561 34.412 196.973 33.6827C196.512 33.0959 195.901 32.6419 195.14 32.3207C195.68 32.0255 196.113 31.6591 196.439 31.2215C196.903 30.5834 197.135 29.8238 197.135 28.9426C197.135 28.1981 196.957 27.5296 196.601 26.9371C196.246 26.3446 195.72 25.8736 195.023 25.5242C194.327 25.1747 193.484 25 192.494 25ZM188.294 39.1978H192.749C193.801 39.1978 194.629 38.9091 195.232 38.3318C195.836 37.7545 196.137 37.0328 196.137 36.1668C196.137 35.6047 196.006 35.0957 195.743 34.6399C195.48 34.1841 195.101 33.8271 194.606 33.5688C194.126 33.2953 193.554 33.1586 192.889 33.1586H188.294V39.1978ZM192.633 31.7001H188.294V26.4585H192.448C193.36 26.4585 194.087 26.6864 194.629 27.1422C195.17 27.598 195.441 28.2437 195.441 29.0793C195.441 29.9149 195.186 30.5606 194.675 31.0164C194.18 31.4722 193.5 31.7001 192.633 31.7001Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M206.464 25L199.92 40.6791H201.73L203.404 36.5998H210.721L212.381 40.6791H214.214L207.74 25H206.464ZM210.118 35.1185L207.077 27.6466L204.011 35.1185H210.118Z"
      fill="white"
    />
    <path
      d="M216.944 40.6791V25H218.15L227.479 37.4544V25H229.196V40.6791H227.989L218.638 28.2225V40.6791H216.944Z"
      fill="white"
    />
    <path
      d="M234.954 32.7806L243.377 40.6791H245.698L236.911 32.48L245.466 25H243.192L234.954 32.2328V25H233.26V40.6791H234.954V32.7806Z"
      fill="white"
    />
  </svg>
);

export default Icon;

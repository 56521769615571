import { FC, useState } from 'react';
import { MetadataRegister, ProgressIndicator } from '~/components';
import { FormBehavior, observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import { AlertMessages, StepsSideAccount, showAlert, useStores } from '~/utils';
import AddBalance from './AddBalance';
import { initialValues, validationSchema } from './schema';
import SideAccountRegister from './SideAccountRegister';

export const SideAccountRegisterContainer: FC = () => {
  const navigate = useNavigate();
  const {
    clients,
    utils: { setProgressId },
  } = useStores();

  const [nameProduct, setNameProduct] = useState('');

  const handleFormSubmit = async (values: AddBalance) => {
    await clients.addBalanceToAccount(values);
    showAlert({
      message: AlertMessages.SUCCESS_ADD_BALANCE,
      type: 'success',
    });
    navigate(Routes.CLIENT_ACCOUNT_DETAILS, { replace: true });
  };

  return (
    <FormBehavior
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
    >
      <SideAccountRegister>
        <ProgressIndicator
          steps={[
            {
              id: StepsSideAccount.DATA,
              label: 'Dados da Conta',
              content: (
                <AddBalance
                  clientStore={clients}
                  nameProduct={nameProduct}
                  onNavigateId={(id) => setProgressId(id)}
                  setNameProduct={(name) => setNameProduct(name)}
                />
              ),
            },
            {
              id: StepsSideAccount.META_DATA,
              label: 'Metadata',
              content: (
                <MetadataRegister onNavigateId={(id) => setProgressId(id)} />
              ),
            },
          ]}
        />
      </SideAccountRegister>
    </FormBehavior>
  );
};

export default observer(SideAccountRegisterContainer);

import { FC } from 'react';
import { IconDefinition } from '~/assets';
import {
  ButtonComponent,
  Description,
  IconComponent,
  Title,
  WrapperCard,
} from './styles';

type Props = {
  icon: IconDefinition;
  title: string;
  description: string;
  labelButton: string;
  disabled?: boolean;
  onPress(): void;
};

const Clients: FC<Props> = ({
  icon,
  title,
  description,
  labelButton,
  disabled,
  onPress,
}) => {
  return (
    <WrapperCard>
      <IconComponent icon={icon} />
      <Title>{title}</Title>
      <Description>{description}</Description>
      <ButtonComponent
        id={title.toLowerCase().replace(' ', '_')}
        onPress={onPress}
        disabled={disabled}
      >
        {labelButton}
      </ButtonComponent>
    </WrapperCard>
  );
};

export default Clients;

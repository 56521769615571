import { FC } from 'react';
import {
  faChartLine,
  faChartNetwork,
  faCircleUser,
  faFileExport,
  faFileLines,
  faUsers,
  faXmark,
} from '~/assets';
import { isEmpty, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import { getActivatedRoute, useStores } from '~/utils';
import If from '../If';
import ItemMenu from './ItemMenu';
import {
  ButtonClose,
  Content,
  Heading,
  IconClose,
  Icons,
  Logo,
  LogoClient,
  Wrapper,
} from './styles';

type Props = {
  onFocus: boolean;
  logoClient?: string;
  isTablet: boolean;
  onClose(): void;
};

const SideMenu: FC<Props> = ({ onFocus, logoClient, isTablet, onClose }) => {
  const navigate = useNavigate();
  const {
    theme: { clientProvider },
  } = useStores();

  const handleNavigate = (route: Routes) => {
    navigate(route);
  };

  return (
    <Wrapper focus={onFocus}>
      <Content>
        <Heading>
          <If condition={isEmpty(logoClient)}>
            <Logo />
          </If>
          <If condition={!isEmpty(logoClient)}>
            <LogoClient src={logoClient} />
          </If>
          <If condition={isTablet}>
            <ButtonClose id="close_side_menu" onPress={onClose}>
              <IconClose icon={faXmark} />
            </ButtonClose>
          </If>
        </Heading>
        <ItemMenu
          id="item_menu_clients"
          text="Clientes"
          icon={<Icons icon={faCircleUser} />}
          activatedRoute={getActivatedRoute(Routes.CLIENTS)}
          onNavigate={() => handleNavigate(Routes.CLIENTS)}
        />
        <ItemMenu
          id="item_menu_products"
          text="Produtos"
          icon={<Icons icon={faChartNetwork} />}
          activatedRoute={getActivatedRoute(Routes.PRODUCTS)}
          onNavigate={() => handleNavigate(Routes.PRODUCTS)}
        />
        <ItemMenu
          id="item_menu_report"
          text="Relatórios"
          icon={<Icons icon={faChartLine} />}
          activatedRoute={false}
          onNavigate={() =>
            window.open(clientProvider?.link_to_reports || '', '_blank')
          }
        />
        <ItemMenu
          id="item_menu_users"
          text="Usuários"
          icon={<Icons icon={faUsers} />}
          activatedRoute={getActivatedRoute(Routes.USERS)}
          onNavigate={() => handleNavigate(Routes.USERS)}
        />
        <ItemMenu
          id="item_menu_files"
          text="Importações"
          icon={<Icons icon={faFileLines} />}
          activatedRoute={getActivatedRoute(Routes.FILES)}
          onNavigate={() => handleNavigate(Routes.FILES)}
        />
        <ItemMenu
          id="item_menu_files"
          text="Exportações"
          icon={<Icons icon={faFileExport} />}
          activatedRoute={getActivatedRoute(Routes.EXPORTS)}
          onNavigate={() => handleNavigate(Routes.EXPORTS)}
        />
      </Content>
      {/* @TODO -  add functionality to settings when possible */}
      {/* <Footer>
        <ItemMenu
          activatedRoute={getActivatedRoute(Routes.SETTINGS)}
          icon={<Icons icon={faGear} />}
          text="Settings"
          onNavigate={() => handleNavigate(Routes.SETTINGS)}
        />
      </Footer> */}
    </Wrapper>
  );
};

export default SideMenu;

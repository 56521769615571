import styled from 'styled-components';
import { getTheme, pxToRem } from '~/modules';
import LetteredAvatarComponent from '../LetteredAvatar';
import Typography from '../Typography';

const backgroundZ4 = getTheme('background.z4');
const themeRadiusCard = getTheme('themeRadius.card');
const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');

export const Row = styled.div`
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-right: ${spacingMd}px;
`;

export const Card = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 1fr;
  align-items: center;
  width: 100%;
  min-height: ${pxToRem(100)};
  padding: 0 ${spacingLg}px;
  background: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;

  & + & {
    margin-top: ${spacingSm}px;
  }
  cursor: pointer;
`;

export const Name = styled(Typography).attrs({ variant: 'md' })`
  flex: 1;
  font-weight: bold;
  margin-right: ${spacingMd}px;
`;

export const Permission = styled(Typography)``;

export const LastActivity = styled(Typography)``;

export const LetteredAvatar = styled(LetteredAvatarComponent)`
  width: ${pxToRem(60)};
  height: ${pxToRem(60)};
  margin-right: ${spacingSm}px;

  p {
    font-size: 22px;
  }
`;

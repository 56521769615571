import { FC } from 'react';
import { isEmpty } from 'lodash';
import { faPlus } from '~/assets';
import {
  AccountRow,
  ClientCardInfo,
  If,
  OptionButton,
  OptionsList,
  PaginatedList,
} from '~/components';
import { Routes } from '~/routes';
import {
  AccountPaymentType,
  PersonType,
  accountsColumns,
  formatAddress,
  formatPhone,
  getActiveAddress,
} from '~/utils';
import {
  ButtonComponent,
  Card,
  Column,
  ContentBox,
  DescriptionLabel,
  IconComponent,
  Label,
  PaginatedListBox,
  SmallCard,
  SmallCardWrapper,
  Title,
  Wrapper,
} from './styles';

type Props = {
  client: Client;
  onShowClientData(isMetadataInitiallyOpened?: boolean): void;
  onPressAccount(account: Account): void;
  onNavigate(route: Routes): void;
};

const ClientAccounts: FC<Props> = ({
  client,
  onShowClientData,
  onPressAccount,
  onNavigate,
}) => {
  const activeAddress = getActiveAddress(client.addresses);
  const isClientPF = client?.personType === PersonType.PF;

  return (
    <Wrapper titlePage="Contas do cliente">
      <ContentBox>
        <Column>
          <ClientCardInfo
            name={client?.fullName}
            email={client?.mail}
            phone={formatPhone(client?.phone)}
            address={formatAddress(activeAddress)}
            onPressSeeData={onShowClientData}
          />
          <If condition={!isEmpty(client?.individual?.metadata)}>
            <Card>
              <Label>METADADOS</Label>
              <SmallCardWrapper>
                {client?.individual?.metadata
                  ?.slice(0, 2)
                  ?.map(({ key, value }, index) => (
                    <SmallCard key={index}>
                      <Label>{key}</Label>
                      <DescriptionLabel>{value}</DescriptionLabel>
                    </SmallCard>
                  ))}
              </SmallCardWrapper>
              <ButtonComponent
                id="btn_see_all"
                onPress={() => onShowClientData(true)}
              >
                <IconComponent icon={faPlus} />
                Ver tudo
              </ButtonComponent>
            </Card>
          </If>
        </Column>
        <PaginatedListBox>
          <OptionsList>
            <OptionButton
              id="btn_add_side_account"
              label={
                isClientPF
                  ? 'Cadastrar Conta Padrão'
                  : 'Cadastrar Conta de Controle'
              }
              icon={faPlus}
              onPress={() => onNavigate(Routes.SIDE_ACCOUNT_REGISTER)}
            />
          </OptionsList>
          <Title>Contas Vinculadas</Title>
          <PaginatedList
            columns={accountsColumns}
            list={client.accounts}
            showPagination={false}
            renderList={(account) => (
              <AccountRow
                balance={
                  account.paymentType === AccountPaymentType.POSTPAID
                    ? account?.limit
                    : account?.balance
                }
                name={account?.product?.name}
                status={account?.status}
                type={account?.type}
                onPress={() => onPressAccount(account)}
                key={`${account?.balance}` + `${account?.product?.name}`}
              />
            )}
            titleEmptyData="Nenhuma conta vinculada"
            descriptionEmptyData="O cliente ainda não possui nenhuma conta vinculada"
          />
        </PaginatedListBox>
      </ContentBox>
    </Wrapper>
  );
};

export default ClientAccounts;

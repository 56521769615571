import { Icon, getTheme, pxToRem, styled } from '~/modules';
import Button from '../Button';
import Modal from '../Modal';
import TextInput from '../TextInput';

const boxShadowZ4 = getTheme('boxShadow.z4');

const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXxl = getTheme('spacing.xxl');

const borderRadiusMax = getTheme('borderRadius.max');

export const Wrapper = styled(Modal)`
  flex-direction: column;
  width: ${pxToRem(668)};
  max-height: 95%;
  max-width: 90%;
  ${boxShadowZ4};
`;

export const Content = styled.div`
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  overflow-y: auto;
  padding: ${spacingXxl}px;
`;

export const Input = styled(TextInput)`
  margin-bottom: ${spacingMd}px;
`;

export const ButtonRegisterUser = styled(Button)`
  min-width: ${pxToRem(280)};
  min-height: ${pxToRem(60)};
  border-radius: ${borderRadiusMax}px;
  margin-top: ${spacingLg}px;
`;

export const IconPlus = styled(Icon).attrs({
  fontSize: pxToRem(20),
})`
  margin-right: ${spacingMd}px;
`;

import { FC } from 'react';

const Icon: FC<IconProps> = ({ className, fill = '#5AFFCB' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2162"
    height="1621"
    className={className}
    fill="none"
    viewBox="0 0 2162 1621"
  >
    <g fill={fill} opacity="0.1">
      <path
        fillRule="evenodd"
        d="M882.636 1277V956.776h226.634c83.45 0 151.09-67.468 151.09-150.694V674.224h75.55c83.44 0 151.09 67.468 151.09 150.694v301.392c0 83.22-67.65 150.69-151.09 150.69H882.636z"
        clipRule="evenodd"
      />
      <path d="M1109.27 354H656v602.776h226.636V674.224h377.724v-169.53c0-83.226-67.64-150.694-151.09-150.694z" />
    </g>
    <g fill={fill} opacity="0.1">
      <path
        fillRule="evenodd"
        d="M226.636 1621v-320.22h226.637c83.445 0 151.091-67.47 151.091-150.7v-131.86h75.545c83.445 0 151.091 67.47 151.091 150.7v301.39c0 83.22-67.646 150.69-151.091 150.69H226.636z"
        clipRule="evenodd"
      />
      <path d="M453.273 698H0v602.78h226.636v-282.56h377.728V848.694c0-83.226-67.646-150.694-151.091-150.694z" />
    </g>
    <g fill={fill} opacity="0.1">
      <path
        fillRule="evenodd"
        d="M1557.64 923V602.776h226.63c83.45 0 151.09-67.468 151.09-150.694V320.224h75.55c83.44 0 151.09 67.468 151.09 150.694v301.388C2162 855.532 2094.35 923 2010.91 923h-453.27z"
        clipRule="evenodd"
      />
      <path d="M1784.27 0H1331v602.776h226.64V320.224h377.72v-169.53C1935.36 67.468 1867.72 0 1784.27 0z" />
    </g>
  </svg>
);

export default Icon;

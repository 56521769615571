import { FormValidator, isValidCpf } from '~/modules';
import {
  States,
  isValidDate,
  validateMaxLength,
  validateMinLength,
} from '~/utils';

const isPhone = (value?: string): boolean => {
  if (!value) return true;
  return value.length === 15 || value.length === 14;
};

const isWebsite = (value?: string): boolean => {
  if (!value) return true;
  const match = value.match(
    /^www\.[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
  );
  return match != null;
};

const isState = (value?: string): boolean => {
  if (!value) return true;
  return !!States.find((state) => state === value);
};

const isEmail = (value?: string): boolean => {
  if (!value) return true;
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!re.test(value)) {
    return false;
  }
  const parts = value.split('@');
  const [localPart, domain] = parts;
  if (
    /\.{2}/.test(localPart) ||
    /\.$/.test(localPart) ||
    /^\./.test(localPart) ||
    /[^a-zA-Z0-9._%-]/.test(localPart)
  ) {
    return false;
  }
  return !(
    /^\./.test(domain) ||
    /\.$/.test(domain) ||
    !/^[a-zA-Z0-9.-]+$/.test(domain) ||
    !/\.[a-zA-Z]{2,}$/.test(domain)
  );
};

export const validationSchema = FormValidator.object().shape({
  firstName: FormValidator.string()
    .required('Campo obrigatório')
    .test(
      'valid',
      'Tamanho inválido, superior a 254 caracteres',
      validateMaxLength,
    )
    .test(
      'valid',
      'Tamanho inválido, inferior a 3 caracteres',
      validateMinLength,
    ),

  lastName: FormValidator.string()
    .required('Campo obrigatório')
    .test(
      'valid',
      'Tamanho inválido, superior a 254 caracteres',
      validateMaxLength,
    )
    .test(
      'valid',
      'Tamanho inválido, inferior a 3 caracteres',
      validateMinLength,
    ),

  document: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'CPF inválido', isValidCpf),

  motherName: FormValidator.string(),
  // .required('Campo obrigatório')
  // .test(
  //   'valid',
  //   'Tamanho inválido, superior a 254 caracteres',
  //   validateMaxLength,
  // )
  // .test(
  //   'valid',
  //   'Tamanho inválido, inferior a 3 caracteres',
  //   validateMinLength,
  // ),

  birthDate: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Data inválida', isValidDate),

  mail: FormValidator.string()
    // .required('Campo obrigatório')
    .test('valid', 'E-mail inválido', isEmail),

  phone: FormValidator.string()
    // .required('Campo obrigatório')
    .test('valid', 'Telefone inválido', isPhone),

  website: FormValidator.string().test('valid', 'Website inválido', isWebsite),

  gender: FormValidator.string().required('Campo obrigatório'),

  address: FormValidator.object().shape({
    postalCode: FormValidator.string(),
    // .required('Campo obrigatório'),
    street: FormValidator.string(),
    // .required('Campo obrigatório'),
    number: FormValidator.string(),
    // .required('Campo obrigatório'),
    neighborhood: FormValidator.string(),
    // .required('Campo obrigatório'),
    city: FormValidator.string(),
    // .required('Campo obrigatório'),
    state: FormValidator.string()
      // .required('Campo obrigatório')
      .test('valid', 'Estado inválido', isState),
  }),

  identityDocument: FormValidator.object().shape({
    number: FormValidator.string(),
    // .required('Campo obrigatório'),
    dispatchingAgency: FormValidator.string(),
    // .required('Campo obrigatório'),
    state: FormValidator.string(),
    // .required('Campo obrigatório')
    // .test('valid', 'Estado inválido', isState),
    issueDate: FormValidator.string(),
    // .required('Campo obrigatório')
    // .test('valid', 'Data inválida', isValidDate),
  }),
});

export const initialValues: ClientRegister = {
  firstName: '',
  lastName: '',
  document: '',
  birthDate: '',
  gender: '',
  // motherName: '',
  // mail: '',
  // phone: '',
  // internalPersonId: '',
  // identityDocument: {
  //   number: '',
  //   dispatchingAgency: '',
  //   state: '',
  //   issueDate: '',
  // },
  // address: {
  //   city: '',
  //   neighborhood: '',
  //   number: '',
  //   state: '',
  //   street: '',
  //   complement: '',
  //   postalCode: '',
  // },
} as ClientRegister;

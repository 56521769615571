import { LoadDotsAnimation } from '~/assets';
import { Animation, getTheme, pxToRem, styled } from '~/modules';
import TextInput from '../TextInput';

const spacingMd = getTheme('spacing.md');
const spacingSm = getTheme('spacing.sm');

const backgroundZ2 = getTheme('background.z2');
const themeRadiusButton = getTheme('themeRadius.button');
const brandPrimaryMain = getTheme('brand.primary.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');
const textMain = getTheme('text.main');

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const InputSearch = styled(TextInput)``;

export const WrapperList = styled.div`
  position: absolute;
  width: 100%;
  height: max-content;
  flex-direction: column;
  overflow-y: scroll;
  bottom: 0;
  top: 80px;
  max-height: ${pxToRem(300)};
  padding: ${spacingMd}px;
  background-color: ${backgroundZ2};
  border-radius: ${themeRadiusButton}px;
`;

export const ItemList = styled.div`
  width: 100%;
  padding: ${spacingSm}px 0;
  cursor: pointer;
  color: ${textMain};

  &:hover {
    background-color: ${brandPrimaryMain};
    color: ${brandPrimaryContrast};
  }
`;

export const LoadingIndicator = styled(Animation).attrs({
  options: {
    animationData: LoadDotsAnimation,
  },
  width: pxToRem(60),
  height: pxToRem(60),
  speed: 1.5,
})``;

import { FC } from 'react';
import { UserStatus } from '~/utils';
import If from '../If';
import LetteredAvatar from '../LetteredAvatar';
import StatusIndicator from '../StatusIndicator';
import { Name, UserName, Wrapper, WrapperLetter } from './styles';

type Props = {
  name?: string;
  status?: UserStatus;
  userName?: string;
};

const PersonInfo: FC<Props> = ({ name, status, userName }) => (
  <Wrapper>
    <WrapperLetter>
      <LetteredAvatar name={name} />
    </WrapperLetter>
    <Name>{name}</Name>
    <UserName>{userName}</UserName>
    <If condition={!!status}>
      <StatusIndicator status={status} />
    </If>
  </Wrapper>
);

export default PersonInfo;

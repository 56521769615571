import { FC, useEffect, useMemo, useState } from 'react';
import { FormBehavior, isValidCnpj, observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import { removeMaskCpfOrCnpj, showAlert, useStores } from '~/utils';
import Clients from './Clients';
import { getInitialValue, validationSchema } from './schema';

const ClientSearchContainer: FC = () => {
  const {
    clients: {
      documentSpotlightSearch,
      getClientAccount,
      setDocumentSpotlightSearch,
    },
  } = useStores();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorPJ, setErrorPJ] = useState('');

  const initialValues: SearchClient = useMemo(() => {
    return getInitialValue(documentSpotlightSearch || '');
  }, [documentSpotlightSearch]);

  const handleFormSubmit = async ({ document }: SearchClient) => {
    setLoading(true);
    try {
      await getClientAccount(removeMaskCpfOrCnpj(document));
      setDocumentSpotlightSearch();
      navigate(Routes.CLIENT_ACCOUNTS);
    } catch ({ message }) {
      showAlert({ message });
      isValidCnpj(document) && setErrorPJ(message);
    } finally {
      setLoading(false);
    }
  };

  const handleSpotlightSearch = () => {
    const document = documentSpotlightSearch;
    document && handleFormSubmit({ document });
  };

  useEffect(() => {
    handleSpotlightSearch();
    return () => {
      setDocumentSpotlightSearch();
    };
  }, [documentSpotlightSearch]);

  return (
    <FormBehavior
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      enableReinitialize
    >
      <Clients
        isSubmitting={loading}
        error={errorPJ}
        onClearErrorPJ={() => setErrorPJ('')}
        onNavigate={(route) => navigate(route)}
      />
    </FormBehavior>
  );
};

export default observer(ClientSearchContainer);

import { FC, useEffect, useMemo, useState } from 'react';
import {
  ModalConfirmation,
  ModalListInstallmentDates,
  ModalListMetaData,
} from '~/components';
import { isEmpty, observer } from '~/modules';
import { AlertMessages, ProductStatus, showAlert, useStores } from '~/utils';
import ProductDetails from './ProductDetails';

export const ProductDetailsContainer: FC = () => {
  const {
    products: {
      currentProduct,
      controlAccountList,
      mccList,
      getControlAccounts,
      setStatusProduct,
      getMCC,
    },
  } = useStores();

  const [isAccountLoading, setIsAccountLoading] = useState(true);
  const [textModalConfirmation, setTextModalConfirmation] =
    useState<AlertMessages>('' as AlertMessages);
  const [optionSelected, setOptionSelected] = useState<ProductStatus>();
  const [onModalInfos, setOnModalInfos] = useState(false);
  const [onModalInstallmentDates, setOnModalInstallmentDates] = useState(false);

  const handleModalInfos = () =>
    setOnModalInfos((onModalInfos) => !onModalInfos);

  const handleModalInstallmentDates = () => {
    setOnModalInstallmentDates(
      (onModalInstallmentDates) => !onModalInstallmentDates,
    );
  };

  const onGetControlAccounts = async ({ page }: ListProps) => {
    setIsAccountLoading(true);
    try {
      await getControlAccounts({
        productId: currentProduct.id,
        page,
      });
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setIsAccountLoading(false);
    }
  };

  const onGetMCC = async () => {
    try {
      await getMCC();
    } catch ({ message }) {
      showAlert({ message });
    }
  };

  const handleOnpressOption = (option: ProductStatus, text: AlertMessages) => {
    setOptionSelected(option);
    setTextModalConfirmation(text);
  };

  const handleOptionSelected = async () => {
    const message = await setStatusProduct(currentProduct.id, optionSelected);
    setTextModalConfirmation('' as AlertMessages);
    showAlert({ message, type: 'success' });
  };

  const mcc: MCC[] = useMemo(() => {
    if (mccList.mccList) {
      return currentProduct.merchantCategoryCodes?.reduce((acc, item) => {
        const mccFilter = mccList.mccList.filter((value) => value.mcc === item);
        return !isEmpty(mccFilter)
          ? [...acc, mccFilter[0]]
          : [...acc, { mcc: item }];
      }, [] as any);
    }
  }, [mccList.mccList]);

  useEffect(() => {
    onGetControlAccounts({});
    onGetMCC();
  }, [currentProduct.id]);

  return (
    <>
      <ProductDetails
        product={currentProduct}
        accounts={controlAccountList}
        isAccountLoading={isAccountLoading}
        onChangePage={(page) => onGetControlAccounts({ page })}
        onPressOption={handleOnpressOption}
        onPressSeeAll={handleModalInfos}
        onPressOpenModalInstallmentDates={handleModalInstallmentDates}
      />
      <ModalConfirmation
        isOpen={!isEmpty(textModalConfirmation)}
        description={textModalConfirmation}
        onConfirm={handleOptionSelected}
        onCancel={() => setTextModalConfirmation('' as AlertMessages)}
      />
      <ModalListInstallmentDates
        isOpen={onModalInstallmentDates}
        currentProduct={currentProduct}
        handleClose={handleModalInstallmentDates}
      />
      <ModalListMetaData
        onSections
        isOpen={onModalInfos}
        mccList={mcc}
        metaDataList={currentProduct.metadata}
        handleClose={handleModalInfos}
        hideExtraInfo
      />
    </>
  );
};

export default observer(ProductDetailsContainer);

import { FC, useEffect } from 'react';
import { faPlus } from '~/assets';
import { FormikProps, useFormikContext } from '~/modules';
import { Masks, RolesValueExports } from '~/utils';
import HeaderModal from '../HeaderModal';
import Select from '../Select';
import { ButtonUploadFile, Content, IconPlus, Input, Wrapper } from './styles';

type Props = {
  isOpen: boolean;
  handleClose(): void;
};

const ModalExportFile: FC<Props> = ({ isOpen, handleClose }) => {
  const {
    values,
    errors,
    touched,
    submitForm,
    resetForm,
    handleChange,
    setFieldValue,
  }: FormikProps<ExportFile> = useFormikContext();

  useEffect(() => {
    !isOpen && resetForm();
  }, [isOpen]);

  return (
    <Wrapper isOpen={isOpen}>
      <HeaderModal title="Gerar Arquivo" onPressClose={handleClose} />
      <Content>
        <Input
          label="Data inicial"
          placeholder="00/00/0000"
          id="startDate"
          name="startDate"
          error={touched.startDate && errors.startDate}
          value={values.startDate}
          mask={Masks.date}
          onChange={handleChange}
        />
        <Input
          label="Data final"
          placeholder="00/00/0000"
          id="endDate"
          name="endDate"
          error={touched.endDate && errors.endDate}
          value={values.endDate}
          mask={Masks.date}
          onChange={handleChange}
        />
        <Select
          label="Tipo"
          placeholder="Selecione o tipo do arquivo"
          id="type"
          name="type"
          error={touched?.type && errors?.type}
          onChange={({ name, value }) => setFieldValue(name, value)}
          value={values.type}
          options={RolesValueExports}
        />
        <ButtonUploadFile
          id="btn_register_user"
          onPress={submitForm}
          onKeyEnter
        >
          <IconPlus icon={faPlus} />
          Gerar
        </ButtonUploadFile>
      </Content>
    </Wrapper>
  );
};

export default ModalExportFile;

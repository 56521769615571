import { FC, useEffect, useState } from 'react';
import { ModalExportFile } from '~/components';
import { observer, saveAs } from '~/modules';
import { AlertMessages, UserRoles, showAlert, useStores } from '~/utils';
import Exports from './Exports';

export const ExportsContainer: FC = () => {
  const {
    user: { user },
    exports: { uploadFile, getExports, exportsList, downloadExport },
  } = useStores();

  const [onUploadFileModal, setOnUploadFileModal] = useState(false);
  const [isFilesLoading, setIsFilesLoading] = useState(true);

  const handleUploadFileModal = () =>
    setOnUploadFileModal((onUploadFileModal) => !onUploadFileModal);

  const handleUploadFile = async (values: ExportFile) => {
    try {
      await uploadFile(values);
      handleUploadFileModal();
      onGetExports({});
      showAlert({
        message: AlertMessages.FILES_UPLOAD_FILE,
        type: 'success',
      });
    } catch ({ message }) {
      showAlert({ message });
    }
  };

  const onGetExports = async (values: GetUsersProps) => {
    setIsFilesLoading(true);
    try {
      await getExports(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setIsFilesLoading(false);
    }
  };

  const handleExportDownload = async (values: FileExport) => {
    try {
      const response = await downloadExport(values);
      const filename = response.headers
        .get('Content-Disposition')
        .split(';')
        .find((n) => n.includes('filename='))
        .replace('filename=', '')
        .trim();
      saveAs(response.data, filename);
    } catch ({ message }) {
      showAlert({
        message: AlertMessages.FAIL_EXPORT_FILE_DOWNLOAD,
      });
    } finally {
      setIsFilesLoading(false);
    }
  };

  useEffect(() => {
    onGetExports({});
  }, []);

  return (
    <>
      <Exports
        isFilesLoading={isFilesLoading}
        filesList={exportsList}
        isUserEspectator={user.role === UserRoles.SPECTATOR}
        onPressFile={handleExportDownload}
        onChangePage={(page) => onGetExports({ page })}
        onPressUploadFile={handleUploadFileModal}
      />
      <ModalExportFile
        isOpen={onUploadFileModal}
        handleClose={handleUploadFileModal}
        handleFormSubmit={handleUploadFile}
      />
    </>
  );
};

export default observer(ExportsContainer);

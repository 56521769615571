import { FC } from 'react';
import { faPlus } from '~/assets';
import { PaginatedList, TitlePage, UsersRow } from '~/components';
import { usersColumns } from '~/utils';
import {
  ButtonRegisterUser,
  ContentBox,
  IconPlus,
  TopBox,
  Wrapper,
} from './styles';

type Props = {
  isUsersLoading: boolean;
  usersList: ListResponse<Users>;
  isUserEspectator: boolean;
  onPressRegisterUser(): void;
  onPressFilter(filterValue: string): Promise<void> | void;
  onChangePage(page: number): void;
  onPressUser(user: User): void;
};

const Users: FC<Props> = ({
  usersList,
  isUsersLoading,
  isUserEspectator,
  onPressRegisterUser,
  onPressFilter,
  onChangePage,
  onPressUser,
}) => (
  <Wrapper
    titlePage="Usuários"
    labelFilter="Buscar Usuário"
    onPressFilter={onPressFilter}
  >
    <ContentBox>
      <TopBox>
        <TitlePage totalElements={usersList.paging?.totalElements}>
          Usuários
        </TitlePage>
        <ButtonRegisterUser
          id="btn_modal_register_user"
          disabled={isUserEspectator}
          onPress={onPressRegisterUser}
          secondary
        >
          <IconPlus icon={faPlus} />
          Criar Usuário
        </ButtonRegisterUser>
      </TopBox>

      <PaginatedList
        columns={usersColumns}
        list={usersList.content}
        totalPages={usersList.paging?.totalPages}
        currentPage={usersList.paging?.page}
        isLoading={isUsersLoading}
        onChangePage={onChangePage}
        renderList={(user) => (
          <UsersRow
            name={user.name}
            role={user.role}
            status={user.status}
            lastActivity={user.lastActivity}
            onPress={() => onPressUser(user)}
            key={`${user.name}` + `${user.lastActivity}`}
          />
        )}
        titleEmptyData="Sem usuários cadastrados"
      />
    </ContentBox>
  </Wrapper>
);

export default Users;

import { Button, SceneWrapper, TextInput, Typography } from '~/components';
import { getTheme, pxToRem, styled } from '~/modules';

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingXxl = getTheme('spacing.xxl');
const spacingXl = getTheme('spacing.xl');

const borderRadiusMax = getTheme('borderRadius.max');

const dangerLight = getTheme('danger.light');
const textLink = getTheme('text.link');

export const Wrapper = styled(SceneWrapper)`
  flex: 1;
`;

export const ContentBox = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography).attrs({ variant: 'xl' })`
  font-weight: bold;
  text-align: center;
  margin-bottom: ${spacingMd}px;
`;

export const Description = styled(Typography)`
  text-align: center;
  max-width: ${pxToRem(635)};
  margin-top: ${spacingSm}px;
  margin-bottom: ${spacingMd}px;
`;

export const Input = styled(TextInput)`
  max-width: ${pxToRem(635)};
  margin-right: ${spacingMd}px;
  margin-bottom: ${spacingXxl}px;
`;

export const ButtonSearch = styled(Button)`
  width: ${pxToRem(280)};
  height: ${pxToRem(60)};
  border-radius: ${borderRadiusMax}px;
`;

export const Error = styled(Typography).attrs({
  variant: 'sm',
})`
  color: ${dangerLight};
  margin-top: -${spacingXl}px;
  margin-bottom: ${spacingXl}px;
`;

export const LinkError = styled(Typography).attrs({
  variant: 'sm',
})`
  color: ${textLink};
  text-decoration: underline;
`;

export const WrapperTextError = styled.div`
  cursor: pointer;
`;

import { BackgroundLetters, Button, Typography } from '~/components';
import { Icon, getTheme, pxToRem, styled } from '~/modules';

const brandSecondaryMain = getTheme('brand.secondary.main');
const brandSecondaryContrast = getTheme('brand.secondary.contrast');
const infoContrast = getTheme('info.contrast');

const spacingXl = getTheme('spacing.xl');
const spacingLg = getTheme('spacing.lg');

export const Wrapper = styled(BackgroundLetters)``;

export const Content = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

export const RoundedWrapper = styled.div`
  width: ${pxToRem(106)};
  height: ${pxToRem(106)};
  border-radius: 50%;
  background-color: ${brandSecondaryMain};
  margin-bottom: ${spacingXl}px;
  justify-content: center;
  align-items: center;
`;

export const IconExclamation = styled(Icon).attrs({ fontSize: pxToRem(32) })`
  color: ${brandSecondaryContrast};
`;

export const Title = styled(Typography).attrs({ variant: 'md' })`
  font-weight: bold;
  color: ${infoContrast};
  margin-bottom: ${spacingLg}px;
`;

export const Description = styled(Typography).attrs({ variant: 'md' })`
  color: ${infoContrast};
  max-width: ${pxToRem(800)};
  text-align: center;
  margin-bottom: ${spacingXl}px;
`;

export const ButtonLogin = styled(Button)`
  width: ${pxToRem(250)};
  height: ${pxToRem(51)};
`;

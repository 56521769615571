import { isEmpty } from 'lodash';
import { AddressStatus } from '../enums';

export const formatAddress = (address?: Address): string => {
  if (!address) return '-';
  const { number, state, street, postalCode, neighborhood, city } = address;
  return `${street}, ${number} - ${neighborhood}, ${city} - ${state}, ${formatPostalCode(
    postalCode,
  )}`;
};

export const getActiveAddress = (addresses?: Address[]) => {
  return addresses?.find((address) => address.status === AddressStatus.ACTIVE);
};

export const formatPostalCode = (postalCode?: string) => {
  if (!postalCode || isEmpty(postalCode)) return '';

  return postalCode
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
};

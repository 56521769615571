import { FC, ReactNode } from 'react';
import { DefaultWrapper } from './styles';

type Props = {
  children: ReactNode;
};

const SideAccountRegister: FC<Props> = ({ children }) => (
  <DefaultWrapper titlePage="Efetivar" hiddenBorder offPaddingContent>
    {children}
  </DefaultWrapper>
);

export default SideAccountRegister;

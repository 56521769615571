import {
  faArrowDown,
  faArrowUp,
  faHistory,
  faHorizontalRule,
  faUsdCircle,
} from '~/assets';

export enum TransactionType {
  CASH_IN = 'CASH_IN',
  CASH_OUT = 'CASH_OUT',
  REFUND = 'REFUND',
  EXPIRATION = 'EXPIRATION',
  LIMIT = 'LIMIT',
}

export enum TransactionTypePt {
  CASH_IN = 'Crédito',
  CASH_OUT = 'Débito',
  REFUND = 'Estorno',
  EXPIRATION = 'Recolhimento',
  LIMIT = 'Limite',
}

export const TRANSACTION_ICONS = {
  [TransactionType.CASH_IN]: faArrowDown,
  [TransactionType.REFUND]: faHorizontalRule,
  [TransactionType.LIMIT]: faUsdCircle,
  [TransactionType.EXPIRATION]: faHistory,
  [TransactionType.CASH_OUT]: faArrowUp,
};

import moment from 'moment';

const ISO_DATE = 'YYYY-MM-DDTHH:mm:ss';
const SCREEN_DATE = 'DD/MM/YYYY';
const SHORT_DATE = 'DD/MM/YYYY';
const LONG_DATE = 'D [de] MMMM';
const HOUR_MINUTE = 'HH:mm';
const TODAY = 'Hoje';
const SHORT_DATE_HOUR_MINUTE = 'DD/MM/YYYY [às] HH:mm';
const SHORT_DATE_HOUR_MINUTE_SECOND = 'DD/MM/YYYY [às] HH:mm:ss';
const MONTH_YEAR = 'MMMM YYYY';
const DAY_MONTH = 'DD/MM';
const DATE = 'YYYY-MM-DD';

export const WEEKEND_DAYS = ['Sab', 'Dom'];
export const WEEK_DAYS = ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', ...WEEKEND_DAYS];

moment.updateLocale('pt', {
  months: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  weekdays: [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ],
});

export const formatToScreenDate = (date: Date): string =>
  moment.utc(date, ISO_DATE).format(SCREEN_DATE);

export const formatScreenDateToISO = (date: Date): string =>
  moment.utc(date, SHORT_DATE).format(ISO_DATE);

export const formatShortDate = (date: Date): string =>
  moment.utc(date).format(SHORT_DATE);

export const formatLongDate = (date: Date): string =>
  moment.utc(date).format(LONG_DATE);

export const formatHourMinuteDate = (date: Date): string =>
  moment(date).format(HOUR_MINUTE);

export const isDateNow = (timestamp: number): boolean =>
  moment.utc(new Date()).format(SHORT_DATE) ===
  moment.unix(timestamp).format(SHORT_DATE);

export const formatTimestampToDateOrToday = (timestamp?: number): string => {
  if (!timestamp) return '';
  return isDateNow(timestamp)
    ? TODAY
    : moment.unix(timestamp).format(SHORT_DATE);
};

export const formatTimestampToDate = (timestamp?: number): string =>
  timestamp ? moment.unix(timestamp || 0).format(SHORT_DATE_HOUR_MINUTE) : '';

export const formatHourMinuteSecondDate = (date: Date | undefined): string =>
  moment(date).format(SHORT_DATE_HOUR_MINUTE_SECOND);

export const formatShortDateFromString = (date?: string): string =>
  date ? moment(date).format(SHORT_DATE) : '';

export const formatToMonthYear = (date: Date) =>
  moment.utc(date).format(MONTH_YEAR);

export const formatToApiDate = (date: Date) => moment.utc(date).format(DATE);

export const formatToDayMonth = (date: Date) =>
  moment.utc(date).format(DAY_MONTH);

export const timestampNow = () => moment.utc(new Date()).unix();

export const isValidDate = (date?: string): boolean => {
  if (!date) return false;
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const [d, m, y] = date.split('/').map(Number);

  if (d > 0 && d <= 31 && m > 0 && m <= 12 && y >= 0) {
    return y < year || (y === year && (m < month || (m === month && d <= day)));
  }

  return false;
};

export const isAfterDate = (
  compairFieldValue: string,
  value: string,
): boolean => {
  const startDate = moment(compairFieldValue, SCREEN_DATE).toDate();
  const endDate = moment(value, SCREEN_DATE).add(1, 'days').toDate();
  return endDate > startDate;
};

export const isValidDateWithFutureDate = (date?: string): boolean => {
  if (!date) return false;
  const today = new Date('3000-12-31');
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const [d, m, y] = date.split('/').map(Number);

  if (d > 0 && d <= 31 && m > 0 && m <= 12 && y >= 0) {
    return y < year || (y === year && (m < month || (m === month && d <= day)));
  }

  return false;
};

export const toApiDateWithZ = (date: string) =>
  moment(date, SCREEN_DATE).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

import axios, { AxiosResponse } from 'axios';
import { Storage } from '~/services';
import { ExpiredSessionError, ResponseErrors } from '~/utils';

const { REACT_APP_BASE_PATH } = process.env;

export const request = axios.create({
  baseURL: REACT_APP_BASE_PATH,
  timeout: 30000, // 30 seconds timeout
});

const successResponse = (response: any): AxiosResponse => response;

const errorResponse = (
  error: ErrorResponse,
): ExpiredSessionError | ErrorResponse => {
  const errorCode = error.response.data.code;
  if (
    errorCode === ResponseErrors.TOKEN_EXPIRED ||
    errorCode === ResponseErrors.TOKEN_UNAUTHORIZED ||
    errorCode === ResponseErrors.USER_NOT_FOUND
  ) {
    Storage.clearWholeStorage();
    throw new ExpiredSessionError();
  }
  throw error;
};

const addAuthHeaders = async (config) => {
  const client_id: string = Storage.getClientId();
  const token: Token | null = Storage.getToken();

  return {
    ...config,
    headers: {
      ...config.headers,
      client_id,
      'x-buildersbank-authorization': token?.accessToken,
    },
  };
};

request.interceptors.response.use(successResponse, errorResponse);

request.interceptors.request.use(addAuthHeaders);

export default request;

import { FC } from 'react';
import { IconDefinition } from '~/assets';
import { Icon, Label, Value, WrapperItem, WrapperValue } from './styles';

type Props = {
  icon: IconDefinition;
  label: string;
  value?: string;
};

const Item: FC<Props> = ({ icon, label, value }) => (
  <WrapperItem>
    <Icon icon={icon} />
    <WrapperValue>
      <Label>{label}</Label>
      <Value>{value || '-'}</Value>
    </WrapperValue>
  </WrapperItem>
);

export default Item;

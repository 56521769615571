import { FC, ReactNode, useEffect, useState } from 'react';
import { observer } from '~/modules';
import {
  AlertMessages,
  UserStatus,
  getFirstAndLastName,
  showAlert,
  useStores,
} from '~/utils';
import Filter from '../Filter';
import Header from '../Header';
import If from '../If';
import ModalNewPassword from '../ModalNewPassword';
import SideMenu from '../SideMenu';
import SpotlightSearch from '../SpotlightSearch';
import { ChildrenWrapper, Constrain, Wrapper, WrapperHeading } from './styles';

type Props = {
  children: ReactNode;
  titlePage?: string;
  showHeader?: boolean;
  labelFilter?: string;
  hiddenBorder?: boolean;
  offPaddingContent?: boolean;
  onPressFilter?(filterValue: string): void | Promise<void>;
};

const SceneWrapper: FC<Props> = ({
  children,
  titlePage = AlertMessages.TITLE_PAGE,
  showHeader = true,
  labelFilter,
  hiddenBorder,
  offPaddingContent,
  onPressFilter,
  ...rest
}): JSX.Element => {
  const {
    user: {
      user: { name, status },
      logout,
      changePassword,
    },
    theme: { clientProvider, isTablet },
  } = useStores();

  const onNewPasswordModal = status === UserStatus.PENDING;

  const [onMenuFocus, setOnMenuFocus] = useState(!isTablet);
  const handleMenuFocus = () => setOnMenuFocus((onMenuFocus) => !onMenuFocus);

  const [onSpotlightSearch, setOnSpotlightSearch] = useState(false);
  const handleOnSpotlight = () =>
    setOnSpotlightSearch((onSpotlightSearch) => !onSpotlightSearch);

  const handleLogout = async () => {
    await logout();
  };

  const handleNewPassword = async (values: ChangePassword) => {
    await changePassword(values);
    showAlert({
      message: AlertMessages.SUCCESS_CHANGE_PASSWORD,
      type: 'success',
    });
  };

  const handleClickChildrenWrapper = () => {
    onMenuFocus && isTablet && handleMenuFocus();
  };

  useEffect(() => {
    isTablet && setOnMenuFocus(false);
  }, [isTablet]);

  return (
    <>
      <Wrapper>
        <If condition={showHeader}>
          <SideMenu
            onFocus={onMenuFocus}
            logoClient={clientProvider?.logoMenu}
            isTablet={isTablet}
            onClose={handleMenuFocus}
          />
        </If>
        <ChildrenWrapper
          blur={onMenuFocus && isTablet ? true : undefined}
          onClick={handleClickChildrenWrapper}
          {...rest}
        >
          <If condition={showHeader}>
            <WrapperHeading hiddenBorder={hiddenBorder}>
              <Header
                titlePage={titlePage}
                nameUser={getFirstAndLastName(name)}
                onPressFocusMenu={handleMenuFocus}
                onPressLogout={handleLogout}
                onPressMagnifyingGlass={handleOnSpotlight}
              />
              <If condition={!!onPressFilter}>
                <Filter
                  label={labelFilter}
                  onPressFilter={onPressFilter || (() => {})}
                />
              </If>
            </WrapperHeading>
          </If>
          <Constrain
            blur={onMenuFocus && isTablet ? true : undefined}
            offPaddingContent={offPaddingContent}
          >
            {children}
          </Constrain>
        </ChildrenWrapper>
      </Wrapper>

      <ModalNewPassword
        isOpen={onNewPasswordModal}
        handleFormSubmit={handleNewPassword}
      />

      <SpotlightSearch
        isOpen={onSpotlightSearch}
        handleOnSpotlight={handleOnSpotlight}
      />
    </>
  );
};

export default observer(SceneWrapper);

import { FormValidator, isValidCpf, isValidEmail } from '~/modules';
import { validateName } from '~/utils';

export const validationSchema = FormValidator.object().shape({
  name: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Nome inválido', validateName),
  role: FormValidator.string().required('Campo obrigatório'),
  document: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'CPF inválido', isValidCpf),
  email: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Email inválido', isValidEmail),
});

export const initialValues: RegisterUser = {
  name: '',
  document: '',
  email: '',
  role: '',
};

import { FormValidator } from '~/modules';
import { isAfterDate, isValidDate } from '~/utils';

export const validationSchema = FormValidator.object().shape({
  startDate: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Data inválida', isValidDate),
  endDate: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Data inválida', isValidDate)
    .test(
      'valid',
      'Data final deve ser posterior à data inicial',
      (value, context) => {
        return isAfterDate(context.parent.startDate, value);
      },
    ),
  type: FormValidator.string().required('Campo obrigatório'),
});

export const initialValues: ExportFile = {
  startDate: '',
  endDate: '',
  type: '',
};

import { getTheme, ifStyle, pxToRem, styled } from '~/modules';

const brandPrimaryMain = getTheme('brand.primary.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');
const backgroundZ2 = getTheme('background.z2');

const spacingXs = getTheme('spacing.xs');
const borderRadiusMax = getTheme('borderRadius.max');

const isActive = ifStyle('isActive');

type Props = {
  isActive?: boolean;
};

export const Wrapper = styled.div<Props>`
  align-items: center;
  justify-content: ${isActive('flex-end', 'flex-start')};
  width: ${pxToRem(50)};
  height: ${pxToRem(28)};
  padding: ${spacingXs}px;
  background-color: ${isActive(brandPrimaryMain, backgroundZ2)};
  border-radius: ${borderRadiusMax}px;

  -webkit-transition: justify-content 0.4s ease-in-out;
  transition: justify-content 0.4s ease-in-out;

  cursor: pointer;
`;

export const Rounded = styled.div`
  width: ${pxToRem(20)};
  height: ${pxToRem(20)};
  background-color: ${brandPrimaryContrast};
  border-radius: ${borderRadiusMax}px;
  cursor: pointer;
`;

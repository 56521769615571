import { FC } from 'react';
import ToggleSwitch from '../ToggleSwitch';
import { Card, Description, MCC, Row, RowMcc, WrapperToggle } from './styles';

type Props = {
  mcc: string | number;
  description: string;
  onPressToggleSwitch?(isActive: boolean): Promise<void> | void;
};

const MccRow: FC<Props> = ({ mcc, description, onPressToggleSwitch }) => (
  <Card>
    <RowMcc>
      <MCC>{mcc}</MCC>
    </RowMcc>
    <Row>
      <Description>{description}</Description>
    </Row>
    <WrapperToggle>
      <ToggleSwitch isActive onPress={onPressToggleSwitch} />
    </WrapperToggle>
  </Card>
);

export default MccRow;

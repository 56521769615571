import { FC } from 'react';
import { TypographyType } from '~/utils/types/Variants';
import { Wrapper } from './styles';

const Typography: FC<TypographyType> = ({
  variant = 'xs',
  children,
  ...props
}) => {
  return (
    <Wrapper variant={variant} {...props}>
      {children}
    </Wrapper>
  );
};

export default Typography;

import { FC } from 'react';
import { faSquareInfo } from '~/assets';
import { UploadRoles, UploadStatus } from '~/utils';
import If from '../If';
import {
  Card,
  IconSearch,
  Name,
  Row,
  RowButtonSearch,
  SearchIconBox,
} from './styles';

type Props = {
  name?: string;
  message?: string;
  type?: UploadRoles;
  status?: UploadStatus;
  createdAt?: string;
  onPress?(): void;
  recharge?: FileUploadErrorRecharge;
};

const FileRow: FC<Props> = ({
  name = '',
  message = '',
  recharge,
  onPress = () => {},
}) => {
  return (
    <Card fourItens={!!recharge}>
      <Row>
        <Name>{name}</Name>
      </Row>
      <Row>
        <Name title={message}>{message}</Name>
      </Row>
      <If condition={!!recharge}>
        <RowButtonSearch>
          <SearchIconBox id="onPress_product" onPress={onPress}>
            <IconSearch icon={faSquareInfo} />
          </SearchIconBox>
        </RowButtonSearch>
      </If>
    </Card>
  );
};

export default FileRow;

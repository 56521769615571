import { Typography, getTheme, ifStyle, styled } from '~/modules';

const spacingSm = getTheme('spacing.sm');

const dangerLight = getTheme('danger.light');
const textMain = getTheme('text.main');
const textContrast = getTheme('text.contrast');
const brandPrimaryMain = getTheme('brand.primary.main');

const themeRadiusInput = getTheme('themeRadius.input');

const isDragOver = ifStyle('isDragOver');

type DragProps = {
  isDragOver?: boolean;
};

export const Wrapper = styled.div`
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const DragWrapper = styled.div<DragProps>`
  position: relative;
  width: 100%;
  display: flex;
  gap: ${spacingSm}px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: ${spacingSm}px;
  border-radius: ${themeRadiusInput}px;
  border: 2px dashed ${textMain}30;
  color: ${textMain};
  cursor: pointer;
  font-weight: 500;
  background-color: ${isDragOver(brandPrimaryMain, textContrast)}30;
  transition: 0.2s ease all;
`;

export const CustomLabel = styled(Typography).attrs({
  variant: 'sm',
})`
  font-weight: 500;
  margin: ${spacingSm}px 0;
`;

export const Error = styled(Typography).attrs({
  variant: 'sm',
})`
  color: ${dangerLight};
  margin-top: ${spacingSm}px;
  text-align: left;
`;

export enum NumberPage {
  ADD = 1,
  SUBTRACTION = -1,
  SECOND_PAGE = 2,
}

export enum ListPagination {
  INITIAL_PAGE = 1,
  SIZE = 12,
}

import { Button, Typography } from '~/components';
import { Icon, getTheme, pxToRem, styled } from '~/modules';

const brandPrimaryMain = getTheme('brand.primary.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');
const spacingLg = getTheme('spacing.lg');
const spacingXxl = getTheme('spacing.xxl');
const themeRadiusCard = getTheme('themeRadius.card');

const boxShadowZ3 = getTheme('boxShadow.z3');

export const Wrapper = styled.div`
  width: 100%;
  padding: ${spacingXxl}px;
  background-color: ${brandPrimaryMain};
  border-radius: ${themeRadiusCard}px ${themeRadiusCard}px 0 0;
`;

export const Title = styled(Typography).attrs({ variant: 'max' })`
  font-weight: bold;
  line-height: ${pxToRem(60)};
  color: ${brandPrimaryContrast};
  opacity: 0.8 !important;
`;

export const IconClose = styled(Icon).attrs({ fontSize: pxToRem(24) })``;

export const ButtonClose = styled(Button)`
  border-radius: 50%;
  background-color: ${brandPrimaryContrast};
  color: ${brandPrimaryMain};
  width: ${pxToRem(60)};
  height: ${pxToRem(60)};
  margin-right: ${spacingLg}px;
  ${boxShadowZ3};
`;

import { FC } from 'react';
import { currencyParser } from '@platformbuilders/helpers';
import { LoadingIndicator, isEmpty } from '~/modules';
import { formatShortDate } from '~/utils';
import HeaderModal from '../HeaderModal';
import If from '../If';
import {
  Card,
  Columns,
  Content,
  EmptyDataComponent,
  LoadingBox,
  Row,
  TitleColumn,
  ValueData,
  Wrapper,
  WrapperItemMetaData,
} from './styles';

type Props = {
  isOpen: boolean;
  schedulingList?: ListResponse<Scheduling>;
  handleClose(): void;
  isLoading: boolean;
};

const ModalListScheduling: FC<Props> = ({
  isOpen,
  schedulingList,
  handleClose,
  isLoading,
}) => {
  return (
    <Wrapper isOpen={isOpen} onClose={handleClose}>
      <HeaderModal title="Agendamentos" onPressClose={handleClose} />
      <Content>
        <If condition={isLoading}>
          <LoadingBox>
            <LoadingIndicator />
          </LoadingBox>
        </If>
        <If condition={isEmpty(schedulingList?.content) && !isLoading}>
          <EmptyDataComponent
            title={'Sem agendamentos cadastrados'}
            description={''}
          />
        </If>

        <If condition={!isEmpty(schedulingList?.content) && !isLoading}>
          <Row>
            <Card>
              <Columns>
                <TitleColumn>Data de criação</TitleColumn>
                <TitleColumn>Valor</TitleColumn>
                <TitleColumn>Data de execução</TitleColumn>
              </Columns>
              {schedulingList?.content?.map((date) => {
                return (
                  <WrapperItemMetaData key={date.updatedAt}>
                    <ValueData>
                      {formatShortDate(new Date(date.updatedAt || ''))}
                    </ValueData>
                    <ValueData>{currencyParser(date.amount || '')}</ValueData>
                    <ValueData>
                      {formatShortDate(new Date(date.executionOnDate || ''))}
                    </ValueData>
                  </WrapperItemMetaData>
                );
              })}
            </Card>
          </Row>
        </If>
      </Content>
    </Wrapper>
  );
};

export default ModalListScheduling;

import { getTheme, ifStyle, pxToRem, styled } from '~/modules';
import Button from '../Button';
import Touchable from '../Touchable';
import Typography from '../Typography';

const spacingMd = getTheme('spacing.md');

const brandPrimaryMain = getTheme('brand.primary.main');
const backgroundReverseZ2 = getTheme('background.reverse.z2');
const backgroundZ4 = getTheme('background.z4');
const brandPrimaryContrast = getTheme('background.primary.contrast');
const boxShadowPrimaryGlow = getTheme('boxShadow.primary glow');
const textMain = getTheme('text.main');

const borderWidthMin = getTheme('borderWidth.min');

const isActive = ifStyle('active');

type Props = {
  active: boolean;
};

export const Wrapper = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const Heading = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: ${spacingMd}px;
  border-bottom: ${borderWidthMin}px solid ${textMain}30;
`;

export const Content = styled.div`
  flex: 1;
  overflow: scroll;
`;

export const ButtonComponent = styled(Button)`
  width: ${pxToRem(133)};
  height: ${pxToRem(51)};
`;

export const Item = styled(Touchable)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
`;

export const Rounded = styled.div<Props>`
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
  background-color: ${isActive(brandPrimaryMain, backgroundReverseZ2)};
  color: ${isActive(brandPrimaryContrast, backgroundZ4)};
  ${boxShadowPrimaryGlow}
`;

export const Label = styled(Typography).attrs({
  variant: 'xxs',
})`
  font-weight: 500;
  text-align: center;
`;

export const Line = styled.div<Props>`
  position: absolute;
  right: -50%;
  width: 100%;
  opacity: 0.5;
  height: ${pxToRem(2.5)};
  top: ${pxToRem(15)};
  background-color: ${isActive(brandPrimaryMain, backgroundReverseZ2)};
`;

export const ProgressItem = styled.div`
  flex: 1;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const ProgressBar = styled.div`
  justify-content: space-between;
  width: 70%;
  align-items: top;
  margin: 0 ${spacingMd}px;
`;

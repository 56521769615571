import { FC } from 'react';
import { FooterButtons } from '~/components';
import { FormikProps, useFormikContext } from '~/modules';
import { StepsCompanyRegister, formatPhone } from '~/utils';
import { Props } from '..';
import { Content, Input, TitlePageComponent, WrapperContent } from '../styles';

const Contact: FC<Props> = ({ onNavigateId }) => {
  const {
    values,
    errors,
    touched,
    isSubmitting,
    setFieldValue,
  }: FormikProps<CompanyRegister> = useFormikContext();

  return (
    <WrapperContent>
      <Content>
        <TitlePageComponent>Dados de contato</TitlePageComponent>
        <Input
          label="Telefone"
          placeholder="(00) 00000-0000"
          id="phone"
          name="phone"
          error={touched.phone && errors.phone}
          value={values.phone}
          onChange={({ target: { name, value } }) =>
            setFieldValue(name, formatPhone(value))
          }
        />
        <Input
          label="E-mail"
          placeholder="empresa@dominio.com"
          id="mail"
          name="mail"
          error={touched.mail && errors.mail}
          value={values.mail}
          onChange={({ target: { name, value } }) =>
            setFieldValue(name, value.toLowerCase())
          }
        />
        <Input
          label="Website"
          placeholder="www.empresa.com"
          id="website"
          name="website"
          error={touched.website && errors.website}
          value={values.website}
          onChange={({ target: { name, value } }) =>
            setFieldValue(name, value.toLowerCase())
          }
        />
      </Content>
      <FooterButtons
        firstBtn={{
          id: 'btn_clear',
          label: 'Voltar',
          disabled: isSubmitting,
          onPress: () => onNavigateId(StepsCompanyRegister.DATA),
        }}
        secondaryBtn={{
          id: 'btn_confirm',
          label: 'Próximo',
          onPress: () => onNavigateId(StepsCompanyRegister.ADDRESS),
        }}
      />
    </WrapperContent>
  );
};

export default Contact;

import { FC } from 'react';
import { faTriangleExclamation } from '~/assets';
import { Routes } from '~/routes';
import {
  ButtonLogin,
  Content,
  Description,
  IconExclamation,
  RoundedWrapper,
  Title,
  Wrapper,
} from './styles';

type Props = {
  onNavigate(route: Routes): void;
};

const UserActivationError: FC<Props> = ({ onNavigate }) => (
  <Wrapper>
    <Content>
      <RoundedWrapper>
        <IconExclamation icon={faTriangleExclamation} />
      </RoundedWrapper>

      <Title>Erro de ativação do Token</Title>
      <Description>
        Houve um erro durante a ativação do seu usuário. Caso já tenha sido
        ativado, faça login novamente.
      </Description>

      <ButtonLogin
        id="go_login"
        onPress={() => onNavigate(Routes.LOGIN)}
        secondary
      >
        Fazer Login
      </ButtonLogin>
    </Content>
  </Wrapper>
);

export default UserActivationError;

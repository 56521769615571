import { FC } from 'react';
import { FormBehavior } from '~/modules';
import ModalUploadFile from './ModalUploadFile';
import { initialValues, validationSchema } from './schema';

type Props = {
  isOpen: boolean;
  handleClose(): void;
  handleFormSubmit(values: UploadFile): void;
};

const ModalUploadFileContainer: FC<Props> = ({
  isOpen,
  handleClose,
  handleFormSubmit,
}) => (
  <FormBehavior
    validationSchema={validationSchema}
    initialValues={initialValues}
    onSubmit={handleFormSubmit}
  >
    <ModalUploadFile isOpen={isOpen} handleClose={handleClose} />
  </FormBehavior>
);

export default ModalUploadFileContainer;

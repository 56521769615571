import { Icon, getTheme, pxToRem, styled } from '~/modules';
import Typography from '../Typography';

const spacingLg = getTheme('spacing.lg');

const brandSecondaryMain = getTheme('brand.secondary.main');
const brandSecondaryContrast = getTheme('brand.secondary.contrast');

export const Wrapper = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled(Typography).attrs({ variant: 'md' })`
  font-weight: bold;
  opacity: 0.8 !important;
  margin: ${spacingLg}px 0;
`;

export const Description = styled(Typography).attrs({ variant: 'lg' })`
  opacity: 0.8 !important;
`;

export const WrapperRounded = styled.div`
  width: ${pxToRem(95)};
  height: ${pxToRem(95)};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: ${brandSecondaryMain};
`;

export const IconComponent = styled(Icon).attrs({
  fontSize: pxToRem(30),
})`
  color: ${brandSecondaryContrast};
`;

import { FC, useState } from 'react';
import { faPlus } from '~/assets';
import {
  FooterButtons,
  ModalConfirmation,
  PaginatedList,
  TitlePage,
} from '~/components';
import { FormikProps, isEmpty, useFormikContext } from '~/modules';
import {
  MetadataColumns,
  StepsRegisterProduct,
  timestampNow,
  useStores,
} from '~/utils';
import MetaDataItem from './MetaDataItem';
import { ButtonField, Content, Description, IconPlus, Wrapper } from './styles';

type Props = {
  onNavigateId(id: number): void;
};

const Metadata: FC<Props> = ({ onNavigateId }) => {
  const {
    values,
    isSubmitting,
    setFieldValue,
    submitForm,
    validateForm,
  }: FormikProps<RegisterProduct | SideAccountRegister> = useFormikContext();

  const {
    utils: { currentProgressId },
  } = useStores();

  const [onConfirmation, setOnConfirmation] = useState(false);

  const handleOnConfirmation = () =>
    setOnConfirmation((onConfirmation) => !onConfirmation);

  const handleAdd = (metadata: MetaData) => {
    const metadatas: MetaData[] = [];
    metadatas.push(metadata);
    values.metadata?.map((item) => {
      item.id !== metadata.id && metadatas.push(item);
    });

    setFieldValue('metadata', metadatas);
  };

  const handleRemove = (id?: number) => {
    const metadatas: MetaData[] = [];
    if (values.metadata?.length === 1) {
      metadatas.push({
        value: '',
        key: '',
        id: timestampNow(),
      });
    } else {
      values.metadata?.map((item) => {
        item.id !== id && metadatas.push(item);
      });
    }

    setFieldValue('metadata', metadatas);
  };

  const handleNewField = () => {
    const id = timestampNow();
    values.metadata &&
      values.metadata[0]?.key !== '' &&
      handleAdd({
        value: '',
        key: '',
        id,
      });
  };

  const handleFormValidate = async () => {
    const errors = await validateForm(values);

    if (isEmpty(errors)) {
      handleOnConfirmation();
    } else {
      await submitForm();
      onNavigateId(StepsRegisterProduct.DATA);
    }
  };

  const handleSubmitForm = async () => {
    const metadatas: MetaData[] = [];
    values.metadata?.map((item) => {
      item.key !== '' && metadatas.push(item);
    });
    setFieldValue('metadata', metadatas);
    await submitForm();
    handleOnConfirmation();
  };

  return (
    <Wrapper>
      <Content>
        <TitlePage>Metadata</TitlePage>
        <Description>
          Em Metadados conseguimos registrar informações complementares. Caso
          necessário, adicione campos usando a seção abaixo.
        </Description>
        <ButtonField
          id="add_field"
          disabled={isSubmitting}
          onPress={handleNewField}
        >
          <IconPlus icon={faPlus} />
          Adicionar Campo
        </ButtonField>
        <PaginatedList
          columns={MetadataColumns}
          list={values.metadata}
          showPagination={false}
          renderList={(item) => (
            <MetaDataItem
              key={`${item.key}_${item.id}`}
              metadata={item}
              onChange={handleAdd}
              onRemove={handleRemove}
            />
          )}
          titleEmptyData="Sem Metadata"
        />
      </Content>
      <FooterButtons
        firstBtn={{
          id: 'btn_previous',
          label: 'Voltar',
          disabled: isSubmitting,
          onPress: () => onNavigateId(currentProgressId - 1),
        }}
        secondaryBtn={{
          id: 'btn_confirm',
          label: 'Finalizar',
          onPress: () => handleFormValidate(),
        }}
      />
      <ModalConfirmation
        isOpen={onConfirmation}
        description="Clique em sim para concluir o cadastro"
        onCancel={handleOnConfirmation}
        onConfirm={handleSubmitForm}
      />
    </Wrapper>
  );
};

export default Metadata;

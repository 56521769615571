import { FC, useState } from 'react';
import { ModalListMetaData } from '~/components';
import { observer } from '~/modules';
import { SectionsInfoAll, useStores } from '~/utils';
import UploadsDetails from './UploadsDetails';

export const UploadsDetailsContainer: FC = () => {
  const {
    uploads: {
      currentFile,
      getCurrentFileMetadata,
      getCurrentFileExtraInfo,
      setCurrentFileError,
    },
  } = useStores();

  const [isFileLoading] = useState(false);

  const [onModalInfos, setOnModalInfos] = useState(false);

  const handleModalInfos = () => {
    setOnModalInfos((onModalInfos) => !onModalInfos);
  };

  const handleSetErrorInfo = (error: FileUploadError) => {
    handleModalInfos();
    setCurrentFileError(error);
  };

  return (
    <>
      <UploadsDetails
        file={currentFile}
        details={currentFile.errors}
        isFileLoading={isFileLoading}
        onPressSeeAll={handleSetErrorInfo}
      />
      <ModalListMetaData
        onSections
        isOpen={onModalInfos}
        metaDataList={getCurrentFileMetadata}
        handleClose={handleModalInfos}
        hideMccInfo
        activeTab={SectionsInfoAll.EXTRA_INFOS}
        extraInfoList={getCurrentFileExtraInfo}
        fullWidth
      />
    </>
  );
};

export default observer(UploadsDetailsContainer);

import { FC, ReactNode } from 'react';
import store from '~/stores';
import { UserRoles } from '~/utils';
import If from '../If';
import { Title, WrapperOptions } from './styles';

type Props = {
  userLogged?: User;
  condition?: boolean;
  children: ReactNode;
};

const OptionsList: FC<Props> = ({
  userLogged = store.user.user,
  condition = true,
  children,
}) => (
  <If condition={userLogged.role === UserRoles.ADMIN && condition}>
    <Title>Opções</Title>
    <WrapperOptions>{children}</WrapperOptions>
  </If>
);

export default OptionsList;

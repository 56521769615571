import { FC } from 'react';
import { PaginatedList, ProductRow, TitlePage } from '~/components';
import { getFirstAndLastName, productsColumns } from '~/utils';
import { Content, Description, Wrapper } from './styles';

type Props = {
  permissionProductsList: ListResponse<PermissionProduct>;
  isLoading: boolean;
  userSelected: User;
  onChangePage(page: number): void;
  onSetIsAllowed(values: SetPermissionProductsProps): void;
};

const ProductsPermission: FC<Props> = ({
  permissionProductsList,
  isLoading,
  userSelected,
  onChangePage,
  onSetIsAllowed,
}) => {
  const { content, paging } = permissionProductsList;

  return (
    <Wrapper titlePage="Permissões de produtos">
      <Content>
        <TitlePage totalElements={paging?.totalElements}>
          Permissões de produtos
        </TitlePage>
        <Description>
          Defina os produtos que o usuário{' '}
          <b>{getFirstAndLastName(userSelected?.name)}</b> pode ter acesso.
        </Description>

        <PaginatedList
          columns={productsColumns}
          list={content}
          totalPages={paging?.totalPages}
          currentPage={paging?.page}
          isLoading={isLoading}
          onChangePage={onChangePage}
          renderList={({ userId, productId, ...product }) => (
            <ProductRow
              description={product?.description}
              name={product?.name}
              status={product?.status}
              type={product?.type}
              isAllowed={product.isAllowed}
              key={`${product?.name}` + `${product?.description}`}
              onPressToggleSwitch={(isActive) =>
                onSetIsAllowed({
                  isAllowed: isActive,
                  userId,
                  productId,
                  page: paging?.page,
                })
              }
            />
          )}
          titleEmptyData="Sem produtos disponíveis"
        />
      </Content>
    </Wrapper>
  );
};

export default ProductsPermission;

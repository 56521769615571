import { Krypton } from '~/modules';
import { ResponseError } from '~/utils';
import request from '../request';

export const registerUser = async (values: RegisterUser): Promise<void> => {
  try {
    await request.post(`/users`, values);
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const changePassword = async (
  { currentPassword, newPassword, newPasswordConfirmation }: ChangePassword,
  publicKey: string,
): Promise<void> => {
  try {
    await request.patch('/users/me/passwords/reset', {
      currentPassword: Krypton.encrypt(currentPassword, publicKey),
      newPassword: Krypton.encrypt(newPassword, publicKey),
      newPasswordConfirmation: Krypton.encrypt(
        newPasswordConfirmation,
        publicKey,
      ),
    });
  } catch (error) {
    throw new ResponseError(error);
  }
};

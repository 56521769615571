import { FormValidator } from '~/modules';

export const validationSchema = FormValidator.object().shape({
  file: FormValidator.mixed().required('Campo obrigatório'),
  type: FormValidator.string().required('Campo obrigatório'),
});

export const initialValues: UploadFile = {
  file: '',
  type: '',
};

import { FC } from 'react';
import { FooterButtons } from '~/components';
import { FormikProps, observer, useFormikContext } from '~/modules';
import { ClientsStore } from '~/stores';
import { PersonType, StepsSideAccount, maskCpfOrCnpj } from '~/utils';
import { Content, Input, TitlePageComponent, Wrapper } from './styles';

type Props = {
  clientStore: ClientsStore;
  onNavigateId(id: number): void;
};

const UpdateLimit: FC<Props> = ({ clientStore, onNavigateId }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    resetForm,
  }: FormikProps<UpdateLimit> = useFormikContext();

  const handleClear = async () => {
    resetForm();
  };

  const isClientPF = clientStore.currentClient?.personType === PersonType.PF;

  return (
    <Wrapper>
      <Content>
        <TitlePageComponent>Insira os dados</TitlePageComponent>

        <Input
          label={isClientPF ? 'CPF' : 'CNPJ'}
          placeholder={isClientPF ? '000.000.000-00' : '00.000.000/0000-00'}
          id="document"
          name="document"
          disabled
          value={maskCpfOrCnpj(clientStore.currentClient?.document)}
          onChange={handleChange}
        />
        <Input
          label="Valor"
          placeholder="R$ 123,45"
          id="amount"
          name="amount"
          value={values?.amount}
          error={touched?.amount && errors?.amount}
          onChange={handleChange}
          maskType="currency"
        />
      </Content>
      <FooterButtons
        firstBtn={{
          id: 'btn_clear',
          label: 'Limpar',
          onPress: () => handleClear(),
        }}
        secondaryBtn={{
          id: 'btn_confirm',
          label: 'Próximo',
          onPress: () => onNavigateId(StepsSideAccount.META_DATA),
        }}
      />
    </Wrapper>
  );
};

export default observer(UpdateLimit);

import { FormValidator, isValidCpf, isValidEmail } from '~/modules';
import { validateName } from '~/utils';

export const validationSchema = FormValidator.object().shape({
  name: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Nome inválido', validateName),
  role: FormValidator.string().required('Campo obrigatório'),
  document: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'CPF inválido', isValidCpf),
  email: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Email inválido', isValidEmail),
});

export const getInitialValues = (values: User): EditUser => {
  return {
    id: values.id,
    name: values.name,
    document: values.document,
    email: values.email,
    role: values.role,
  };
};

import { FC, useEffect, useState } from 'react';
import { ModalRegisterUser } from '~/components';
import { observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import { AlertMessages, UserRoles, showAlert, useStores } from '~/utils';
import Users from './Users';

export const UsersContainer: FC = () => {
  const navigate = useNavigate();
  const {
    user: { user, registerUser },
    users: { usersList, getUsers, setUserSelected },
  } = useStores();

  const [onRegisterUserModal, setOnRegisterUserModal] = useState(false);
  const [isUsersLoading, setIsUsersLoading] = useState(true);

  const handleRegisterUserModal = () =>
    setOnRegisterUserModal((onRegisterUserModal) => !onRegisterUserModal);

  const handleRegisterUser = async (values: RegisterUser) => {
    await registerUser(values);
    handleRegisterUserModal();
    showAlert({
      message: AlertMessages.SUCCESS_REGISTER_USER,
      type: 'success',
    });
  };

  const onGetUsers = async (values: GetUsersProps) => {
    setIsUsersLoading(true);
    try {
      await getUsers(values);
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setIsUsersLoading(false);
    }
  };

  const handlePressUser = async (values: User) => {
    setUserSelected(values);
    navigate(Routes.USER_DETAILS);
  };

  useEffect(() => {
    onGetUsers({});
  }, []);

  return (
    <>
      <Users
        isUsersLoading={isUsersLoading}
        usersList={usersList}
        isUserEspectator={user.role === UserRoles.SPECTATOR}
        onPressRegisterUser={handleRegisterUserModal}
        onPressUser={handlePressUser}
        onPressFilter={(name) => onGetUsers({ name })}
        onChangePage={(page) => onGetUsers({ page })}
      />
      <ModalRegisterUser
        isOpen={onRegisterUserModal}
        handleClose={handleRegisterUserModal}
        handleFormSubmit={handleRegisterUser}
      />
    </>
  );
};

export default observer(UsersContainer);

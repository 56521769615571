import { Modal } from '~/components';
import { getTheme, pxToRem, styled } from '~/modules';
import { mqDevices } from '~/utils';

const spacingXxl = getTheme('spacing.xxl');
const spacingMd = getTheme('spacing.md');

const backgroundZ3 = getTheme('background.z3');
const boxShadowZ4 = getTheme('boxShadow.z4');

export const Wrapper = styled(Modal)`
  flex-direction: column;
  width: ${pxToRem(1200)};
  height: 95%;
  max-height: 95%;
  max-width: 90%;
  background-color: ${backgroundZ3};
  ${boxShadowZ4};
`;

export const Content = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: ${spacingXxl}px;

  @media ${mqDevices.inMobileAndTablet} {
    padding: ${spacingMd}px;
  }
`;

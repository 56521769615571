import { CharactersLength } from '../enums';

export const validateName = (text?: string) => {
  const regex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/;
  return !!text && regex.test(text) && text.length <= CharactersLength.USER_MAX;
};

export const validateNameProduct = (text?: string) => {
  const regex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ0-9- ]+$/;
  return !!text && regex.test(text) && text.length <= CharactersLength.USER_MAX;
};

export const validateMaxLength = (text?: string) => {
  return !!text && text.length <= CharactersLength.USER_MAX;
};

export const validateMinLength = (text?: string) => {
  return !!text && text.length >= CharactersLength.USER_MIN;
};

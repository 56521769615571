import { FC, useEffect, useMemo, useState } from 'react';
import {
  If,
  ModalConfirmation,
  ModalListMetaData,
  ModalListScheduling,
  ModalTransactionDetails,
} from '~/components';
import { isEmpty, observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import {
  AlertMessages,
  EventOptionsAccount,
  ListPagination,
  formatGroupDate,
  formatToApiDate,
  showAlert,
  useStores,
} from '~/utils';
import AccountDetails from './AccountDetails';

type GetTransactions = Omit<GetTransactionsProps, 'accountId'>;

const AccountDetailsContainer: FC = () => {
  const {
    clients: { selectedAccount, setStatusAccount },
    transactions: {
      schedulingList,
      transactionsList,
      transaction,
      getAccountTransactions,
      getTransactionDetails,
      getAccountScheduling,
      setAccountTransactions,
      cancelTransaction,
    },
  } = useStores();
  const navigate = useNavigate();

  const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);
  const [isSchedulingLoading, setIsSchedulingLoading] = useState(true);
  const [selectedStartDate, setSelectedStartDate] = useState<CalendarDay>();
  const [selectedEndDate, setSelectedEndDate] = useState<CalendarDay>();
  const [textModalConfirmation, setTextModalConfirmation] =
    useState<AlertMessages>('' as AlertMessages);
  const [optionSelected, setOptionSelected] = useState<EventOptionsAccount>();
  const [isOpenListMetaData, setIsOpenListMetaData] = useState(false);
  const [isOpenListScheduling, setIsOpenListScheduling] = useState(false);
  const [isOpenTransactionDetail, setIsOpenTransactionDetail] = useState(false);
  const [onConfirmation, setOnConfirmation] = useState(false);

  const handleOpenListMetaData = () => {
    setIsOpenListMetaData((isOpenListMetaData) => !isOpenListMetaData);
  };

  const handleOpenScheduling = async () => {
    try {
      setIsOpenListScheduling((isOpenListScheduling) => !isOpenListScheduling);
      setIsSchedulingLoading(true);
      if (!isOpenListScheduling) {
        await getAccountScheduling({ accountId: selectedAccount.id });
      }
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setIsSchedulingLoading(false);
    }
  };

  const onPressAddBalance = () => {
    navigate(Routes.ADD_BALANCE);
  };

  const onPressUpdateLimit = () => {
    navigate(Routes.UPDATE_MILIT);
  };

  const handleOpenTransactionDetail = () => {
    setIsOpenTransactionDetail(
      (isOpenTransactionDetail) => !isOpenTransactionDetail,
    );
  };

  const handleOnPressTransaction = async (transactionId: string) => {
    try {
      await getTransactionDetails({
        accountId: selectedAccount.id,
        transactionId,
      });
      handleOpenTransactionDetail();
    } catch ({ message }) {
      showAlert({ message });
    }
  };

  const formatStartEndDate = () => {
    let startDate = '';
    let endDate = '';
    if (selectedStartDate) {
      startDate = formatToApiDate(selectedStartDate.dayDate.toDate());
      if (selectedEndDate) {
        endDate = formatToApiDate(selectedEndDate.dayDate.toDate());
      }
    }
    return [startDate, endDate];
  };

  const getTransactions = async ({
    page,
    startDate,
    endDate,
    transactionType,
  }: GetTransactions) => {
    setIsTransactionsLoading(true);
    try {
      const [formattedStartDate, formattedEndDate] = formatStartEndDate();
      await getAccountTransactions({
        page,
        transactionType,
        accountId: selectedAccount.id,
        endDate: endDate ?? formattedEndDate,
        startDate: startDate ?? formattedStartDate,
      });
    } catch ({ message }) {
      showAlert({ message });
      setAccountTransactions({});
    } finally {
      setIsTransactionsLoading(false);
    }
  };

  const onSearch = async (startDate?: string, endDate?: string) => {
    await getTransactions({
      page: ListPagination.INITIAL_PAGE,
      endDate: endDate ?? undefined,
      startDate: startDate ?? undefined,
    });
  };

  const onClearFilter = async () => {
    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);
    await getTransactions({
      startDate: '',
      endDate: '',
      page: ListPagination.INITIAL_PAGE,
    });
  };

  const handleOnpressOption = (
    option: EventOptionsAccount,
    text: AlertMessages,
  ) => {
    setOptionSelected(option);
    setTextModalConfirmation(text);
  };

  const handleOptionSelected = async () => {
    const message = await setStatusAccount(selectedAccount.id, optionSelected);
    setTextModalConfirmation('' as AlertMessages);
    showAlert({ message, type: 'success' });
  };

  const onCancelTransaction = async () => {
    setOnConfirmation(true);
  };

  const confirmCancelTransaction = async () => {
    try {
      await cancelTransaction({ transactionId: transaction.transactionId });
      showAlert({
        message: AlertMessages.SUCCESS_CANCEL_TRANSACTION,
        type: 'success',
      });
    } catch (error) {
      showAlert({
        message: AlertMessages.FAIL_CANCEL_TRANSACTION,
      });
    } finally {
      handleOpenTransactionDetail();
      setOnConfirmation(false);
      getTransactions({});
    }
  };

  const transactionsGroups: GroupDateProps<Transaction>[] = useMemo(() => {
    return formatGroupDate({
      content: transactionsList.content,
      renderPeriodValue: ({ createdAt }) => createdAt,
    });
  }, [transactionsList.content]);

  useEffect(() => {
    if (!isEmpty(selectedAccount)) {
      getTransactions({});
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (isEmpty(selectedAccount)) {
      navigate(Routes.CLIENT_ACCOUNTS);
    }
  }, [selectedAccount]);

  return (
    <>
      <AccountDetails
        account={selectedAccount}
        isTransactionsLoading={isTransactionsLoading}
        transactionsGroups={transactionsGroups}
        listPaging={transactionsList.paging}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        onPressAddBalance={onPressAddBalance}
        onPressUpdateLimit={onPressUpdateLimit}
        onPressTransaction={handleOnPressTransaction}
        onPressAditionalInfo={handleOpenListMetaData}
        onPressScheduling={handleOpenScheduling}
        onPressOption={handleOnpressOption}
        onChangePage={(page) => getTransactions({ page })}
        onSelectEndDate={setSelectedEndDate}
        onSelectStartDate={setSelectedStartDate}
        onClearFilter={onClearFilter}
        onSearch={onSearch}
      />
      <ModalListMetaData
        isOpen={isOpenListMetaData}
        metaDataList={selectedAccount?.metadata}
        handleClose={handleOpenListMetaData}
      />
      <ModalListScheduling
        isOpen={isOpenListScheduling}
        schedulingList={schedulingList}
        handleClose={handleOpenScheduling}
        isLoading={isSchedulingLoading}
      />
      <If condition={!isEmpty(transaction)}>
        <ModalTransactionDetails
          isOpen={isOpenTransactionDetail}
          transactionDetail={transaction}
          handleClose={handleOpenTransactionDetail}
          onCancelTransaction={onCancelTransaction}
        />
      </If>
      <ModalConfirmation
        isOpen={!isEmpty(textModalConfirmation)}
        description={textModalConfirmation}
        onConfirm={handleOptionSelected}
        onCancel={() => setTextModalConfirmation('' as AlertMessages)}
      />
      <ModalConfirmation
        isOpen={onConfirmation}
        description={`Deseja realmente cancelar essa transação?`}
        onConfirm={confirmCancelTransaction}
        onCancel={() => setOnConfirmation(false)}
      />
    </>
  );
};

export default observer(AccountDetailsContainer);

import { Button, SceneWrapper, Select, Typography } from '~/components';
import { Icon, getTheme, ifStyle, pxToRem, styled } from '~/modules';

const backgroundZ4 = getTheme('background.z4');
const boxShadowZ3 = getTheme('boxShadow.z3');
const dangerContrast = getTheme('danger.contrast');
const successContrast = getTheme('success.contrast');
const infoContrast = getTheme('info.contrast');
const dangerMain = getTheme('danger.main');
const successMain = getTheme('success.main');
const infoMain = getTheme('info.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');
const brandPrimaryMain = getTheme('brand.primary.main');
const borderRadiusMax = getTheme('borderRadius.max');
const themeRadiusCard = getTheme('themeRadius.card');
const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingXl = getTheme('spacing.xl');
const spacingXxl = getTheme('spacing.xxl');
const textMain = getTheme('text.main');

const isDanger = ifStyle('danger');
const isInfo = ifStyle('info');
const isSuccess = ifStyle('success');

export const Wrapper = styled(SceneWrapper)`
  flex: 1;
`;

export const ContentBox = styled.div`
  flex: 1;
  flex-direction: column;
  padding: ${spacingXxl}px 0;
`;

export const Title = styled(Typography).attrs({ variant: 'md' })`
  font-weight: bold;
  margin-bottom: ${spacingXl}px;
`;

export const TopBox = styled.div`
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const CardsBox = styled.div`
  margin-bottom: ${spacingXl}px;
  gap: ${spacingMd}px;
`;

export const Card = styled.div`
  flex-direction: column;
  height: fit-content;
  padding: ${spacingMd}px;
  background: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;
  ${boxShadowZ3};
`;

export const CardLabel = styled(Typography).attrs({ variant: 'sm' })``;

export const CardValue = styled(Typography).attrs({ variant: 'md' })`
  font-weight: bold;
  overflow: scroll;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

type StatusCardProps = {
  danger?: boolean;
  info?: boolean;
  success?: boolean;
};

export const StatusCard = styled(Card)<StatusCardProps>`
  background: ${isDanger(
    dangerContrast,
    isSuccess(successContrast, isInfo(infoContrast)),
  )};
`;

export const StatusCardLabel = styled(CardLabel)<StatusCardProps>`
  color: ${isDanger(dangerMain, isSuccess(successMain, isInfo(infoMain)))};
`;

export const StatusCardValue = styled(CardValue)<StatusCardProps>`
  color: ${isDanger(dangerMain, isSuccess(successMain, isInfo(infoMain)))};
`;

export const ButtonInfo = styled(Button)`
  min-width: ${pxToRem(270)};
  height: ${pxToRem(60)};
  padding: 0 ${spacingSm}px;
  border-radius: ${borderRadiusMax}px;
`;

export const IconPlus = styled(Icon)`
  font-size: ${pxToRem(17)};
  margin-right: ${spacingMd}px;
`;

export const WrapperOptions = styled.div`
  margin-bottom: ${spacingXl}px;
`;

export const OptionButton = styled(Button)`
  width: ${pxToRem(280)};
  height: ${pxToRem(60)};
  border-radius: ${borderRadiusMax}px;
  margin-right: ${spacingSm}px;
`;

export const IconOption = styled(Icon).attrs({
  fontSize: pxToRem(20),
})`
  margin-right: ${spacingMd}px;
`;

export const WrapperFilters = styled.div``;

export const SelectInputComponent = styled(Select).attrs<any>((props) => ({
  styles: {
    option: {
      backgroundColor: 'transparent',
      borderRadius: themeRadiusCard(props),
      '&:hover': {
        backgroundColor: brandPrimaryMain(props),
        color: brandPrimaryContrast(props),
        borderRadius: 4,
      },
    },
    menu: {
      borderRadius: themeRadiusCard(props),
      backgroundColor: backgroundZ4(props),
    },
    control: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      '&:active': {
        borderColor: 'transparent',
        borderRadius: themeRadiusCard(props),
      },
      '&:hover': {
        borderColor: 'transparent',
        borderRadius: themeRadiusCard(props),
      },
      '&:focus': {
        borderColor: 'transparent',
        borderRadius: themeRadiusCard(props),
      },
    },
    menuList: {
      borderRadius: themeRadiusCard(props),
    },
  },
}))`
  margin-left: ${spacingMd}px;
  width: ${pxToRem(400)};
  height: ${pxToRem(54)};
  justify-content: center;
  padding: 0 ${spacingMd}px;
  font-weight: bold;
  background-color: transparent;
  color: ${textMain};
  border-radius: ${borderRadiusMax}px;
  border: 1px solid ${textMain};

  * {
    cursor: pointer;
  }
`;

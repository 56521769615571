import { FormValidator } from '~/modules';
import {
  timestampNow,
  validateMaxLength,
  validateMinLength,
  validateNameProduct,
} from '~/utils';

export const validationSchema = FormValidator.object().shape({
  name: FormValidator.string()
    .required('Campo obrigatório')
    .test('valid', 'Nome inválido', validateNameProduct)
    .test(
      'valid',
      'Tamanho inválido, inferior a 3 caracteres',
      validateMinLength,
    ),
  description: FormValidator.string()
    .required('Campo obrigatório')
    .test(
      'valid',
      'Tamanho inválido, superior a 254 caracteres',
      validateMaxLength,
    )
    .test(
      'valid',
      'Tamanho inválido, inferior a 3 caracteres',
      validateMinLength,
    ),
  type: FormValidator.string().required('Campo obrigatório'),
});

export const initialValues: RegisterProduct = {
  name: '',
  description: '',
  type: '',
  merchantCategoryCodes: [],
  internalProductId: '',
  metadata: [{ key: '', value: '', id: timestampNow() }],
};

import { FormValidator } from '~/modules';
import {
  CharactersLength,
  validatePasswordComplexity,
  validatePasswordLength,
} from '~/utils';

export const validationSchema = FormValidator.object().shape({
  currentPassword: FormValidator.string().required('Campo obrigatório'),
  newPassword: FormValidator.string()
    .required('Campo obrigatório')
    .test(
      'length',
      `Mínimo ${CharactersLength.PASSWORD_MIN} caracteres`,
      validatePasswordLength,
    )
    .test(
      'length',
      'Deve conter letra maiúscula, minúscula, número e caracteres especiais',
      validatePasswordComplexity,
    ),
  newPasswordConfirmation: FormValidator.string()
    .required('Campo obrigatório')
    .equals(
      [FormValidator.ref('newPassword')],
      'Deve ser igual a senha digitada acima',
    ),
});

export const initialValuesNewPassword: ChangePassword = {
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

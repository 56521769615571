import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const { NODE_ENV, REACT_APP_SENTRY_DSN } = process.env;
export default class SentryService {
  static init(): void {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      environment: NODE_ENV,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }

  static async setUser(user: User): Promise<void> {
    Sentry.setUser({
      email: user.email,
    });
  }
}

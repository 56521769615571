import { getTheme, pxToRem, styled } from '~/modules';
import { mqDevices } from '~/utils';
import EmptyData from '../EmptyData';
import Modal from '../Modal';
import Typography from '../Typography';

const backgroundZ3 = getTheme('background.z3');
const backgroundZ2 = getTheme('background.z2');

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXl = getTheme('spacing.xl');
const spacingXxl = getTheme('spacing.xxl');

const themeRadiusCard = getTheme('themeRadius.card');

export const Wrapper = styled(Modal)`
  flex-direction: column;
  width: ${pxToRem(959)};
  max-width: 90%;
  height: ${pxToRem(772)};
  max-height: 95vh;

  @media ${mqDevices.inMobileAndTablet} {
    height: 85vh;
  }
`;

export const EmptyDataComponent = styled(EmptyData)``;

export const LoadingBox = styled.div`
  padding-top: ${spacingLg}px;
  padding-bottom: ${spacingXxl}px;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;
  padding: ${spacingXl}px;
`;

export const Row = styled.div`
  width: 100%;

  @media ${mqDevices.inMobileAndTablet} {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  overflow-y: auto;
  flex-direction: column;
  width: 100%;
  max-height: ${pxToRem(460)};
  padding: ${spacingMd}px;
  border-radius: ${themeRadiusCard}px;
  background-color: ${backgroundZ3};
  margin: ${spacingSm}px;

  @media ${mqDevices.inMobileAndTablet} {
    width: 100%;
  }
`;

export const Columns = styled.div`
  width: 100%;
  justify-content: space-between;
  margin-bottom: ${spacingMd}px;
  padding: 0 ${spacingLg}px;
`;

export const TitleColumn = styled(Typography).attrs({
  variant: 'sm',
})`
  flex: 1;
  font-weight: 500;
`;

export const WrapperItemMetaData = styled.div`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  min-height: ${pxToRem(96)};
  padding: 0 ${spacingLg}px;
  background-color: ${backgroundZ2};
  border-radius: ${themeRadiusCard}px;
  margin-bottom: ${spacingMd}px;
`;

export const ValueData = styled(Typography)`
  flex: 1;
`;

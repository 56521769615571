import { DateManager } from '~/modules';
import { formatToApiDate } from './dateFormatter';

export const generateDateObject = (value: string) => {
  return {
    dayNumber: DateManager(value).date(),
    monthNumber: DateManager(value).month() + 1,
    dayDate: DateManager(value),
  };
};

export const formatPaymentTypeToSelectValue = (
  startDate: CalendarDay | undefined,
  endDate: CalendarDay | undefined,
) => {
  return [
    formatToApiDate(startDate?.dayDate.toDate() || new Date()),
    formatToApiDate(endDate?.dayDate.toDate() || new Date()),
  ];
};

import { FC, ReactNode } from 'react';
import { Wrapper } from './styles';

type Props = {
  children: ReactNode;
};

const ProductRegister: FC<Props> = ({ children }) => (
  <Wrapper titlePage="Cadastrar Produto" hiddenBorder offPaddingContent>
    {children}
  </Wrapper>
);

export default ProductRegister;

export enum PersonType {
  PJ = 'PJ',
  PF = 'PF',
}

export enum AddressStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum AccountType {
  DEFAULT = 'DEFAULT',
  CONTROL_CASH_IN = 'CONTROL_CASH_IN',
  CONTROL_CASH_OUT = 'CONTROL_CASH_OUT',
}

export enum AccountTypePt {
  DEFAULT = 'Padrão',
  CONTROL_CASH_IN = 'Controle de Entrada',
  CONTROL_CASH_OUT = 'Controle de Saída',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMININE',
}

export enum GenderPt {
  MALE = 'MASCULINO',
  FEMALE = 'FEMININO',
}

export enum EventOptionsAccount {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCEL = 'CANCELED',
  BLOCKED = 'BLOCKED',
}

export enum StepsSideAccount {
  DATA = 1,
  META_DATA = 2,
}

export enum StepsCompanyRegister {
  DATA = 1,
  CONTACT = 2,
  ADDRESS = 3,
}

export enum StepsClientRegister {
  DATA = 1,
  DOCUMENTS = 2,
  ADDRESS = 3,
}

export enum BusinessTypes {
  LTDA = 'LTDA',
  SA = 'SA',
  MEI = 'MEI',
  ME = 'ME',
  EIRELI = 'EIRELI',
}

export enum AccountPaymentType {
  POSTPAID = 'POSTPAID',
  PREPAID = 'PREPAID',
}

export enum ModalClientSections {
  META_DATA = 'META_DATA',
  PERSONAL_DATA = 'PERSONAL_DATA',
}

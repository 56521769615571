import { FC, useEffect, useMemo } from 'react';
import { MccRow, PaginatedList, TitlePage } from '~/components';
import { FormikProps, isEmpty, observer, useFormikContext } from '~/modules';
import {
  AlertMessages,
  MCCActivateColumns,
  StepsRegisterProduct,
  showAlert,
  useStores,
} from '~/utils';
import {
  ButtonComponent,
  Content,
  Description,
  Footer,
  NameList,
  SelectInputSearchComponent,
  Wrapper,
} from './styles';

type Props = {
  onNavigateId(id: number): void;
};
const MCC: FC<Props> = ({ onNavigateId }) => {
  const {
    products: { mccList, mccListSelected, setMccListSelected, getMCC },
  } = useStores();

  const { values, setFieldValue }: FormikProps<RegisterProduct> =
    useFormikContext();

  const listSelect: SelectProps[] = useMemo(() => {
    return mccList.mccList?.reduce((acc, item) => {
      return [
        ...acc,
        {
          value: item.mcc,
          label: `${item.mcc} - ${item.description}`,
        },
      ];
    }, [] as SelectProps[]);
  }, [mccList]);

  const handleSelected = (value: SelectProps) => {
    if (mccListSelected.find((item) => item.value === value.value)) {
      showAlert({ message: AlertMessages.ERROR_ADD_MCC });
    } else {
      const arrayMcc = [value, ...mccListSelected];
      setMccListSelected(arrayMcc);
      const valuesFormMcc: string[] = [];
      valuesFormMcc.push(value.value.toString());
      values?.merchantCategoryCodes?.map((item) => {
        valuesFormMcc.push(item);
      });
      setFieldValue('merchantCategoryCodes', valuesFormMcc);
    }
  };

  const handleRemoveMcc = (id: number | string) => {
    const arrayMcc = mccListSelected.filter((item) => {
      return item.value !== id;
    });
    setMccListSelected(arrayMcc);

    const valueFormMcc = values?.merchantCategoryCodes?.filter((item) => {
      return item !== id;
    });
    setFieldValue('merchantCategoryCodes', valueFormMcc);
  };

  const onGetMCC = async () => {
    try {
      await getMCC();
    } catch ({ message }) {
      showAlert({ message });
    }
  };

  useEffect(() => {
    onGetMCC();
  }, []);

  useEffect(() => {
    if (isEmpty(values.merchantCategoryCodes)) {
      setMccListSelected([]);
    }
  }, [values.merchantCategoryCodes]);

  return (
    <Wrapper>
      <Content>
        <TitlePage>Merchant Category Code</TitlePage>
        <Description>
          Busque e selecione as empresas ou áreas de atuação das quais o produto
          irá transacionar.
        </Description>
        <SelectInputSearchComponent
          id="mcc_cod"
          name="mcc_cod"
          label="Buscar MCC"
          placeholder="Digite o código ou descrição do MCC "
          textIsEmpty="Sem resultado para o valor informado"
          listSelect={listSelect}
          value={undefined}
          autoFocus
          clearValue
          onSelected={handleSelected}
        />

        <NameList>Ativos</NameList>
        <PaginatedList
          columns={MCCActivateColumns}
          list={mccListSelected}
          showPagination={false}
          titleEmptyData="Sem MCC selecionado"
          renderList={({ value, label }) => (
            <MccRow
              key={value}
              mcc={value}
              description={label.substring(7)}
              onPressToggleSwitch={() => handleRemoveMcc(value)}
            />
          )}
        />
      </Content>
      <Footer>
        <ButtonComponent
          id="btn_clear"
          onPress={() => onNavigateId(StepsRegisterProduct.DATA)}
          transparent
        >
          Voltar
        </ButtonComponent>
        <ButtonComponent
          id="btn_confirm"
          onPress={() => onNavigateId(StepsRegisterProduct.META_DATA)}
        >
          Próximo
        </ButtonComponent>
      </Footer>
    </Wrapper>
  );
};

export default observer(MCC);

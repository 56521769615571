import { FC, useMemo } from 'react';
import { isEqual } from 'lodash';
import { isEmpty } from '~/modules';
import If from '../If';
import { CustomLabel, Error, Select, Wrapper } from './styles';

type Props = {
  id: string;
  name: string;
  placeholder?: string;
  options: SelectProps[];
  value: string;
  label?: string;
  secondary?: boolean;
  error?: string | boolean;
  className?: string;
  onChange(props: ChangeFieldValue): void;
  styles?: ExternalSelectProps;
};

const SelectContainer: FC<Props> = ({
  id,
  name,
  options,
  placeholder,
  value,
  label = '',
  secondary = false,
  error,
  className,
  onChange,
  styles,
}) => {
  const defaultValue = useMemo(() => {
    return options
      ? options.find((option) => isEqual(option.value, value))
      : '';
  }, [options, value]);

  const handleChangeOption = ({ value }: SelectProps) => {
    onChange({ name, value });
  };

  const customStyles = {
    option: styles?.option,
    control: styles?.control,
    menuList: styles?.menuList,
    dropdownIndicator: styles?.dropdownIndicator,
    singleValue: styles?.singleValue,
    input: styles?.input,
    placeholder: styles?.placeholder,
    menu: styles?.menu,
  };

  const renderSelect = (defaultValue?: SelectProps | string) => {
    return (
      <Select
        menuPlacement="auto"
        value={defaultValue || ''}
        id={id}
        secondary={secondary}
        name={name}
        placeholder={placeholder}
        options={options}
        customStyles={customStyles}
        onChange={handleChangeOption}
      />
    );
  };

  return (
    <Wrapper className={className}>
      <If condition={!isEmpty(label)}>
        <CustomLabel>{label}</CustomLabel>
      </If>

      {renderSelect(defaultValue)}
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};
export default SelectContainer;

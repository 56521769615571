import { Options } from 'react-lottie';
import { LoadDotsAnimation } from '~/assets';
import { Animation, getTheme, ifStyle, pxToRem, styled } from '~/modules';

const brandPrimaryMain = getTheme('brand.primary.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');
const brandSecondaryMain = getTheme('brand.secondary.main');
const brandSecondaryContrast = getTheme('brand.secondary.contrast');
const backgroundZ4 = getTheme('background.z4');
const backgroundReverseZ3 = getTheme('background.reverse.z3');
const textMain = getTheme('text.main');

const themeRadiusButton = getTheme('themeRadius.button');
const borderWidthMin = getTheme('borderWidth.min');

const isSecondary = ifStyle('secondary');
const isTertiary = ifStyle('tertiary');
const isTransparent = ifStyle('transparent');
const isDisabled = ifStyle('disabled');
const isLoading = ifStyle('loading');
const isColumn = ifStyle('column');

type ButtonProps = {
  secondary?: boolean | string;
  tertiary?: boolean | string;
  transparent?: boolean;
  disabled?: boolean;
  loading?: boolean;
  column?: boolean;
};

export const Button = styled.button<ButtonProps>`
  display: flex;
  background-color: ${isSecondary(
    brandSecondaryMain,
    isTertiary(
      backgroundReverseZ3,
      isTransparent('transparent', brandPrimaryMain),
    ),
  )};
  color: ${isSecondary(
    brandSecondaryContrast,
    isTertiary(backgroundZ4, isTransparent(textMain, brandPrimaryContrast)),
  )};
  border-radius: ${themeRadiusButton}px;
  border: ${isTransparent(borderWidthMin, 0)}px solid ${textMain};
  flex-direction: ${isColumn('column', 'row')};
  opacity: ${isDisabled(0.3, isLoading(0.8, 1))};
  pointer-events: ${isDisabled('none', isLoading('none'))};

  height: ${pxToRem(51)};
  width: ${pxToRem(250)};
  justify-content: center;
  align-items: center;
  padding: 0;
  font-weight: bold;
  font-size: ${pxToRem(17)};

  &:hover {
    opacity: ${isDisabled(0.3, 0.8)};
    cursor: ${isDisabled('not-allowed', isLoading('not-allowed', 'pointer'))};
  }
`;

type LoadingIndicatorProps = {
  options?: Options;
};

export const LoadingIndicator = styled(Animation).attrs({
  options: {
    animationData: LoadDotsAnimation,
  },
  width: pxToRem(70),
  height: pxToRem(70),
  speed: 1.5,
})<LoadingIndicatorProps>``;

import { EventOptionsAccount, ResponseError } from '~/utils';
import request from '../request';
import { clientsStatusToApi, clientsToApi, rechargeToApi } from './clients.dto';

export const getClientAccounts = async (document: string): Promise<Client> => {
  try {
    const { data } = await request.get(`/persons/${document}/accounts`);
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const setStatusAccount = async (
  accountId: string,
  status?: EventOptionsAccount,
): Promise<Account> => {
  try {
    const { data } = await request.patch(
      `/accounts/${accountId}/${clientsStatusToApi(status)}`,
      {
        status,
      },
    );
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const sideAccountRegister = async (
  values: SideAccountRegister,
): Promise<Account> => {
  try {
    const { data } = await request.post(`/side-accounts`, values);
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const sideAccountRegisterPF = async (
  values: SideAccountRegisterPF,
): Promise<Account> => {
  try {
    const { data } = await request.post(`/accounts`, values);
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const companyRegister = async (
  values: CompanyRegister,
): Promise<Client> => {
  try {
    const { data } = await request.post(`/companies`, values);
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const clientRegister = async (
  values: ClientRegister,
): Promise<Client> => {
  try {
    const { data } = await request.post(`/individuals`, clientsToApi(values));
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const addBalanceToAccount = async (
  values: AddBalance,
): Promise<void> => {
  try {
    const { data } = await request.post(`/recharges`, rechargeToApi(values));
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

export const updateLimitToAccount = async (
  values: UpdateLimit,
  id: string,
): Promise<void> => {
  try {
    const { data } = await request.put(
      `/accounts/${id}/limit`,
      rechargeToApi(values),
    );
    return data;
  } catch (error) {
    throw new ResponseError(error);
  }
};

import { FC, useEffect, useState } from 'react';
import { observer } from '~/modules';
import { showAlert, useStores } from '~/utils';
import ProductsPermission from './ProductsPermission';

type handleGetProps = {
  page?: number;
  name?: string;
};
export const ProductsPermissionContainer: FC = () => {
  const {
    users: {
      permissionProductsList,
      userSelected,
      getPermissionProducts,
      setPermissionProducts,
    },
  } = useStores();

  const [isLoading, setIsLoading] = useState(true);

  const handleSetIsAllowed = async (values: SetPermissionProductsProps) => {
    try {
      await setPermissionProducts(values);
    } catch ({ message }) {
      showAlert({ message });
      handleGetPermission({ page: values.page });
    }
  };

  const handleGetPermission = async ({ page }: handleGetProps) => {
    setIsLoading(true);
    try {
      await getPermissionProducts({
        userId: userSelected.id,
        page,
      });
    } catch ({ message }) {
      showAlert({ message });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetPermission({});
  }, []);

  return (
    <ProductsPermission
      permissionProductsList={permissionProductsList}
      isLoading={isLoading}
      userSelected={userSelected}
      onSetIsAllowed={handleSetIsAllowed}
      onChangePage={(page) => handleGetPermission({ page })}
    />
  );
};

export default observer(ProductsPermissionContainer);

import { FC } from 'react';
import { faQuestion } from '~/assets';
import {
  Description,
  IconComponent,
  Title,
  Wrapper,
  WrapperRounded,
} from './styles';

type Props = {
  title: string;
  description: string;
  className?: string;
};

const EmptyData: FC<Props> = ({ title, description, className }) => (
  <Wrapper className={className}>
    <WrapperRounded>
      <IconComponent icon={faQuestion} />
    </WrapperRounded>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </Wrapper>
);

export default EmptyData;

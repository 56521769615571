import styled from 'styled-components';
import { Icon, getTheme, pxToRem } from '~/modules';
import Button from '../Button';
import Typography from '../Typography';

const textMain = getTheme('text.main');
const brandPrimaryContrast = getTheme('brand.primary.contrast');
const brandPrimaryLight = getTheme('brand.primary.light');
const brandPrimaryMain = getTheme('brand.primary.main');
const backgroundZ4 = getTheme('background.z4');
const backgroundZ2 = getTheme('background.z2');

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const themeRadiusCard = getTheme('themeRadius.card');
const themeRadiusButton = getTheme('themeRadius.button');
const borderRadiusXl = getTheme('borderRadius.xl');

export const Row = styled.div`
  align-items: center;
  flex: 1;
  margin-right: ${spacingSm}px;
`;

export const RowButtonSearch = styled(Row)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const Card = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
  align-items: center;
  width: 100%;
  min-height: ${pxToRem(110)};
  padding: 0 ${spacingLg}px;

  background: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;

  & + & {
    margin-top: ${spacingSm}px;
  }
`;

export const IconCard = styled(Icon).attrs({
  fontSize: pxToRem(16),
})`
  color: ${textMain};
  margin-right: ${spacingMd}px;
`;

export const IconHiddenName = styled(Icon).attrs({
  fontSize: pxToRem(16),
})`
  color: ${textMain};
  margin-right: ${spacingSm}px;
`;

export const IconHidenCard = styled(Icon).attrs({
  fontSize: pxToRem(16),
})`
  color: ${textMain};
`;

export const Name = styled(Typography).attrs({ variant: 'md' })`
  font-weight: bold;
  flex: 1;
  margin-right: ${spacingMd}px;
  line-height: 120%;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
`;

export const Description = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-right: ${spacingMd}px;
`;

export const DescriptionHidden = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  border-radius: ${themeRadiusButton}px;
  padding: ${spacingMd}px;
  background-color: ${backgroundZ2};
`;

export const SearchIconBox = styled(Button)`
  width: ${pxToRem(56)};
  height: ${pxToRem(56)};
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  border-radius: ${borderRadiusXl}px;
  background: linear-gradient(
    180deg,
    ${brandPrimaryLight} 0%,
    ${brandPrimaryMain} 100%
  );
  cursor: pointer;
`;

export const IconSearch = styled(Icon)`
  font-size: ${pxToRem(24)};
  color: ${brandPrimaryContrast};
  font-weight: 900;
`;

export const WrapperToggle = styled.div`
  flex: 1;
  justify-content: flex-end;
`;

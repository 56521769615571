import { FC, useState } from 'react';
import { currencyParser, isEmpty } from '~/modules';
import { SectionsInfoAll } from '~/utils';
import EmptyData from '../EmptyData';
import HeaderModal from '../HeaderModal';
import If from '../If';
import {
  Card,
  Column,
  Content,
  Description,
  DescriptionMCC,
  SectionItem,
  SectionsBar,
  Title,
  TitleMCC,
  Value,
  Wrapper,
  WrapperSections,
  WrapperValue,
} from './styles';

type Props = {
  isOpen: boolean;
  metaDataList?: MetaData[] | FileUploadMetaData[];
  mccList?: MCC[];
  extraInfoList?: FileUploadErrorRecharge | null;
  onSections?: boolean;
  handleClose(): void;
  hideMccInfo?: boolean;
  hideExtraInfo?: boolean;
  activeTab?: SectionsInfoAll;
  fullWidth?: boolean;
};

const ModalListMetaData: FC<Props> = ({
  isOpen,
  metaDataList,
  onSections = false,
  mccList,
  extraInfoList,
  handleClose,
  hideMccInfo = false,
  hideExtraInfo = false,
  activeTab = SectionsInfoAll.META_DATA,
  fullWidth = false,
}) => {
  const [onSection, setOnSection] = useState(activeTab);

  return (
    <Wrapper isOpen={isOpen} onClose={handleClose} fullWidth={fullWidth}>
      <HeaderModal title="Informações Adicionais" onPressClose={handleClose} />
      <If condition={onSections}>
        <WrapperSections>
          <SectionsBar>
            <If condition={!hideExtraInfo}>
              <SectionItem
                active={onSection === SectionsInfoAll.EXTRA_INFOS}
                onClick={() => setOnSection(SectionsInfoAll.EXTRA_INFOS)}
              >
                <Value>Informações extras</Value>
              </SectionItem>
            </If>
            <SectionItem
              active={onSection === SectionsInfoAll.META_DATA}
              onClick={() => setOnSection(SectionsInfoAll.META_DATA)}
            >
              <Value>Metadata</Value>
            </SectionItem>
            <If condition={!hideMccInfo}>
              <SectionItem
                active={onSection === SectionsInfoAll.MCC}
                onClick={() => setOnSection(SectionsInfoAll.MCC)}
              >
                <Value>MCC</Value>
              </SectionItem>
            </If>
          </SectionsBar>
        </WrapperSections>
      </If>
      <Content>
        <If condition={onSection === SectionsInfoAll.EXTRA_INFOS}>
          <If condition={!isEmpty(extraInfoList)}>
            <Column>
              <Title>Conta origem</Title>
              <Title>Conta destino</Title>
              <TitleMCC>Valor</TitleMCC>
            </Column>
            <Card key={extraInfoList?.accountId}>
              <Description>{extraInfoList?.accountId}</Description>
              <Description>{extraInfoList?.destinationId}</Description>
              <DescriptionMCC>
                {currencyParser(extraInfoList?.amount || '')}
              </DescriptionMCC>
            </Card>
          </If>
          <If condition={isEmpty(extraInfoList)}>
            <EmptyData title="Sem informações extras" description="" />
          </If>
        </If>

        <If condition={onSection === SectionsInfoAll.META_DATA}>
          <If condition={!isEmpty(metaDataList)}>
            <Column>
              <Title>Descrição</Title>
              <Title>Valor</Title>
            </Column>
            {metaDataList?.map(({ key, value }, index) => {
              return (
                <Card key={index}>
                  <Description>{key}</Description>
                  <WrapperValue>
                    <Value>{value}</Value>
                  </WrapperValue>
                </Card>
              );
            })}
          </If>
          <If condition={isEmpty(metaDataList)}>
            <EmptyData title="Sem metadados cadastrados" description="" />
          </If>
        </If>

        <If condition={onSection === SectionsInfoAll.MCC}>
          <If condition={!isEmpty(mccList)}>
            <Column>
              <TitleMCC>MCC</TitleMCC>
              <Title>Descrição</Title>
            </Column>
            {mccList?.map(({ mcc, description }) => {
              return (
                <Card key={mcc}>
                  <DescriptionMCC>{mcc}</DescriptionMCC>
                  <WrapperValue>
                    <Value>
                      {isEmpty(description)
                        ? 'Descrição temporariamente indisponível'
                        : description}
                    </Value>
                  </WrapperValue>
                </Card>
              );
            })}
          </If>
          <If condition={isEmpty(mccList)}>
            <EmptyData title="Sem MCC cadastrados" description="" />
          </If>
        </If>
      </Content>
    </Wrapper>
  );
};

export default ModalListMetaData;

import { FC } from 'react';
import { ProgressIndicator } from '~/components';
import { FormBehavior, observer, useNavigate } from '~/modules';
import { Routes } from '~/routes';
import {
  AlertMessages,
  StepsClientRegister,
  showAlert,
  useStores,
} from '~/utils';
import Address from './Address';
import CompanyRegister from './ClientRegister';
import DataCompany from './DataCompany';
import Documents from './Documents';
import { initialValues, validationSchema } from './schema';

export type Props = {
  onNavigateId(id: number): void;
};

export const ClientRegisterContainer: FC = () => {
  const navigate = useNavigate();
  const {
    clients: { clientRegister },
    utils: { setProgressId },
  } = useStores();

  const handleFormSubmit = async (values: ClientRegister) => {
    await clientRegister(values);
    showAlert({
      message: AlertMessages.SUCCESS_COMPANY_REGISTER,
      type: 'success',
    });
    navigate(Routes.CLIENT_ACCOUNTS);
  };

  return (
    <FormBehavior
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
    >
      <CompanyRegister>
        <ProgressIndicator
          steps={[
            {
              id: StepsClientRegister.DATA,
              label: 'Dados do Cliente',
              content: <DataCompany onNavigateId={(id) => setProgressId(id)} />,
            },
            {
              id: StepsClientRegister.DOCUMENTS,
              label: 'Documentos',
              content: <Documents onNavigateId={(id) => setProgressId(id)} />,
            },
            {
              id: StepsClientRegister.ADDRESS,
              label: 'Endereço',
              content: <Address onNavigateId={(id) => setProgressId(id)} />,
            },
          ]}
        />
      </CompanyRegister>
    </FormBehavior>
  );
};

export default observer(ClientRegisterContainer);

import styled from 'styled-components';
import { Button, Typography } from '~/components';
import { Icon, getTheme, pxToRem } from '~/modules';

const backgroundZ4 = getTheme('background.z4');

const spacingSm = getTheme('spacing.sm');
const spacingMd = getTheme('spacing.md');
const spacingLg = getTheme('spacing.lg');
const spacingXxl = getTheme('spacing.xxl');
const themeRadiusCard = getTheme('themeRadius.card');
const textMain = getTheme('text.main');

export const Wrapper = styled.div`
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

export const WrapperItem = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: ${pxToRem(83)};
  background-color: ${backgroundZ4};
  border-radius: ${themeRadiusCard}px;
  padding: 0 ${spacingLg}px;
  margin-bottom: ${spacingSm}px;
`;

export const Description = styled(Typography).attrs({
  variant: 'lg',
})`
  width: 75%;
  margin-top: ${spacingMd}px;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: scroll;
  padding-bottom: 10%;
  padding: ${spacingXxl}px;
`;

export const ButtonField = styled(Button)`
  align-self: flex-end;
  width: ${pxToRem(250)};
  min-height: ${pxToRem(51)};
  margin-top: ${spacingMd}px;
`;

export const ButtonClose = styled(Button)`
  border-radius: 50%;
  width: ${pxToRem(35)};
  height: ${pxToRem(35)};
`;

export const InputItem = styled.input`
  flex: 1;
  border: 0;
  background-color: transparent;
  margin-right: ${spacingMd}px;
  color: ${textMain};
`;

export const IconPlus = styled(Icon)`
  margin-right: ${spacingMd}px;
`;

import { FC } from 'react';
import { faPlus } from '~/assets';
import { ExportRow, PaginatedList, TitlePage } from '~/components';
import { exportsColumns } from '~/utils';
import {
  ButtonRegisterProduct,
  ContentBox,
  IconPlus,
  TopBox,
  Wrapper,
} from './styles';

type Props = {
  isFilesLoading: boolean;
  filesList: ListResponse<FileExport>;
  isUserEspectator: boolean;
  onChangePage(page: number): void;
  onPressFile(product: FileExport): void;
  onPressUploadFile: () => void;
};

const Exports: FC<Props> = ({
  filesList,
  isFilesLoading,
  isUserEspectator,
  onChangePage,
  onPressFile,
  onPressUploadFile,
}) => (
  <Wrapper titlePage="Exportações">
    <ContentBox>
      <TopBox>
        <TitlePage totalElements={filesList.paging?.totalElements}>
          Exportações
        </TitlePage>
        <ButtonRegisterProduct
          id="btn_register_product"
          disabled={isUserEspectator}
          onPress={onPressUploadFile}
          secondary
        >
          <IconPlus icon={faPlus} />
          Nova Exportação
        </ButtonRegisterProduct>
      </TopBox>

      <PaginatedList
        columns={exportsColumns}
        list={filesList.content}
        totalPages={filesList.paging?.totalPages}
        currentPage={filesList.paging?.page}
        isLoading={isFilesLoading}
        onChangePage={onChangePage}
        renderList={(file: FileExport) => {
          return (
            <ExportRow
              name={file?.title}
              status={file?.status}
              type={file?.type}
              createdAt={new Date(file?.createdAt)}
              onPress={() => onPressFile(file)}
              key={`${file?.createdAt}`}
            />
          );
        }}
        titleEmptyData="Sem exportações disponíveis"
      />
    </ContentBox>
  </Wrapper>
);

export default Exports;
